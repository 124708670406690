Vue.component('table-permis-de-louer', {
  props: ['excelFields'],
  computed: {
    tickets () { return this.$parent.tickets }
  },
  mounted () {
    this.$set(this.excelFields, 0, 'identifiant')
    this.$set(this.excelFields, 1, 'status')
    this.$set(this.excelFields, 2, 'dateCreation')
    this.$set(this.excelFields, 3, 'categorie')
    this.$set(this.excelFields, 4, 'sousCategorie')
    this.$set(this.excelFields, 5, 'description')
    this.$set(this.excelFields, 6, 'adresse')
    this.$set(this.excelFields, 7, 'commune')
    this.$set(this.excelFields, 8, 'priorite')
    this.$set(this.excelFields, 9, 'dateLimite')
  },
  template: /*html*/`
    <table class="table-accueil table-commune liste-tickets">
      <thead>
        <tr>
          <cell-id :header="true"></cell-id>
          <cell-status :header="true"></cell-status>
          <cell-date-creation :header="true"></cell-date-creation>
          <cell-demandeur :header="true"></cell-demandeur>
          <cell-adresse-bat :header="true" titre="Adresse"></cell-adresse-bat>
          <cell-date-limite :header="true"></cell-date-limite>
        </tr>
      </thead>
      <tbody>
        <tr v-for="ticket in tickets" :class="[ticket.status === 2 && 'cloture', ticket.status === -2 && 'dismissed']">
          <cell-id :ticket="ticket"></cell-id>
          <cell-status :ticket="ticket"></cell-status>
          <cell-date-creation :ticket="ticket"></cell-date-creation>
          <cell-demandeur :ticket="ticket" :hideOrigine="false"></cell-demandeur>
          <cell-adresse-bat :ticket="ticket"></cell-adresse-bat>
          <cell-date-limite :ticket="ticket"></cell-date-limite>
        </tr>
      </tbody>
    </table>
  `
})