Vue.component('cell-pec', {
  props: ['header', 'ticket'],
  data() { return {
    pp: this.$parent.$parent
  }},
  template: `
    <component :is="header ? 'th' : 'td'" :class="[!header && ticket.pecCommune && 'violet']">
      <a v-if="header" href="#" @click.prevent="pp.sortBy('pecCommune')">
        <span v-show="pp.queryParams.orderBy === 'pecCommune' && pp.queryParams.orderDesc">&#9650;</span>
        <span v-show="pp.queryParams.orderBy === 'pecCommune' && !pp.queryParams.orderDesc">&#9660;</span>
        Prise en charge
      </a>
      <span v-else="">
        <span v-if="ticket.pecCommune">Commune de {{ ticket.commune }}</span>
        <span v-else="">Bassin de Pompey</span>
      </span>
    </component>
  `
})