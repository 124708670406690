Vue.component('home-stat', {
  props: ['image', 'title', 'value', 'filter', 'statIndex'],
  methods: {
    activateFilter () {

      this.$parent.queryParams.where = this.filter ? this.filter : []
      this.$parent.updateSelectedStatFilter = false
      this.$parent.selectedStatFilter = this.statIndex
    }
  },
  template: `
    <a v-if="value" href="#" @click.prevent="activateFilter" :class="[$parent.selectedStatFilter === statIndex && 'active']">
      <div class="title">
        <img :src="image"><br>
        {{ title }}
      </div>
      <div class="data">{{ value }}</div>
    </a>
  `
})