PAGES['config'] = Vue.component('page-config', {
  data () { return {
    newAtAuto: {
      num_surCategorie: null,
      num_categorie: '',
      // num_sousCategorie: null,
      chefEquipe: null,
      num_commune: '',
      commune: 0
    },
    atAutos: [],
    newDroit: {
      login: null,
      num_profil: null,
      num_commune: null,
      valideur: 0
    },
    droits: [],
    newRegleMail: {
      num_surCategorie: null,
      champ: '',
      valeurChamp: '',
      destinataire: '',
      modele: null,
      unique: 0,
      creation: 0
    },
    reglesMail: [],
    selectedPage: 4,
  }},
  mounted () {
    this.loadAtAuto()
    this.loadReglesMail()
  },
  methods: {
    loadAtAuto () {
      let me = this
      this.server.get('/api/config/atAuto/list').then(function (data) {
        for (let i = 0; i < data.length; i += 1) {
          data[i].readOnly = true
        }
        me.atAutos = data
      })
    },
    loadReglesMail () {
      let me = this
      me.reglesMail = []
      this.server.get('/api/config/regleMail/list').then(function (data) {
        for (let i = 0; i < data.length; i += 1) {
          data[i].readOnly = true
        }
        me.reglesMail = data
      })
    },
    saveAtAuto () { this.server.post('/api/config/atAuto/add', this.newAtAuto).then(this.loadAtAuto) },
    updateAtAuto (atAuto) { this.server.post('/api/config/atAuto/update', atAuto).then(this.loadAtAuto) },
    removeAtAuto (num_atAuto) { this.server.get('/api/config/atAuto/delete/' + num_atAuto).then(this.loadAtAuto) },
    upAtAuto (num_atAuto) { this.server.get('/api/config/atAuto/up/' + num_atAuto).then(this.loadAtAuto) },
    downAtAuto (num_atAuto) { this.server.get('/api/config/atAuto/down/' + num_atAuto).then(this.loadAtAuto) },
    saveRegleMail () { this.server.post('/api/config/regleMail/add', this.newRegleMail).then(this.loadReglesMail) },
  },
  template: /*html*/`
    <div class="page-config">
      <a href="#" class="button" :class="[selectedPage === 1 && 'blue', selectedPage !== 1 && 'grey']" @click.prevent="selectedPage = 1">Attribution automatique des tickets</a>
      <a href="#" class="button" :class="[selectedPage === 4 && 'blue', selectedPage !== 4 && 'grey']" @click.prevent="selectedPage = 4">Droits</a>
      <a href="#" class="button" :class="[selectedPage === 3 && 'blue', selectedPage !== 3 && 'grey']" @click.prevent="selectedPage = 3">Règles mail</a>
      <br><br>
      <div v-show="selectedPage === 1">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Domaine</th>
              <th>Catégorie(s)</th>
              <th>Commune</th>
              <th>Agent</th>
              <th>Commune(s)</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(atAuto, index) in atAutos">
              <td>
                <a href="#" class="button blue" @click.prevent="upAtAuto(atAuto.num_atAuto)" :class="[index === 0 && 'disabled']">&#x2BC5;</a>
                <a href="#" class="button blue" @click.prevent="downAtAuto(atAuto.num_atAuto)" :class="[index === atAutos.length - 1 && 'disabled']"> &#x2BC6;</a>
              </td>
              <td>
                <in-select 
                  :data="$store.state.constants.surCategories"
                  :labelField="'nom'" 
                  v-model="atAuto.num_surCategorie" 
                  emptyLabel="Coisir un domaine" 
                  dataField="num_surCategorie"
                  :readOnly="atAuto.readOnly"
                ></in-select>
              </td>
              <td>
                <in-multi-check
                  v-show="catParSurCat(atAuto.num_surCategorie).length > 0" 
                  :data="catParSurCat(atAuto.num_surCategorie)"
                  :labelField="'nom'" 
                  v-model="atAuto.num_categorie" 
                  dataField="num_categorie"
                  :readOnly="atAuto.readOnly"
                ></in-multi-check>
              </td>
              <td>
                <in-bool v-model="atAuto.commune" :readOnly="atAuto.readOnly"></in-bool>
              </td>
              <td>
              <in-select 
                  v-show="!atAuto.commune"
                  :data="$store.state.constants.intervenants[atAuto.num_surCategorie]"
                  :labelField="'nom'" 
                  v-model="atAuto.chefEquipe" 
                  emptyLabel="Choisir un chef d'équipe" 
                  dataField="login"
                  :readOnly="atAuto.readOnly"
                ></in-select>
              </td>

              <td>
                <in-multi-check
                  :data="$store.state.constants.communes"
                  :labelField="'nom'" 
                  v-model="atAuto.num_commune" 
                  dataField="num_commune"
                  :readOnly="atAuto.readOnly"
                ></in-multi-check>
              </td>
              <td>
                <a href="#" class="button blue small w-80" @click.prevent="atAuto.readOnly = false" v-show="atAuto.readOnly">Éditer</a>
                <a href="#" class="button green small w-80" @click.prevent="updateAtAuto(atAuto)" v-show="!atAuto.readOnly" :class="[(!atAuto.chefEquipe || !atAuto.num_surCategorie) && 'disabled']">Enregistrer</a>
                <a href="#" class="button red small w-80" @click.prevent="removeAtAuto(atAuto.num_atAuto)">Supprimer</a>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <in-select 
                  :data="$store.state.constants.surCategories"
                  :labelField="'nom'" 
                  v-model="newAtAuto.num_surCategorie" 
                  emptyLabel="Coisir un domaine" 
                  dataField="num_surCategorie"
                  :readOnly="false"
                ></in-select>
              </td>
              <td>
                <in-multi-check
                  v-show="catParSurCat(newAtAuto.num_surCategorie).length > 0" 
                  :data="catParSurCat(newAtAuto.num_surCategorie)"
                  :labelField="'nom'" 
                  v-model="newAtAuto.num_categorie" 
                  dataField="num_categorie"
                  :readOnly="false"
                ></in-select>
              </td>
              <td>
                <in-bool v-model="newAtAuto.commune" label=""></in-bool>
              </td>
              <td>
                <in-select 
                  v-show="!newAtAuto.commune"
                  :data="$store.state.constants.intervenants[newAtAuto.num_surCategorie]"
                  :labelField="'nom'" 
                  v-model="newAtAuto.chefEquipe" 
                  emptyLabel="Choisir un chef d'équipe" 
                  dataField="login"
                ></in-select>
              </td>
              <td>
                <in-multi-check
                  :data="$store.state.constants.communes"
                  :labelField="'nom'" 
                  v-model="newAtAuto.num_commune" 
                  dataField="num_commune"
                  :readOnly="false"
                ></in-select>
              </td>
              <td>
                <a href="#" class="button green small w-80" @click.prevent="saveAtAuto" :class="[((!newAtAuto.chefEquipe && !newAtAuto.commune) || !newAtAuto.num_surCategorie) && 'disabled']">Ajouter</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-show="selectedPage === 4">
        <config-droits></config-droits>
      </div>
      <div v-show="selectedPage === 3">
        <div>
          Mails configurés en dur : 
          <ul>
            <li>mail lors d'un échange</li>
            <li>mail lors d'un changement de priorité</li>
            <li>mail lors d'un changement de valideur</li>
          </ul>
        </div>
        <table>
          <thead>
            <tr>
              <th>Domaine</th>
              <th>Champ surveillé</th>
              <th>Valeur du champ</th>
              <th>Destinataire</th>
              <th>Modèle</th>
              <th>Envoi unique</th>
              <th>À la création</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="regleMail in reglesMail">
            <td>
              <in-select 
                :data="$store.state.constants.surCategories"
                :labelField="'nom'" 
                v-model="regleMail.num_surCategorie" 
                emptyLabel="Coisir un domaine" 
                dataField="num_surCategorie"
                :readOnly="regleMail.readOnly"
              ></in-select>
            </td>
            <td>
              <in-text v-show="!regleMail.creation" v-model="regleMail.champ" :small="true" :readOnly="regleMail.readOnly"></in-text>
              <span v-show="regleMail.creation">N/A</span>
            </td>
            <td>
              <in-text v-show="!regleMail.creation" v-model="regleMail.valeurChamp" :small="true" :readOnly="regleMail.readOnly" :emptyValue="'non défini'"></in-text>
              <span v-show="regleMail.creation">N/A</span>
            </td>
            <td>
              <in-text v-model="regleMail.destinataire" :small="true" :readOnly="regleMail.readOnly"></in-text>
            </td>
            <td>
              <in-select 
                :data="$store.state.constants.mailModels"
                :labelField="'nom'" 
                v-model="regleMail.modele" 
                emptyLabel="Coisir un modèle" 
                dataField="nom"
                :readOnly="regleMail.readOnly"
              ></in-select>
            </td>
            <td>
              <in-bool v-model="regleMail.unique" :readOnly="regleMail.readOnly"></in-bool>
            </td>
            <td>
              <in-bool v-model="regleMail.creation" :readOnly="regleMail.readOnly"></in-bool>
            </td>
            <td>
              <a href="#" class="button blue small w-80" @click.prevent="regleMail.readOnly = false" v-show="regleMail.readOnly">Éditer</a>
              <a href="#" class="button green small w-80" @click.prevent="updateRegleMail(regleMail)" v-show="!regleMail.readOnly" :class="[(!regleMail.num_surCategorie || (!regleMail.champ && !regleMail.creation) || !regleMail.destinataire || !regleMail.modele) && 'disabled']">Enregistrer</a>
              <a href="#" class="button red small w-80" @click.prevent="removeRegleMail(regleMail.num_regleMail)">Supprimer</a>
            </td>
          </tr>
          <tr>
            <td>
              <in-select 
                :data="$store.state.constants.surCategories"
                :labelField="'nom'" 
                v-model="newRegleMail.num_surCategorie" 
                emptyLabel="Coisir un domaine" 
                dataField="num_surCategorie"
                :readOnly="false"
              ></in-select>
            </td>
            <td>
              <in-text v-show="!newRegleMail.creation" v-model="newRegleMail.champ" :small="true"></in-text>
              <span v-show="newRegleMail.creation">N/A</span>
            </td>
            <td>
              <in-text v-show="!newRegleMail.creation" v-model="newRegleMail.valeurChamp" :small="true"></in-text>
              <span v-show="newRegleMail.creation">N/A</span>
            </td>
            <td>
              <in-text v-model="newRegleMail.destinataire" :small="true"></in-text>
            </td>
            <td>
              <in-select 
                :data="$store.state.constants.mailModels"
                :labelField="'nom'" 
                v-model="newRegleMail.modele" 
                emptyLabel="Coisir un modèle" 
                dataField="nom"
                :readOnly="false"
              ></in-select>
            </td>
            <td>
              <in-bool v-model="newRegleMail.unique"></in-bool>
            </td>
            <td>
              <in-bool v-model="newRegleMail.creation"></in-bool>
            </td>
            <td>
              <a href="#" class="button green small w-80" @click.prevent="saveRegleMail" :class="[(!newRegleMail.num_surCategorie || (!newRegleMail.champ && !newRegleMail.creation) || !newRegleMail.destinataire || !newRegleMail.modele) && 'disabled']">Ajouter</a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  `
})