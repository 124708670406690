Vue.component('banner-affectation-priorite', {
  props: ['params'],
  methods: {
    updateChefEquipe (chefEquipe) {
      let me = this
      me.server.post(
        '/api/priorite/chefEquipe/set',
        {
          num_ticket: me.ticket.num_ticket,
          chefEquipe: chefEquipe,
        }
      ).then(function () {
        me.$parent.loadTicket()
        me.$store.commit('setDataUpdated')
      })
    },
    updatePriorite (num_priorite, chefEquipe) {
      let me = this
      let message = ''

      if (!this.datesLimites[num_priorite] || !this.datesLimites[num_priorite].dateLimite) {
        message = `Ce ticket va être enregistré, et un message sera envoyé au demandeur pour l'avertir.<br><br>
        Confirmez-vous ce changement ?`
      } else {
        message = `Ce ticket va être enregistré, sa date limite de clôture va être fixée de manière irréversible au <b> 
        ` + this.formatDateComplete(this.datesLimites[num_priorite].dateLimite) + 
        `</b> et un message sera envoyé au demandeur pour l'avertir.<br><br>
        Confirmez-vous ce changement ?`
      }

      if (num_priorite === 4) {
        me.$store.commit('setModal', {
          visible: true,
          title: '<span class="txt-red">&#9888;</span> Refus du ticket',
          type: 'component',
          component: 'modal-refus-signalement',
          num_surCategorie: me.ticket.num_surCategorie,
          message: 'Un message de refus du signalement va être envoyé au demandeur, vous pouvez le personnaliser ci-dessous :',
          okCallback (message) {
            if (me.datesLimites[num_priorite] && me.datesLimites[num_priorite].dateLimite) {
              me.ticket.dateLimite    = me.datesLimites[num_priorite].dateLimite
            } else {
              me.ticket.dateLimite = null
            }
            me.ticket.num_priorite  = num_priorite
            me.server.post(
              '/api/priorite/priorite/set',
              {
                num_ticket: me.ticket.num_ticket,
                num_priorite: num_priorite,
                chefEquipe: null,
                dateLimite: (me.datesLimites[num_priorite] && me.datesLimites[num_priorite].dateLimite) ? me.datesLimites[num_priorite].dateLimite : null,
                message: message.text
              }
            ).then(function () {
              me.$parent.loadTicket()
              me.$store.commit('setDataUpdated')
            })
          }
        })
      } else if (me.params.binaire) {
        me.$store.commit('setModal', {
          visible: true,
          message: message,
          title: '<span class="txt-red">&#9888;</span> Modification de la priorité',
          type: 'validation',
          okCallback () {
            me.server.post(
              '/api/priorite/priorite/set',
              {
                num_ticket: me.ticket.num_ticket,
                num_priorite: num_priorite,
                chefEquipe: chefEquipe
              }
            ).then(function () {
              me.$parent.loadTicket()
              me.$store.commit('setDataUpdated')
            })
          }
        })
      } else {
        me.$store.commit('setModal', {
          visible: true,
          message: message,
          title: '<span class="txt-red">&#9888;</span> Modification de la priorité',
          type: 'validation',
          okCallback () {
            if (me.datesLimites[num_priorite] && me.datesLimites[num_priorite].dateLimite) {
              me.ticket.dateLimite    = me.datesLimites[num_priorite].dateLimite
            } else {
              me.ticket.dateLimite = null
            }
            me.ticket.num_priorite  = num_priorite
            me.server.post(
              '/api/priorite/priorite/set',
              {
                num_ticket: me.ticket.num_ticket,
                num_priorite: num_priorite,
                chefEquipe: chefEquipe,
                dateLimite: (me.datesLimites[num_priorite] && me.datesLimites[num_priorite].dateLimite) ? me.datesLimites[num_priorite].dateLimite : null
              }
            ).then(function () {
              me.$parent.loadTicket()
              me.$store.commit('setDataUpdated')
            })
          }
        })
      }
    },
    deleteTicket () {
      let me = this
      me.$store.commit('setModal', {
        visible: true,
        message: "Voulez-vous vraiment supprimer ce ticket ?",
        title: 'Suppression du ticket',
        type: 'validation',
        okCallback () {
          me.server.get('/api/ticket/delete/' + me.ticket.num_ticket).then(() => {
            me.$store.commit('setDataUpdated')
            router.push('/accueil')
          })
        }
      })
      
    }
  },
  computed: {
    ticket () { return this.$parent.ticket },
    datesLimites () { return this.$parent.datesLimites || [] }
  },
  template: /*html*/`
    <div class="header-message">
      Un agent et une priorité doivent être attribué à ce ticket.<br>
      <in-select 
        :data="$store.state.constants.intervenants[ticket.num_surCategorie]"
        :labelField="'nom'" 
        v-model="ticket.chefEquipeAuto" 
        emptyLabel="Aucun agent affecté" 
        dataField="login"
        :readOnly="false"
      ></in-select>
      <span v-if="params.binaire">
        <a href="#" class="button green" :class="[!ticket.chefEquipeAuto && 'disabled']" @click.prevent="updatePriorite(2, ticket.chefEquipeAuto)">Standard</a>
        <a href="#" class="button red" :class="[!ticket.chefEquipeAuto && 'disabled']" @click.prevent="updatePriorite(1, ticket.chefEquipeAuto)">Urgent</a>
        <a href="#" class="button violet" @click.prevent="updatePriorite(4, ticket.chefEquipeAuto)">Refus</a>
      </span>
      <span v-else="">
        <a href="#" class="button violet" @click.prevent="updateChefEquipe(ticket.chefEquipeAuto)">Aff. sans priorité</a>
        <a 
          v-for="priorite in $store.state.constants.priorites"
          href="#"
          class="button"
          :class="['priorite-' + priorite.num_priorite, (!ticket.chefEquipeAuto && priorite.num_priorite !== 4) && 'disabled']"
          @click.prevent="updatePriorite(priorite.num_priorite, ticket.chefEquipeAuto)"
          :title="priorite.num_priorite < 4 && datesLimites[priorite.num_priorite] ? ('Date limite : ' + formatDate(datesLimites[priorite.num_priorite].dateLimite)) : 'Pas de date limite'"
        >
          {{ priorite.nom }}{{ priorite.num_priorite < 4 && datesLimites[priorite.num_priorite] ? (' - ' + formatDate(datesLimites[priorite.num_priorite].dateLimite)) : '' }}
        </a>
        <a href="#" class="button red" @click.prevent="deleteTicket">SUPPRIMER</a> {{ params.binaire }}
      </span>
    </div>
  `
})