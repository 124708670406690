Vue.component('mod-avancement-1', {
  props: ['params'],
  computed: {
    ticket () { return this.$parent.ticket }
  },
  template: /*html*/`
    <div class="module avancement">
      <h2>Avancement</h2>
      <div>
        <div class="etape-avancement">
            {{ toUpper($parent.intitule) }}<br>
          <span>{{ formatDateComplete(ticket.dateCreation, true) }}</span>
        </div>
        <div v-if="ticket.valideur && (ticket.valide || !ticket.dateValidation)" :class="[!ticket.dateValidation && 'disabled']" class="etape-avancement">
          VALIDATION<br>
          <span>{{ formatDateComplete(ticket.dateValidation, true) }}</span>
        </div>
        <div v-if="ticket.valideur && (!ticket.valide && ticket.dateValidation)" :class="[!ticket.dateValidation && 'disabled']" class="etape-avancement invalide">
          REFUS<br>
          <span>{{ formatDateComplete(ticket.dateValidation, true) }}</span>
        </div>
        <div :class="[!ticket.datePec && 'disabled']" class="etape-avancement" v-if="$store.state.indexedConstants.surCategories[ticket.num_surCategorie].priseEnCharge && !ticket.pecCommune">
          PRISE EN CHARGE<br>
          <span>{{ formatDateComplete(ticket.datePec, true) }}</span>
        </div>
        <div :class="[!ticket.dateCloture && 'disabled']" class="etape-avancement">
          CLÔTURE<br>
          <span>{{ formatDateComplete(ticket.dateCloture, true) }}</span>
        </div> 
        <div class="desc-avancement" v-if="!params.cacheDetails">
          <span v-if="ticket.valideur && !ticket.dateValidation">
            {{ flUpper($parent.intitule) }} en attente de validation chez {{ ticket.valideurFullName }}.
          </span>
          <span v-else-if="!ticket.dateCloture && !ticket.datePec && ((ticket.dateValidation && ticket.valide) || !ticket.valideur)">
            {{ flUpper($parent.intitule) }} enregistré&nbsp; <span v-if="!ticket.pecCommune"> il sera bientôt pris en charge</span>.
          </span>
          <span v-else-if="!ticket.dateCloture && !ticket.datePec && (ticket.dateValidation && !ticket.valide)">
            {{ flUpper($parent.intitule) }} refusée par {{ ticket.valideurFullName }}.
          </span>
          <span v-else-if="!ticket.dateCloture">
            {{ flUpper($parent.intitule) }} pris(e) en charge <span v-show="ticket.dateLimite" style="display:inline">, nos équipes interviendront avant le<br> <b>{{ formatDateComplete(ticket.dateLimite) }}</b></span>.
          </span>
          <span v-else="">
            {{ flUpper($parent.intitule) }} clôturé(e).
          </span>
        </div>
      </div>
    </div>
  `
})