Vue.component('home-filter', {
  props: ['showOnMobile', 'label', 'values', 'field'],
  data () { return {
    activeFilters: [],
    existingValues: [],
    baseFilter: []
  } },
  methods: {
    addFilter (filterField, value) {
      let homePage = this.$parent.$parent
      homePage.searchNow = true
      homePage.activeFilters[filterField] = homePage.activeFilters[filterField] || []

      // Si la table liee n'est pas la même que celle des filtres existants on remet le filtre à 0
      if (filterField === 'num_surCategorie' && homePage.activeFilters['num_surCategorie'].indexOf(value) === -1) {     
        if (homePage.activeFilters['num_surCategorie'].length > 0) {
          if (this.$store.state.indexedConstants.surCategories[homePage.activeFilters['num_surCategorie'][0]].homeTable !== this.$store.state.indexedConstants.surCategories[value].homeTable) {
            homePage.activeFilters['num_surCategorie'].splice(0, homePage.activeFilters['num_surCategorie'].length)
          }
        }
      }

      if (homePage.activeFilters[filterField].indexOf(value) === -1) {
        homePage.activeFilters[filterField].push(value)
      } else {
        homePage.activeFilters[filterField].splice(homePage.activeFilters[filterField].indexOf(value), 1)
      }


      if (homePage.activeFilters[filterField].length === 0 && this.baseFilter.length > 0) {
        for (let i = 0; i < this.baseFilter.length; i += 1) {
          homePage.activeFilters[filterField].push(this.baseFilter[i])
        }
      }
      
      if (filterField === 'num_surCategorie' && homePage.activeFilters['num_surCategorie'].length > 0) {
        homePage.currentTable = this.$store.state.indexedConstants.surCategories[homePage.activeFilters['num_surCategorie'][0]].homeTable || this.$store.state.userInfos.homeTable
      }

      this.activeFilters = homePage.activeFilters[filterField]
      homePage.updateWhere()
    }
  },
  mounted () {
    let me = this
    this.server.get('/api/ticket/distincts/' + this.field).then(function (data) {
      me.existingValues = data
      if (me.field === 'num_surCategorie') {
        let homeTables = []
        let currentHomeTable
        for (let i = 0; i < data.length; i += 1) {
          if (homeTables.indexOf(me.$store.state.indexedConstants.surCategories[data[i]].homeTable) === -1) {
            homeTables.push(me.$store.state.indexedConstants.surCategories[data[i]].homeTable)
          }
        }
        if (homeTables.length > 1) {
          if (homeTables.indexOf(me.$store.state.userInfos.homeTable) !== -1) {
            currentHomeTable = me.$store.state.userInfos.homeTable
          } else if (homeTables.indexOf(null) !== -1) {
            currentHomeTable = null
          } else {
            currentHomeTable = homeTables[0]
          }

          for (let i = 0; i < data.length; i += 1) {
            if (me.$store.state.indexedConstants.surCategories[data[i]].homeTable === currentHomeTable) {
              me.$parent.$parent.activeFilters['num_surCategorie'] = me.$parent.$parent.activeFilters['num_surCategorie'] || []
              me.$parent.$parent.activeFilters['num_surCategorie'].push(data[i])
              me.baseFilter.push(data[i])
            }
          }
          me.activeFilters = me.$parent.$parent.activeFilters['num_surCategorie']
          me.$parent.$parent.updateWhere()
        }
      }
    })
  },
  template: /*html*/`
    <div v-if="showOnMobile || !isMobile" v-show="existingValues.length > 1">
      <div class="title">{{ label }} :</div>
      <div class="filters-buttons">
        <a  v-for="value in values"
            v-show="existingValues.includes(value.data)"
            href="#" 
            @click.prevent="addFilter(field, value.data)"
            :class="[(activeFilters &&  activeFilters.indexOf(value.data) !== -1) && 'active']"
        >{{ value.label }}</a>
        <!-- <a href="#" v-show="activeFilters.hasOwnProperty(filter.field)" @click.prevent="deleteFilter(filter.field)">&#10799;</a> -->
      </div>
    </div>
  `
})