Vue.component('banner-change-domaine', {
  props: ['params'],
  methods: {
    transfereCommune () {
      let me = this
      me.$store.commit('setModal', {
        visible: true,
        message: me.ticket.pecCommune ? 'Confirmez-vous le transfert du signalement au Bassin de Pompey&nbsp;?' : ('Confirmez-vous le transfert de ce signalement à la commune de <b>' + me.commune + '</b>&nbsp;?'),
        title: '<span class="txt-red">&#9888;</span> Transfert du ticket',
        type: 'validation',
        okCallback () {
          me.ticket.pecCommune = me.ticket.pecCommune ? 0 : 1
          me.server.post('/api/ticket/update', {ticket: me.ticket, fieldsToInclude: ['pecCommune']}).then(() => {
            // me.loadTicket()
            me.$store.commit('setDataUpdated')
          })
        }
      })
    },
    changeSurCategorie (surCategorie) {
      let me = this
      me.$store.commit('setModal', {
        visible: true,
        message: 'Confirmez-vous le transfert de ce signalement au service ' + surCategorie.nom + ' ?',
        title: '<span class="txt-red">&#9888;</span> Transfert du ticket',
        type: 'validation',
        okCallback () {
          me.ticket.num_surCategorie = surCategorie.num_surCategorie
          me.ticket.num_categorie = null
          me.server.post('/api/ticket/update', {ticket: me.ticket, fieldsToInclude: ['num_surCategorie', 'num_categorie']}).then(() => {
            // me.loadTicket()
            me.$store.commit('setDataUpdated')
          })
        }
      })
    }
  },
  computed: {
    ticket () { return this.$parent.ticket },
    datesLimites () { return this.$parent.datesLimites },
    commune () { return this.ticket.num_commune ? this.$store.state.indexedConstants.communes[this.ticket.num_commune].nom : ""}
  },
  template: /*html*/`
    <div class="header-message" v-if="params.domaine || (params.commune && ticket.num_commune)">
      <span v-if="ticket.pecCommune"></span>
      <span v-else-if="params.commune && params.domaine && ticket.num_commune">Vous pouvez attribuer ce ticket à un autre service ou le transférer à la commune.</span>
      <span v-else-if="params.domaine">Vous pouvez attribuer ce ticket à un autre service.</span>
      <span v-else-if="params.commune">Vous pouvez transférer à la commune.</span>
      <br v-if="!ticket.pecCommune">
      <a 
        v-for="surCategorie in $store.state.constants.surCategories"
        v-if="surCategorie.num_surCategorie !== ticket.num_surCategorie && surCategorie.active && params.domaine && !ticket.pecCommune"
        href="#"
        class="button blue"
        @click.prevent="changeSurCategorie(surCategorie)"
      >
        {{ surCategorie.nom }}
      </a>
      <a v-if="params.commune && ticket.num_commune" href="#" class="button violet" @click.prevent="transfereCommune">
        <span v-show="ticket.pecCommune">TRANSFÉRER AU BASSIN DE POMPEY</span>
        <span v-show="!ticket.pecCommune">TRANSFÉRER À LA COMMUNE DE <b>{{ toUpper(commune) }}</b></span>
      </a>
    </div>
  `
})