Vue.component('cell-description', {
  props: ['header', 'ticket', 'hideDomain', 'hideCategory'],
  data() { return {
    pp: this.$parent.$parent
  }},
  template: /*html*/`
    <component :is="header ? 'th' : 'td'" :class="[!header && 'description']">
      <span v-if="header">
        Description
      </span>
      <span v-else="">
        <router-link 
          :to="'/ticket/' + ticket.identifiant" 
          
        >
          <!--<img :src="'/assets/images/' + $store.state.indexedConstants.surCategories[ticket.num_surCategorie].icone">-->
          <span class="categorie" v-if="!hideDomain && !hideCategory">
            <span v-if="!hideDomain">{{ $store.state.indexedConstants.surCategories[ticket.num_surCategorie].nom }} </span>
            <span v-if="!hideCategory">{{ ticket.num_categorie ? (!hideDomain ? ' - ' : '') + $store.state.indexedConstants.categories[ticket.num_categorie].nom : '' }}</span>
          </span>
          <br v-if="!hideDomain && !hideCategory">
          <em v-if="ticket.sujetMail || !ticket.description">(Non renseigné)</em>
          <span v-else="" v-html="addBr(ticket.description)"></span>
          <photo-bouton v-if="ticket.fichiers" :fichiers="ticket.fichiers"></photo-bouton>
        </router-link>
      </span>
    </component>
  `
})