Vue.component('config-droits', {
  data () { return {
    selectedProfil: -1,
    selectedADGroup: null,
    profilMembers: [],
    newMember: {
      login: null,
      num_commune: null
    },
    newGroup: {
      cn: null,
      num_commune: null
    },
    adGroups: []
  }},
  watch: {
    selectedProfil: function() {
      this.selectedADGroup = null
      this.loadProfilMembers()
    }
  },
  mounted () {
  },
  methods: {
    loadProfilMembers () {
      let me = this
      this.server.get('/api/config/profil/members/' + this.selectedProfil).then(function (data) {
        data.forEach(member => { member.editing = false })
        me.profilMembers = data
      })
    },
    addNewMember () {
      let me = this
      let setProfil = function () {
        me.server.post('/api/config/profil/set', {
          login: me.newMember.login, 
          num_profil: me.selectedProfil, 
          num_commune: (me.selectedProfil !== -2 && me.$store.state.indexedConstants.profils[me.selectedProfil].commune) ? me.newMember.num_commune : null}).then(() => {
            me.loadProfilMembers()
            me.$set(me.newMember, 'login', null)
            me.$set(me.newMember, 'num_commune', null)
        })
      }

      if (me.selectedProfil === -2) {
        setProfil()
      } else {
        this.server.get('/api/config/profil/actualProfil/' + this.newMember.login).then((data) => {
          if (data.profil) {
            me.$store.commit('setModal', {
              visible: true,
              message: `<b>` + data.nom + `</b> est déjà affecté au profil <b>` + data.profil + `</b>.<br><br><em>Confirmez-vous le changement de profil ?</em>`,
              title: '<span class="txt-red">&#9888;</span> Changement de profil',
              type: 'validation',
              okCallback: setProfil
            })
          } else {
            setProfil()
          }
        })
      }
      
    },
    addNewGroup () {
      let me = this
      let setProfil = function () {
        me.server.post('/api/config/profil/setGroup', {
          cn: me.newGroup.cn, 
          num_profil: me.selectedProfil, 
          num_commune: me.$store.state.indexedConstants.profils[me.selectedProfil].commune ? me.newGroup.num_commune : null}).then(() => {
            me.loadProfilMembers()
            me.$set(me.newGroup, 'cn', null)
            me.$set(me.newGroup, 'num_commune', null)
        })
      }

      this.server.get('/api/config/profil/actualGroupProfil/' + this.newGroup.cn).then((data) => {
        if (data.profil) {
          me.$store.commit('setModal', {
            visible: true,
            message: `<b>` + data.nom + `</b> est déjà affecté au profil <b>` + data.profil + `</b>.<br><br><em>Confirmez-vous le changement de profil ?</em>`,
            title: '<span class="txt-red">&#9888;</span> Changement de profil',
            type: 'validation',
            okCallback: setProfil
          })
        } else {
          setProfil()
        }
      })
    },
    removeMember (member) {
      let me = this
      this.$store.commit('setModal', {
        visible: true,
        message: me.selectedProfil === -2 ? `<em>Confirmez-vous le retrait de liste des valideurs de <b>` + member.nom + `</b> ?</em>` : `<em>Confirmez-vous le retrait du profil de <b>` + member.nom + `</b> ?</em>`,
        title: '<span class="txt-red">&#9888;</span> Suppression du membre',
        type: 'validation',
        okCallback () {
          me.server.post('/api/config/profil/removeMember', { login: member.login, num_profil: me.selectedProfil }).then(() => me.loadProfilMembers())
        }
      })
    },
    updateMember (member) {
      let me = this
      this.server.post('/api/config/profil/updateMember', { member: member, num_profil: this.selectedProfil }).then(() => me.loadProfilMembers())
    }
  },
  template: /*html*/`
  <div class="droits">
    <div class="liste-profils">
      <h3>PROFIL</h3>
      <div>
        <a href="#" v-for="profil in $store.state.constants.profils" @click.prevent="selectedProfil = profil.num_profil" :class="[selectedProfil === profil.num_profil && 'active']" class="profil">
          <img src="/assets/images/lock.svg">
          {{ profil.nom }}
        </a>
        <a href="#" class="profil" @click.prevent="selectedProfil = -2" :class="[selectedProfil === -2 && 'active']">
          <img src="/assets/images/closed.svg">
          Valideurs
        </a>
      </div>
    </div>
    <div class="liste-membres">
      <h3>MEMBRES</h3>
      <div>
        <table>
          <tr v-show="selectedProfil === 0"><td>
            <img src="/assets/images/gear.svg">
            Utilisateurs non connectés
          </td></tr>
          <tr v-show="selectedProfil === 10"><td>
            <img src="/assets/images/gear.svg">
            Utilisateurs de l'annuaire
          </td></tr>
          <tr v-for="member in profilMembers">
            <td>
              <img :src="member.interne ? '/assets/images/logo_carre.svg' : '/assets/images/man.svg'">
              {{ member.nom }}
            </td>
            <td>
              <in-select
                v-show="selectedProfil !== -2 && $store.state.indexedConstants.profils[selectedProfil].commune" 
                :data="$store.state.constants.communes"
                :labelField="'nom'" 
                v-model="member.num_commune" 
                emptyLabel="Choisir une commune" 
                dataField="num_commune"
                :readOnly="!member.editing"
              ></in-select>
            </td>
            <td class="small">
              <a href="#" v-show="selectedProfil !== -2 && $store.state.indexedConstants.profils[selectedProfil].commune && !member.editing" @click.prevent="member.editing = true" >
                <img src="/assets/images/pencil.svg">
              </a>
              <a href="#" v-show="member.editing" @click.prevent="updateMember(member)" >
                <img src="/assets/images/floppy-disk.svg">
              </a>
            </td>
            <td class="small">
              <a href="#" @click.prevent="removeMember(member)">
                <img src="/assets/images/cross.svg">
              </a>
            </td>
          </tr>
          <tr class="new" v-show="selectedProfil !== -1 && selectedProfil !== 0">
            <td>
              <img src="/assets/images/new.svg">
              <in-autocomplete 
                :src="selectedProfil !== 10 ? '/api/user/autocomplete/' : '/api/user/autocomplete/ext/'" 
                v-model="newMember.login"
                dataField="login" 
                labelField="nom" 
                :emptyLabel="'Choisir un utilisateur'"
              ></in-autocomplete>
            </td>
            <td>
            
              <in-select
                v-show="$store.state.indexedConstants.profils[selectedProfil] && $store.state.indexedConstants.profils[selectedProfil].commune" 
                :data="$store.state.constants.communes"
                :labelField="'nom'" 
                v-model="newMember.num_commune" 
                emptyLabel="Choisir une commune" 
                dataField="num_commune"
              ></in-select>
            </td>
            <td class="small">
              <a href="#" :class="[!newMember.login && 'disabled']" @click.prevent="addNewMember">
                <img :src="newMember.login ? '/assets/images/plus.svg' : '/assets/images/plus_gris.svg'">
              </a>
            </td>
            <td></td>
          </tr>
        </table>
        <!--<a href="#" v-for="profil in $store.state.constants.profils">{{ profil.nom }}</a>-->
      </div>
    </div>
  </div>
  `
})