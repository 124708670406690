Vue.component('cell-domaine', {
  props: ['header', 'ticket', 'hideOrigine'],
  data() { return {
    pp: this.$parent.$parent
  }},
  template: /*html*/`
    <component :is="header ? 'th' : 'td'" class="domaine txt-right">
      <a v-if="header" href="#" @click.prevent="pp.sortBy('num_surCategorie')">
      </a>
      <div v-else="">
        <img :src="'/assets/images/' + $store.state.indexedConstants.surCategories[ticket.num_surCategorie].icone"></span>
      </div>
    </component>
  `
})