Vue.component('liste-tickets', {
  props: ['tickets'],
  data: function () { return {
    visible: false
  } },
  methods: {
    show () { this.visible = true },
    hide () { this.visible = false },
  },
  template: /*html*/`
  <div class="liste-tickets" v-show="visible">
    <div class="fond"></div>
    <a href="#" @click.prevent="visible = false" class="bouton-fermer"><img src="/assets/images/cross_w.svg"></a>
    <div class="contenu">
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Statut</th>
            <th>Priorité</th>
            <th>Délai</th>
            <th>Détails</th>
            <th>Photo</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ticket in tickets">
            <!--<td>{{ ticket.identifiant }}</td>-->
            <td>{{ formatDate(ticket.dateCreation, true) }}</td>
            <td class="status">
              <img v-if="ticket.status == -2" src="/assets/images/cross.svg">
              <img v-else-if="ticket.status == -1" src="/assets/images/hourglass.svg">
              <img v-else-if="ticket.status == 0" src="/assets/images/new.svg">
              <img v-else-if="ticket.status == 1" src="/assets/images/open.svg">
              <img v-else="" src="/assets/images/closed.svg">
              <br>
              <span v-if="ticket.status == -2">Refusé</span>
              <span v-else-if="ticket.status == -1">En&nbsp;validation</span>
              <span v-else-if="ticket.status == 0">Nouveau</span>
              <span v-else-if="ticket.status == 1">En&nbsp;cours</span>
              <span v-else="">Clôturé</span>
            </td>
            <td class="priorite-ticket" :class="['priorite-' + ticket.num_priorite]">
              {{ ticket.priorite || 'ND' }}
            </td>
            <td class="delai txt-center">
              {{ ticket.delai ? (ticket.delai + 'j') : 'N/A' }}
            </td>
            <td class="description">
            <router-link 
              :to="'/ticket/' + ticket.identifiant" 
              
            >
              <!--<img :src="'/assets/images/' + $store.state.indexedConstants.surCategories[ticket.num_surCategorie].icone">-->
              <span class="categorie">
                <span>{{ $store.state.indexedConstants.surCategories[ticket.num_surCategorie].nom }} </span> - 
                <span>{{ ticket.num_categorie ? $store.state.indexedConstants.categories[ticket.num_categorie].nom : '' }}</span>
              </span>
              <br>
              <em v-if="ticket.sujetMail || !ticket.description">(Non renseigné)</em>
              <span v-else="" v-html="addBr(ticket.description)"></span>
              <photo-bouton v-if="ticket.fichiers" :fichiers="ticket.fichiers"></photo-bouton>
            </router-link>
            </td>
            <td>
              <file-tb v-if="ticket.num_fichier" :numFichier="ticket.num_fichier"></file-tb>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  `
})