Vue.component('table-service', {
  props: ['excelFields'],
  computed: {
    tickets () { return this.$parent.tickets }
  },
  mounted () {
    this.$set(this.excelFields, 0, 'identifiant')
    this.$set(this.excelFields, 1, 'status')
    this.$set(this.excelFields, 2, 'dateCreation')
    this.$set(this.excelFields, 3, 'typeDemandeur')
    this.$set(this.excelFields, 4, 'prenomNom')
    this.$set(this.excelFields, 5, 'categorie')
    this.$set(this.excelFields, 6, 'sousCategorie')
    this.$set(this.excelFields, 7, 'description')
    this.$set(this.excelFields, 8, 'adresse')
    this.$set(this.excelFields, 9, 'commune')
  },
  template: /*html*/`
    <table class="table-accueil table-admin liste-tickets">
      <thead>
        <tr>
          <cell-id :header="true"></cell-id>
          <cell-status :header="true"></cell-status>
          <cell-date-creation :header="true"></cell-date-creation>
          <cell-demandeur :header="true"></cell-demandeur>
          <cell-description :header="true"></cell-description>
          <cell-adresse-bat :header="true"></cell-adresse-bat>
        </tr>
      </thead>
      <tbody>
        <tr v-for="ticket in tickets" :class="[ticket.status === 2 && 'cloture', ticket.status === -2 && 'dismissed']">
          <cell-id :ticket="ticket"></cell-id>
          <cell-status :ticket="ticket"></cell-status>
          <cell-date-creation :ticket="ticket"></cell-date-creation>
          <cell-demandeur :ticket="ticket"></cell-demandeur>
          <cell-description :ticket="ticket"></cell-description>
          <cell-adresse-bat :ticket="ticket"></cell-adresse-bat>
        </tr>
      </tbody>
    </table>
  `
})