PAGES['stats'] = Vue.component('page-stats', {
  data () { return {
    intervals: {},
    selectedInterval: null,
    scriptsLoaded: false,
    communes: [],
    communesChoisies: "",
    domaines: {patrimoine: [], voiePublique: []},
    domainesChoisis: "",
    categories: {patrimoine: [], voiePublique: []},
    categoriesChoisies: "",
    status: [],
    statusChoisis: "",
    pec: [{nom: 'Commune', data: 0}, {nom: 'Bassin de Pompey', data: 1}],
    pecCommuneChoisis: "0,1",
    total: 0,
    ongletActuel: 'voiePublique',
    patrimoineActif: false,
    voiePubliqueActif: false
  } },
  methods: {
    ranges () {
      var ranges = []
      var reference
    
      reference = moment()
      ranges.push({ nom: reference.format('MMMM YYYY'), debut: reference.clone().startOf('month'), fin:  reference.clone().endOf('month') })
    
      reference = moment().subtract(1, 'month')
      ranges.push({ nom: reference.format('MMMM YYYY'), debut: reference.clone().startOf('month'), fin: reference.clone().endOf('month') })
    
      reference = moment().subtract(1, 'quarter')
      ranges.push({ nom: reference.format('YYYY') + ' trimestre ' + reference.quarter(), debut: reference.clone().startOf('quarter'), fin:  reference.clone().endOf('quarter') })
    
      reference = moment().subtract(2, 'quarter')
      ranges.push({ nom: reference.format('YYYY') + ' trimestre ' + reference.quarter(), debut: reference.clone().startOf('quarter'), fin:  reference.clone().endOf('quarter') })
    
      reference = moment().subtract(3, 'quarter')
      ranges.push({ nom: reference.format('YYYY') + ' trimestre ' + reference.quarter(), debut: reference.clone().startOf('quarter'), fin:  reference.clone().endOf('quarter') })
    
      reference = moment().subtract(4, 'quarter')
      ranges.push({ nom: reference.format('YYYY') + ' trimestre ' + reference.quarter(), debut: reference.clone().startOf('quarter'), fin:  reference.clone().endOf('quarter') })
    
      reference = moment()
      ranges.push({ nom: 'Année ' + reference.format('YYYY'), debut: reference.clone().startOf('year'), fin: reference.clone().endOf('year') })
    
      reference = moment().subtract(1, 'year')
      ranges.push({ nom: 'Année ' + reference.format('YYYY'), debut: reference.clone().startOf('year'), fin: reference.clone().endOf('year') })
    
      reference = moment().subtract(2, 'year')
      ranges.push({ nom: 'Année ' + reference.format('YYYY'), debut: reference.clone().startOf('year'), fin: reference.clone().endOf('year') })

      ranges.push({ nom: 'Depuis toujours', debut: moment('2020-01-01'), fin: moment('2100-01-01') })
    
      return ranges    

    },
    updateTotal () {
      let me = this
      this.server.get("/api/stats/tickets/total/" + this.urlFilter).then((data) => {
        me.total = data.total
      })
    },
    excel () {
      this.server.get('/api/stats/excel/gen/' + this.urlFilter).then((data) => { 
        window.open('/api/stats/excel/' + data.file)
      })
    }
  },
  watch: {
    selectedInterval: function () { this.updateTotal() },
    communesChoisies: function () { this.updateTotal() },
    domainesChoisis: function () { this.updateTotal() },
    categoriesChoisies: function () { this.updateTotal() },
    statusChoisis: function () { this.updateTotal() },
    pecCommuneChoisis: function () { this.updateTotal() },
    ongletActuel: function () {
      let domainesChoisis = []
      for (let i = 0; i < this.domaines[this.ongletActuel].length; i += 1) {
        domainesChoisis.push(this.domaines[this.ongletActuel][i].num_surCategorie)
      }
      this.domainesChoisis = domainesChoisis.join(",")

      let categoriesChoisies = []
      for (let i = 0; i < this.categories[this.ongletActuel].length; i += 1) {
        categoriesChoisies.push(this.categories[this.ongletActuel][i].num_categorie)
      }
      this.categoriesChoisies = categoriesChoisies.join(",")
    }
  },
  mounted () {
    
    let me = this
    this.lazyLoadJS('/js/stats.js').then(function () {
      me.scriptsLoaded = true
      moment.locale('fr')
      me.intervals = me.ranges()
      Vue.nextTick(() => {
        me.selectedInterval = me.intervals[6]
      });
      me.server.get('/api/ticket/distincts/num_commune').then(function (data) {
        let allCommunes = []
        
        for (let i = 0; i < data.length; i += 1) {
          allCommunes.push(data[i] || "NULL")
          if (data[i]) {
            me.communes.push({
              num_commune: data[i],
              nom: me.$store.state.indexedConstants.communes[data[i]].nom
            })
          } else {
            me.communes.push({
              num_commune: "NULL",
              nom: "Non défini"
            })
          }
        }

        me.communesChoisies = allCommunes.join(",")

        me.communes.sort(function (elt1, elt2) {
          if (elt1.num_commune === "NULL") return 1
          else if (elt2.num_commune === "NULL") return -1
          else if (elt1.nom === elt2.nom) return 0
          else if (elt1.nom < elt2.nom) return -1
          else return 1
        })

      })
      me.server.get('/api/ticket/distincts/num_categorie').then(function (data) {
        let surcatParOnglet = { patrimoine: [2, 3], voiePublique: [1, 6, 8] }
        
        for (let i = 0; i < data.length; i += 1) {
          if (data[i]) {
            if (surcatParOnglet.patrimoine.indexOf(me.$store.state.indexedConstants.categories[data[i]].num_surCategorie) !== -1 ) {
              me.categories.patrimoine.push({
                num_categorie: data[i],
                nom: me.$store.state.indexedConstants.categories[data[i]].nom
              })
            }
            if (surcatParOnglet.voiePublique.indexOf(me.$store.state.indexedConstants.categories[data[i]].num_surCategorie) !== -1 ) {
              me.categories.voiePublique.push({
                num_categorie: data[i],
                nom: me.$store.state.indexedConstants.categories[data[i]].nom
              })
            }

          } else {
            me.categories.voiePublique.push({
              num_categorie: "NULL",
              nom: "Non défini"
            })
            me.categories.patrimoine.push({
              num_categorie: "NULL",
              nom: "Non défini"
            })
          }
        }

        me.categories.patrimoine.sort(function (elt1, elt2) {
          if (elt1.num_categorie === "NULL") return 1
          else if (elt2.num_categorie === "NULL") return -1
          else if (elt1.nom === elt2.nom) return 0
          else if (elt1.nom < elt2.nom) return -1
          else return 1
        })

        me.categories.voiePublique.sort(function (elt1, elt2) {
          if (elt1.num_categorie === "NULL") return 1
          else if (elt2.num_categorie === "NULL") return -1
          else if (elt1.nom === elt2.nom) return 0
          else if (elt1.nom < elt2.nom) return -1
          else return 1
        })

      })

      me.server.get('/api/ticket/distincts/status').then(function (data) {
        let allStatus = []
        
        for (let i = 0; i < data.length; i += 1) {
          allStatus.push(data[i])
          let nom
          switch (data[i]) {
            case -2: nom = 'Refusé'; break;
            case -1: nom = 'En validation'; break;
            case 0:  nom = 'Nouveau'; break;
            case 1: nom = 'En cours'; break;
            case 2: nom = 'Clôturé'; break;
          }
          me.status.push({
            status: data[i],
            nom: nom
          })
        }

        me.statusChoisis = allStatus.join(",")

        me.status.sort(function (elt1, elt2) {
          if (elt1.status === "NULL") return 1
          else if (elt2.status === "NULL") return -1
          else if (elt1.status === elt2.status) return 0
          else if (elt1.status < elt2.status) return -1
          else return 1
        })

      })

      me.server.get('/api/ticket/distincts/num_surCategorie').then(function (data) {
        let allSurCategories = { patrimoine: [], voiePublique: [] }
        let surCatParOnglet = { patrimoine: [2, 3], voiePublique: [1, 6, 8] }
        
        for (let i = 0; i < data.length; i += 1) {
          
          if (data[i]) {
            
            if (surCatParOnglet.patrimoine.indexOf(data[i]) !== -1 ) {
              me.patrimoineActif = true
              me.domaines.patrimoine.push({
                num_surCategorie: data[i],
                nom: me.$store.state.indexedConstants.surCategories[data[i]].nom
              })
              allSurCategories.patrimoine.push(data[i])
            }
            if (surCatParOnglet.voiePublique.indexOf(data[i]) !== -1 ) {
              me.voiePubliqueActif = true
              me.domaines.voiePublique.push({
                num_surCategorie: data[i],
                nom: me.$store.state.indexedConstants.surCategories[data[i]].nom
              })
              allSurCategories.voiePublique.push(data[i])
            }

          } else {
            me.domaines.patrimoine.push({
              num_surCategorie: "NULL",
              nom: "Non défini"
            })
            me.domaines.voiePublique.push({
              num_surCategorie: "NULL",
              nom: "Non défini"
            })

            allSurCategories.patrimoine.push("NULL")
            allSurCategories.voiePublique.push("NULL")
          }
        }

        if (me.patrimoineActif && !me.voiePubliqueActif) me.ongletActuel = "patrimoine"
        else me.ongletActuel = "voiePublique"

        me.domainesChoisis = allSurCategories[me.ongletActuel].join(",")

        let categoriesChoisies = []
        for (let i = 0; i < me.categories[me.ongletActuel].length; i += 1) {
          categoriesChoisies.push(me.categories[me.ongletActuel][i].num_categorie)
        }
        me.categoriesChoisies = categoriesChoisies.join(",")

        me.domaines.patrimoine.sort(function (elt1, elt2) {
          if (elt1.num_surCategorie === "NULL") return 1
          else if (elt2.num_surCategorie === "NULL") return -1
          else if (elt1.nom === elt2.nom) return 0
          else if (elt1.nom < elt2.nom) return -1
          else return 1
        })
        me.domaines.voiePublique.sort(function (elt1, elt2) {
          if (elt1.num_surCategorie === "NULL") return 1
          else if (elt2.num_surCategorie === "NULL") return -1
          else if (elt1.nom === elt2.nom) return 0
          else if (elt1.nom < elt2.nom) return -1
          else return 1
        })

      })
    })
  },
  computed: {
    startDate () {
      return  this.selectedInterval ? this.selectedInterval.debut.format('YYYY-MM-DD') : null
    },
    endDate () {
      return  this.selectedInterval ? this.selectedInterval.fin.format('YYYY-MM-DD') : null
    },
    urlFilter () {
      return  this.startDate + '/'
              + this.endDate
              + (this.communesChoisies.trim().length > 0 ? ('/' + this.communesChoisies) : '')
              + (this.domainesChoisis.trim().length > 0 ? ('/' + this.domainesChoisis) : '')
              + (this.categoriesChoisies.trim().length > 0 ? ('/' + this.categoriesChoisies) : '')
              + (this.statusChoisis.trim().length > 0 ? ('/' + this.statusChoisis) : '')
              + (this.pecCommuneChoisis.trim().length > 0 ? ('/' + this.pecCommuneChoisis) : '')
    }
  },
  template: /*html*/`
    <div class="page-stats">
      <div class="filters">
        <div class="choix-stats" v-show="voiePubliqueActif && patrimoineActif">
          <a href="#" :class="[ongletActuel === 'voiePublique' && 'active']" @click.prevent="ongletActuel = 'voiePublique'">VOIE PUBLIQUE</a>
          <a href="#" :class="[ongletActuel === 'patrimoine' && 'active']" @click.prevent="ongletActuel = 'patrimoine'">PATRIMOINE</a>
        </div>
        <div class="filter">
          Période :
          <in-select 
            :data="intervals"
            :labelField="'nom'" 
            v-model="selectedInterval" 
            :readOnly="false"
          ></in-select>
        </div>
        <div class="filter" v-show="communes.length > 1">
          Commune(s) :
          <in-multi-choices
            :data="communes"
            :labelField="'nom'" 
            v-model="communesChoisies" 
            dataField="num_commune"
          ></in-multi-choices>
        </div>
        <div class="filter" v-show="domaines[ongletActuel].length > 1">
          Domaine(s) :
          <in-multi-choices
            :data="domaines[ongletActuel]"
            :labelField="'nom'" 
            v-model="domainesChoisis" 
            dataField="num_surCategorie"
          ></in-multi-choices>
        </div>
        <div class="filter" v-show="categories[ongletActuel].length > 1">
          Catégorie(s) :
          <in-multi-choices
            :data="categories[ongletActuel]"
            :labelField="'nom'" 
            v-model="categoriesChoisies" 
            dataField="num_categorie"
          ></in-multi-choices>
        </div>
        <div class="filter" v-show="status.length > 1">
          Status(s) :
          <in-multi-choices
            :data="status"
            :labelField="'nom'" 
            v-model="statusChoisis" 
            dataField="status"
          ></in-multi-choices>
        </div>
        <div class="filter" v-show="status.length > 1">
          Prise en charge :
          <in-multi-choices
            :data="pec"
            :labelField="'nom'" 
            v-model="pecCommuneChoisis" 
            dataField="data"
          ></in-multi-choices>
        </div>
        <a href="#" @click.prevent="excel" class="button green">
          EXPORTER EN EXCEL
        </a>
      </div>
      <div class="total txt-center fs-21">{{ total }} Signalements</div>
      <div v-if="selectedInterval && scriptsLoaded" class="all-cards">

        <div class="row">
          <div class="card" style="min-width: 50%;" v-show="ongletActuel === 'voiePublique'"><div>
            <div class="title">Localisation des signalements</div>
            <div class="content">
              <cluster-map :dataSource="'/api/stats/points/' + urlFilter"></cluster-map>
              <!--<data-table :dataSource="'/api/stats/tickets/byCommune/' + urlFilter"></data-table>-->
            </div>
          </div></div>
          <div class="column" v-show="ongletActuel === 'patrimoine'">
            <div class="card w50"><div>
              <div class="title">Par bâtiment</div>
              <div class="content">
                <data-table :dataSource="'/api/stats/byBatiment/' + urlFilter"></data-table>
              </div>
            </div></div>
            <div class="card w50"><div>
              <div class="title">Par intervenant</div>
              <div class="content">
              <data-table :dataSource="'/api/stats/byIntervenants/' + urlFilter"></data-table>
              </div>
            </div></div>
          </div>
          <div class="column">
            <div class="card w50"><div>
              <div class="title">Par catégorie</div>
              <div class="content">
                <pie-chart :dataSource="'/api/stats/byCat/' + urlFilter"></pie-chart>
              </div>
            </div></div>
            <div class="card w50"><div>
              <div class="title">Par heure</div>
              <div class="content">
                <week-chart :dataSource="'/api/stats/byDayHour/' + urlFilter"></week-chart>
              </div>
            </div></div>
          </div>
        </div>
        <div class="card w100"><div>
          <div class="title">Nombre de tickets par jour</div>
          <div class="content">
            <curve-date-chart :dataSource="'/api/stats/byDay/' + urlFilter"></curve-date-chart>
          </div>
        </div></div>
        <div class="card w100"><div>
          <div class="title">Nombre de tickets par semaine</div>
          <div class="content">
            <histo-date-chart :dataSource="'/api/stats/byWeek/' + urlFilter"></histo-date-chart>
          </div>
        </div></div>

        <div class="row">
          <div class="card w33"><div>
            <div class="title">Délais d'interventions par catégorie</div>
            <div class="content">
              <data-table :dataSource="'/api/stats/delay/byCat/' + urlFilter"></data-table>
            </div>
          </div></div>
          <div class="card w33"><div>
            <div class="title">Délais d'interventions par priorité</div>
            <div class="content">
              <data-table :dataSource="'/api/stats/delay/byP/' + urlFilter"></data-table>
            </div>
          </div></div>
          <div class="card w33"><div>
            <div class="title">Tickets pas communes</div>
            <div class="content">
              <data-table :dataSource="'/api/stats/byCommunes/' + urlFilter"></data-table>
            </div>
          </div></div>
        </div>
      </div>
    </div>
  `
})