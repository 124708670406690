Vue.component('cell-agent', {
  props: ['header', 'ticket'],
  data() { return {
    pp: this.$parent.$parent
  }},
  template: `
    <component :is="header ? 'th' : 'td'" :class="[!header && ticket.pecCommune && 'violet']">
      <a v-if="header" href="#" @click.prevent="pp.sortBy('chefEquipe')">
        <span v-show="pp.queryParams.orderBy === 'chefEquipe' && pp.queryParams.orderDesc">&#9650;</span>
        <span v-show="pp.queryParams.orderBy === 'chefEquipe' && !pp.queryParams.orderDesc">&#9660;</span>
        Agent / Commune
      </a>
      <span v-else="">
        <span v-if="ticket.pecCommune">Commune de {{ ticket.commune }}</span>
        <span v-else-if="ticket.chefEquipe">{{ ticket.chefEquipe }}</span>
        <span v-else-if="ticket.chefEquipeAuto && !ticket.dateRefus"><em>({{ ticket.chefEquipeAuto }})</em></span>
      </span>
    </component>
  `
})