// https://openlayers.org/en/latest/examples/cluster.html

Vue.component('vue-carte', {
  props: [ 'coords' ],
  data: function () { return {
    idComponent: "map" + this._uid,
    map: null,
    positionFeature: null,
    layers: null,
    currentLayer: 0
  }},
  watch: {
    coords: function () {
      this.centerOnCoords()
    }
  },
  methods: {
    changeLayer () {
      this.layers[this.currentLayer].setVisible(false)
      this.currentLayer += 1
      this.currentLayer %= this.layers.length
      this.layers[this.currentLayer].setVisible(true)
    },
    centerOnCoords () {
      if (this.coords) {
        var coordinates = ol.proj.transform(this.coords, 'EPSG:4326', 'EPSG:3857' );
        if (this.coords) { this.positionFeature.setGeometry(new ol.geom.Point(coordinates)) }
        this.map.getView().setCenter(coordinates)
      }
    },
    createMap () {
      let me = this

      var view = new ol.View({
        center: ol.proj.transform(this.$store.state.defaultLoc, 'EPSG:4326', 'EPSG:3857'),
        zoom: 17,
        minZoom: 11,
        maxZoom: 19
      })

      // Create map
      me.layers = [
        new ol.layer.Tile({ source: new ol.source.OSM() }),
        new ol.layer.Tile({
          visible: false,
          preload: Infinity,
          source: new ol.source.XYZ({
            url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            maxZoom: 19
          })
          // source: new ol.source.BingMaps({
          //   key: 'AqrjdP72Od3rT6Xb695qJgm_NJC3JrjJ4minFPgM-RP9gj99GuZLbOzcHwuvXzk3',
          //   imagerySet: 'AerialWithLabelsOnDemand'
          // })
        })
      ]

      me.map = new ol.Map({
        target: this.idComponent,
        layers: me.layers,
        view: view
      });
      
      // Défintion du curseur
      me.positionFeature = new ol.Feature()
      me.positionFeature.setStyle(new ol.style.Style({
        image: new ol.style.Icon({
          anchor: [0.5, 34],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          src: '/assets/images/location.png'
        })
      }))

      new ol.layer.Vector({
        map: me.map,
        source: new ol.source.Vector({
          features: [me.positionFeature]
        }),
      })

      // me.positionFeature.setGeometry( ol.proj.transform(me.coords, 'EPSG:4326', 'EPSG:3857' ));


      // Add event on map
      me.map.on('moveend', function(evt) {
        // var map = evt.map;
        var coordinates = ol.proj.transform(
          me.map.getView().getCenter(),
          'EPSG:3857',
          'EPSG:4326'
          );
        // me.positionFeature.setGeometry( me.map.getView().getCenter() ? new ol.geom.Point( me.map.getView().getCenter()) : null)
      })



      me.map.on('singleclick', function(evt) {
        // var map = evt.map;
        var coordinates = ol.proj.transform(
            evt.coordinate,
            'EPSG:3857',
            'EPSG:4326'
        );
        // me.formData.longitude = coordinates[0]
        // me.formData.latitude = coordinates[1]
        me.map.getView().setCenter(evt.coordinate)
        // positionFeature.setGeometry(evt.coordinate ? new ol.geom.Point(evt.coordinate) : null)
      })

    },
    exportMap () {
      let me = this
      
      return new Promise((resolve, reject) => {
        me.map.getView().setZoom(17)
        setTimeout(() => {
          let exportOptions = {
            filter: function(element) {
              return element.className ? element.className.indexOf('ol-control') === -1 : true;
            }
          }
          
          domtoimage.toPng(document.getElementById(me.idComponent), exportOptions).then((data) => {
            resolve(data);
            // this.server.post('/saveMapImage', {name, data}).then((data) => {
            //   resolve(data)
            // })
          })

        }, 1000)
      })
    },
    updateMap () {
      let me = this
      setTimeout(() => {
        me.map.updateSize()
      }, 200);
      
    }
  },
  mounted : function () {
    setTimeout(() => {
      this.createMap()
      this.centerOnCoords()
    }, 200);
  },
  template: `
  <div class="vue-carte">
    <div :id="idComponent" class="map"></div>
    <a href="#" @click.prevent="changeLayer" class="change-layer-button">
      <img :src="currentLayer == 0 ? '/assets/images/satellite_32.png' : '/assets/images/map_32.png'">
      {{ currentLayer == 0 ? 'Satellite' : 'Route' }}
    </a>
  </div>
  `
})