Vue.component('in-geo', {
  props: [ 'overCoords', 'noGeoloc', 'mobile' ],
  data: function () { return {
    idComponent: "map" + this._uid,
    map: null,
    reverse: null,
    deviceLocation: null,
    emitEvent: true,
    layers: null,
    currentLayer: 0
  }},
  watch: {
    overCoords: function (oldCoords, newCoords) {
      if (newCoords) {
        if (newCoords[0] !== oldCoords[0]) { this.updateOverCoords() }
      }
    }
  },
  methods: {
    changeLayer () {
      this.layers[this.currentLayer].setVisible(false)
      this.currentLayer += 1
      this.currentLayer %= this.layers.length
      this.layers[this.currentLayer].setVisible(true)
    },
    createMap () {
      let me = this

      // Format result in the search input autocomplete
      var formatResult = function(feature, el) {
        var title = document.createElement('strong');
        el.appendChild(title);
        var detailsContainer = document.createElement('small');
        el.appendChild(detailsContainer);
        var details = [];
        title.innerHTML = feature.properties.label || feature.properties.name;
        var types = {
          housenumber: 'numéro',
          street: 'rue',
          locality: 'lieu-dit',
          hamlet: 'hameau',
          village: 'village',
          city: 'ville',
          commune: 'commune'
        };
        if (types[feature.properties.type]) {
          var spanType = document.createElement('span');
          spanType.className = 'type';
          title.appendChild(spanType);
          spanType.innerHTML = types[feature.properties.type];
        }
        if (feature.properties.city && feature.properties.city !== feature.properties.name) {
          details.push(feature.properties.city);
        }
        if (feature.properties.context) {
          details.push(feature.properties.context);
        }
        detailsContainer.innerHTML = details.join(', ');
      };
      
      var view = new ol.View({
        center: ol.proj.transform(this.$store.state.defaultLoc, 'EPSG:4326', 'EPSG:3857'),
        zoom: 13,
        minZoom: 11,
        maxZoom: 19,
        extent: ol.proj.transformExtent(
          [5.984664, 48.691623, 6.254699, 48.833391],
          'EPSG:4326', 'EPSG:3857'
        )
      })

      // Create map
      me.layers = [
        new ol.layer.Tile({ source: new ol.source.OSM() }),
        new ol.layer.Tile({
          visible: false,
          preload: Infinity,
          /*source: new ol.source.BingMaps({
            key: 'AqrjdP72Od3rT6Xb695qJgm_NJC3JrjJ4minFPgM-RP9gj99GuZLbOzcHwuvXzk3',
            imagerySet: 'AerialWithLabelsOnDemand'
          })*/
          source: new ol.source.XYZ({
            url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            maxZoom: 19
          })
        })
      ]

      me.map = new ol.Map({
        target: this.idComponent,
        layers: me.layers,
        view: view
      });
      
      // Function to show you can do something with the returned elements
      function myHandler(featureCollection) {
        console.debug(featureCollection);
      }
      
      // We reused the default function to center and zoom on selected feature.
      // You can make your own. For instance, you could center, zoom
      // and add a point on the map
      function onSelected(feature) {
        var coordinates = ol.proj.transform(
          [feature.geometry.coordinates[0], feature.geometry.coordinates[1]],
          'EPSG:4326',
          me.map.getView().getProjection()
        );
        me.map.getView().setCenter(coordinates);
        me.map.getView().setZoom(17);
      }
        
      // URL for API
      let API_CCBP_URL = '/api/geo/api-adresses/?'
      var API_BAN_URL = '//api-adresse.data.gouv.fr';
      
      // Create search by adresses component
      var container = new ol.Photon.Search(me.map, {
        resultsHandler: myHandler,
        onSelected: onSelected,
        placeholder: 'Renseigner l\'adresse',
        formatResult: formatResult,
        url: API_CCBP_URL,
      });
      
      // Add the created DOM element within the map
      // var controlGeocoder = new ol.Photon.AddDomControl(container, 'ol-photon-geocoder ol-unselectable ol-control');
      this.$refs.inputContainer.appendChild(container)

      // map.addControl(controlGeocoder);
      
      // Défintion du curseur
      let positionFeature = new ol.Feature()
      positionFeature.setStyle(new ol.style.Style({
        image: new ol.style.Icon({
          anchor: [0.5, 34],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          src: '/assets/images/location.png'
        })
      }))

      new ol.layer.Vector({
        map: me.map,
        source: new ol.source.Vector({
          features: [positionFeature]
        }),
      })

      // Select element and set options for reverse geocoding
      // var reverseEl = document.getElementById('reverse');
      var reverseEl = document.createElement('div');
      reverseEl.className = 'reverse';
      var optionsReverse = {
        url: API_BAN_URL + '/reverse/?',
        handleResults: function(data) {
          
          if (data.features.length) {
            reverseEl.innerHTML = '«' + data.features[0].properties.label + '»';
            // reverseEl.parentElement.style.display = 'block';
            reverseEl.parentElement.style.display = 'none';
            data.features[0].properties.coords = ol.proj.transform(me.map.getView().getCenter(),'EPSG:3857','EPSG:4326' )

            if (me.$el.querySelector('.photon-input')) {
              me.$el.querySelector('.photon-input').value = data.features[0].properties.name + ', ' + data.features[0].properties.city
            }

            if (me.emitEvent) { me.$emit('change', data.features[0].properties) }
            me.emitEvent = true
          } else {
            reverseEl.innerHTML = '';
            reverseEl.parentElement.style.display = 'none';
            if (me.emitEvent) { me.$emit('change', {coords: ol.proj.transform( me.map.getView().getCenter(), 'EPSG:3857', 'EPSG:4326' )}) }
            me.emitEvent = true
          }
          positionFeature.setGeometry( me.map.getView().getCenter() ? new ol.geom.Point( me.map.getView().getCenter()) : null)
        }
      };
      
      // Instanciate the reverse geocoding component
      me.reverse = ol.Photon.Reverse(optionsReverse);
      var controlReverseGeocoder = new ol.Photon.AddDomControl(reverseEl, 'ol-photon-reverse-geocoder ol-unselectable ol-control');
      me.map.addControl(controlReverseGeocoder);
      
      if (document.querySelector('.ol-photon-reverse-geocoder')) {
        document.querySelector('.ol-photon-reverse-geocoder').style.display = 'none'
      }
      
      // Add event on map
      me.map.on('moveend', function() {
        // var map = evt.map;
        var coordinates = ol.proj.transform(
          me.map.getView().getCenter(),
          'EPSG:3857',
          'EPSG:4326'
          );
          if (me.map.getView().getZoom() > 12) {
            me.reverse.doReverse(coordinates);
          } else {
            // If we dezoom and then move, we will keep displaying the wrong adress, hence this solution
            reverseEl.innerHTML = '';
            reverseEl.parentElement.style.display = 'none';
          }
      })



      me.map.on('singleclick', function(evt) {
        // var map = evt.map;
        var coordinates = ol.proj.transform(
            evt.coordinate,
            'EPSG:3857',
            'EPSG:4326'
        );
        // me.formData.longitude = coordinates[0]
        // me.formData.latitude = coordinates[1]
        me.map.getView().setCenter(evt.coordinate)
        if (me.map.getView().getZoom() > 14) {
            me.reverse.doReverse(coordinates);
        }
        // positionFeature.setGeometry(evt.coordinate ? new ol.geom.Point(evt.coordinate) : null)
      })

      // GEOLOCALISATION
      if (!me.noGeoloc) {
        // let locationActive  = false
        let geolocation     = new ol.Geolocation({
          // enableHighAccuracy must be set to true to have the heading value
          trackingOptions: {
              enableHighAccuracy: true
          },
          tracking: true,
          projection: view.getProjection()
        })

        let setCurrentLocation = function () {
          me.map.getView().setCenter(me.deviceLocation)
          me.map.getView().setZoom(17)
          // positionFeature.setGeometry( map.getView().getCenter() ? new ol.geom.Point( map.getView().getCenter()) : null)
          me.reverse.doReverse(me.deviceLocation)
          // locationActive = true
          geolocation.getPosition()
        }

        geolocation.on('change' , function() {
          me.deviceLocation = geolocation.getPosition();
          geolocation.setTracking(false)
          setCurrentLocation()
          // if (locationActive === true) {
          //   let position = geolocation.getPosition()
          //   map.getView().setCenter(position)
          //   map.getView().setZoom(17)
          //   let transformPosition = ol.proj.transform(position, 'EPSG:3857', 'EPSG:4326')
          //   positionFeature.setGeometry( map.getView().getCenter() ? new ol.geom.Point( map.getView().getCenter()) : null)
          //   // me.formData.longitude = transformPosition[0]
          //   // me.formData.latitude = transformPosition[1]
          //   reverse.doReverse(transformPosition)
          //   // activeGeolocation = false
          // // }
        })

        this.$refs.geoLocButton.addEventListener('click', (e) => {
          e.preventDefault()
          setCurrentLocation()
        })
      }
    },
    updateOverCoords() {
      this.emitEvent = false
      if (this.overCoords) {
        
        var coordinates = ol.proj.transform(
          this.overCoords,
          'EPSG:4326',
          'EPSG:3857'
        );

        // this.map.getView().setZoom(16);
        this.map.getView().setCenter(coordinates)
        if (this.map.getView().getZoom() > 14) {
          this.reverse.doReverse(this.overCoords);
        }
      }
    },
    updateMap () {
      let me = this
      setTimeout(() => {
        me.map.updateSize()
      }, 200);
      
    }
  },
  mounted () {
    let me = this
    setTimeout(() => {
      me.createMap()
      if (me.overCoords) {
        me.updateOverCoords()
      }
    }, 200);
    
  },
  template: `
  <div :class="['in-geo', noGeoloc && 'no-geoloc', mobile && 'mobile']">
    <!-- {{overCoords}} -->
    <!-- {{deviceLocation}} -->
    <div ref="inputContainer" class="input-container">
      <a v-if="!noGeoloc" href="#" ref="geoLocButton" class="geoloc" :class="{'active': deviceLocation}"><i class="icon-myLocation fs-20" aria-hidden="true"></i><br>Ma position</a>
    </div>
    
    <div :id="idComponent" class="map"></div>
    <a href="#" @click.prevent="changeLayer" class="change-layer-button">
      <img :src="currentLayer == 0 ? '/assets/images/satellite_32.png' : '/assets/images/map_32.png'">
      {{ currentLayer == 0 ? 'Satellite' : 'Route' }}
    </a>
  </div>
  `
})