PAGES['delegation'] = Vue.component('page-delegation', {
  data: () => { return {
    delegations : [],
    newDelegation: {
      delegue: null,
      debut: null,
      fin: null
    }
  }},
  watch: { },
  methods: {
    loadDelegations: function () {
      let me = this
      this.newDelegation = {
        delegue: null,
        debut: null,
        fin: null
      }

      this.server.get('/api/user/delegations').then((data) => {
        let now = this.now
        for (let i = 0; i < data.length; i += 1) {
          data[i].active = data[i].debut < now && data[i].fin > now
        }
        me.delegations = data
        
      })
    },
    saveDelegation: function () {
      let me = this
      if (this.newDelegation.debut < this.now && this.newDelegation.fin > this.now) this.$store.commit('setUserDelegue', this.newDelegation.delegue)
      this.server.post('/api/user/saveDelegation', this.newDelegation).then(() => me.loadDelegations())
    },
    deleteDelegation: function (delegation) {
      let me = this
      this.$store.commit('setModal', {
        visible: true,
        message: 'Confirmez-vous la suppression de la délégation à <em>' + delegation.delegueNomComplet + '</em> du <em>' + this.formatDateComplete(delegation.debut) + '</em> au <em>' + this.formatDateComplete(delegation.fin)  + '</em>',
        title: '<span class="txt-red">&#9888;</span> Suppression de la délégation',
        type: 'validation',
        okCallback () {
          if (delegation.active) me.$store.commit('setUserDelegue', null)
          me.server.get('/api/user/removeDelegation/' + delegation.debut).then(() => me.loadDelegations())
        }
      })
    }
  },
  filters: { },
  computed: {
    newDelegationValide : function () {
      if (!this.newDelegation.delegue || !this.newDelegation.debut || !this.newDelegation.fin) return false
      if (this.newDelegation.fin <= this.newDelegation.debut) return false

      for (let i = 0; i < this.delegations.length; i += 1) {
        if (this.newDelegation.debut <= this.delegations[i].fin && this.newDelegation.fin >= this.delegations[i].debut) return false
      }     
      return true 
    }
  },
  mounted: function () {
    this.loadDelegations()
  },
  template: /*html*/`
    <div class="page-delegation">
      <table>
        <thead>
          <tr>
            <th colspan="2">Délégué</th>
            <th>Début</th>
            <th colspan="2">Fin</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td>
              <in-autocomplete 
                src="/api/user/autocomplete/" 
                v-model="newDelegation.delegue"
                dataField="login" 
                labelField="nom" 
                :emptyLabel="'Choisir un délégué'"
              ></in-autocomplete>
            </td>
            <td>
              <in-date v-model="newDelegation.debut" emptyValue="(non renseigné)" :time="false" :noEraseButton="true"></in-date>
            </td>
            <td>
              <in-date v-model="newDelegation.fin" emptyValue="(non renseigné)" :time="false" :noEraseButton="true"></in-date>
            </td>
            <td class="txt-center"><a href="#" class="button blue" :class="[!newDelegationValide && 'disabled']" @click.prevent="saveDelegation">Sauver</a></td>
          </tr>
          <tr v-for="delegation in delegations" :class="[delegation.active && 'active']">
            <td>{{ delegation.active ? 'Active' :'' }}</td>
            <td class="delegue">{{ delegation.delegueNomComplet }}</td>
            <td class="date">{{ formatDateComplete(delegation.debut, false) }}</td>
            <td class="date">{{ formatDateComplete(delegation.fin, false) }}</td>
            <td class="txt-center"><a href="#" class="button red" @click.prevent="deleteDelegation(delegation)">Supprimer</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  `
})