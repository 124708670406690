Vue.component('mod-photo-1', {
  props: ['params'],
  data () { return { viewers: []  }},
  methods: {
    setPhoto (infosPhoto) {
      this.ticket.numFichiers.push(infosPhoto.split(':')[0])
    },
    showPhoto (i) {
      if (!this.viewers[i]) {
        this.viewers[i] = new Viewer(this.$refs['photo' + i][0], {
          title: 0,
          navbar: 0,
          transition: true,
          toolbar: { zoomIn: {show: 1, size: 'large' }, 
            zoomOut: {show: 1, size: 'large' }, oneToOne: 0, reset: 0, prev: {show: 1, size: 'large' }, play: 0, next: {show: 1, size: 'large' }, rotateLeft: {show: 1, size: 'large' }, rotateRight: {show: 1, size: 'large' }, flipHorizontal: 0, flipVertical: 0 }
        })
      }
      this.viewers[i].show()
    }
  },
  beforeDestroy () {
    for (let i in this.viewers) {
      if (this.viewers.hasOwnProperty(i)) {
        this.viewers[i].destroy()
      }
    }
  },
  computed: {
    ticket () { return this.$parent.ticket }
  },
  template: /*html*/`
    <div class="module">
      <h2>{{ params.titre || (params.toutFichier ? 'Photos / fichiers' : 'Photo') }}</h2>
      <in-fichier :allFiles="params.toutFichier" v-model="ticket.files.photo" :maxFiles="params.toutFichier ? 9 : 1" :linkClass="params.toutFichier ? '' : 'gros-rond-gris'" :readOnly="params.readWrite ? false : true">
        <img v-if="!params.toutFichier" src="/assets/images/camera.svg">
      </in-fichier>
    </div>
  `
})