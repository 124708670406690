Vue.component('pie-chart', {
  props: ['dataSource'],
  data: function () { return {
    recalcTimeout: null
  }},
  watch : {
    dataSource () {
      if (this.recalcTimeout) clearTimeout(this.recalcTimeout)
      let me = this
      this.recalcTimeout = setTimeout(() => {
        me.chart.dataSource.url = me.dataSource
        me.chart.dataSource.load()
      }, 500);
    }
  },
  mounted () {
    let chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart);

    chart.dataSource.url = this.dataSource

    chart.exporting.setFormatOptions("csv", {separator : ";", addColumnNames : true})
    chart.exporting.menu = new am4core.ExportMenu()
    chart.exporting.menu.align = "right"
    chart.exporting.menu.verticalAlign = "bottom"
    chart.exporting.menu.items = [{
      "label": "X",
      "type" : "csv"
    }];

    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "signalements";
    pieSeries.dataFields.category = "categorie";

    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeOpacity = 1;

    pieSeries.labels.template.fontSize = 12;
    pieSeries.labels.template.text = "{category} - {value} ({value.percent.formatNumber('##.')} %) ";

    this.chart = chart;
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  template: `
    <div class="pie-chart" ref="chartdiv" style="width:100%;height:300px;">
    </div>
  `
})