Vue.component('filter-type-demandeur', {
  props: [],
  data() { return {
    field: 'typeDemandeur', label:'DEMANDEUR', showOnMobile: false, values: [
      {data: 'BIPM', label: 'BIPM'},
      {data: 'USAGER', label: 'USAGER'},
      {data: 'AGENT', label: 'AGENT'},
      {data: 'COMMUNE', label: 'COMMUNE'}
    ] 
  }},
  methods: {
  },
  template: `
    <home-filter :field="field" :label="label" :showOnMobile="showOnMobile" :values="values" ></home-filter>
  `
})