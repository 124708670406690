Vue.component('in-long-text', {
  props: ['value', 'emptyLabel', 'maxLength', 'readOnly', 'emptyValue'],
  data: function () { return {
    content: this.value
  }},
  methods: {
    handleInput: function () {
      this.$emit('input', this.content)
    }
  },
  watch: {
    value: function () {
      this.content = this.value
    }
  },
  template: /*html*/`
    <div class="in-long-text" :class="[readOnly && 'read-only']">
      <textarea v-show="!readOnly" v-model="content" @input="handleInput" :placeholder="emptyLabel" :maxlength="maxLength || 4096"></textarea>
      <span class="value" v-show="readOnly" v-html="content || emptyLabel"></span>
    </div>
  `
})