Vue.component('mod-dom-pdl-service', {
  props: ['params', 'newTicket'],
  data () { return {
    readOnly: true,
    resultatsVisite: [
      {data: 'aut' , label: 'Autorisation'},
      {data: 'autPres', label: 'Autorisation avec prescription'},
      {data: 'refus', label: 'Refus'},
    ]
  }},
  methods: {
    save () {  this.$parent.updateTicket(['prenomNom', 'email', 'telephone']) },
    reload () {
      this.$parent.loadTicket()
      this.readOnly = true
    }
  },
  computed: {
    ticket () { return this.$parent.ticket },
    runningCheck () {return this.$parent.runningCheck },
    newTicket () { return this.$parent.newTicket },
  },
  template: /*html*/`
    <div class="module">
      <h2>
        Service
        <a href="#" v-if="params.readWrite && !newTicket" class="button green" v-show="readOnly" @click.prevent="readOnly = false">CORRIGER</a>
        <a href="#" v-if="params.readWrite && !newTicket" class="button blue" v-show="!readOnly" @click.prevent="save">ENREGISTRER</a>
        <a href="#" v-if="params.readWrite && !newTicket" class="button red" v-show="!readOnly" @click.prevent="reload">ANNULER</a>
      </h2>
      <table>
        <tr>
          <td class="label">Date d'envoi :</td>
          <td class="field">
            <in-date v-model="ticket.pdl_dateEnvoi" emptyValue="(non renseigné)" :time="false" :readOnly="(!newTicket && readOnly)"></in-date>
          </td>
        </tr>
        <tr>
          <td class="label">Date d'accusé réception :</td>
          <td class="field">
            <in-date v-model="ticket.pdl_dateReception" emptyValue="(non renseigné)" :time="false" :readOnly="(!newTicket && readOnly)"></in-date>
          </td>
        </tr>
        <tr>
          <td class="label">Date de visite :</td>
          <td class="field">
            <in-date v-model="ticket.pdl_dateVisite" emptyValue="(non renseigné)" :time="false" :readOnly="(!newTicket && readOnly)"></in-date>
          </td>
        </tr>
        <tr>
          <td class="label">Résultat de la visite :</td>
          <td class="field">
          <in-select 
            :data="resultatsVisite"
            :labelField="'label'" 
            v-model="ticket.pdl_resultatVisite" 
            emptyLabel="(non renseigné)" 
            dataField="data"
            :readOnly="(!newTicket && readOnly)"
          ></in-select>
          </td>
        </tr>
        <tr>
          <td class="label">Date du rapport :</td>
          <td class="field">
            <in-date v-model="ticket.pdl_dateRapport" emptyValue="(non renseigné)" :time="false" :readOnly="(!newTicket && readOnly)"></in-date>
          </td>
        </tr>
        <tr>
          <td class="label">Date de la 2<sup>ème</sup> visite :</td>
          <td class="field">
            <in-date v-model="ticket.pdl_dateVisite2" emptyValue="(non renseigné)" :time="false" :readOnly="(!newTicket && readOnly)"></in-date>
          </td>
        </tr>
        <tr>
          <td class="label">Date d'envoi de l'arrêté :</td>
          <td class="field">
            <in-date v-model="ticket.pdl_dateArrete" emptyValue="(non renseigné)" :time="false" :readOnly="(!newTicket && readOnly)"></in-date>
          </td>
        </tr>
        <tr>
          <td class="label">Dossier complet :</td>
          <td class="field">
          <in-bool v-model="ticket.pdl_complet"  :readOnly="(!newTicket && readOnly)"></in-bool>
          </td>
        </tr>
        <tr>
          <td class="field" colspan="2">
            <in-fichier v-model="ticket.files.pdl_arrete" label="Arrêté" :readOnly="(!newTicket && readOnly && false)" templateFile="pdl_arrete.docx" :maxFiles="1"></in-fichier>
          </td>
        </tr>
      </table>
    </div>
  `
})