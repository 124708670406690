Vue.component('cell-date-creation', {
  props: ['header', 'ticket'],
  data() { return {
    pp: this.$parent.$parent
  }},
  template: /*html*/`
    <component :is="header ? 'th' : 'td'" class="txt-center">
      <a v-if="header" href="#" @click.prevent="pp.sortBy('dateCreation')">
        <span v-show="pp.queryParams.orderBy === 'dateCreation' && pp.queryParams.orderDesc">&#9650;</span>
        <span v-show="pp.queryParams.orderBy === 'dateCreation' && !pp.queryParams.orderDesc">&#9660;</span>
        Date
      </a>
      <span v-else="">
        {{formatDate(ticket.dateCreation, true)  }}
      </span>
    </component>
  `
})