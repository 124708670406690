Vue.component('filter-domaine', {
  props: [],
  data() { 
    let surCatFilter = []
    for(let i = 0; i < this.$store.state.constants.surCategories.length; i += 1) {
      surCatFilter.push({ data: this.$store.state.constants.surCategories[i].num_surCategorie, label: this.$store.state.constants.surCategories[i].nom })
    }
    return {
      field: 'num_surCategorie',
      label:'DOMAINE', 
      showOnMobile: false, 
      values: surCatFilter 
    }
  },
  methods: {
  },
  template: /*html*/`
    <home-filter :field="field" :label="label" :showOnMobile="showOnMobile" :values="values" ></home-filter>
  `
})