Vue.component('mod-origine-1', {
  props: ['params'],
  data () { return { }},
  methods: { },
  computed: {
    ticket () { return this.$parent.ticket }
  },
  template: `
    <div class="module">
      <h2>Origine de la demande</h2>
        <div class="choix-origine">
          <a v-for="origine in $store.state.constants.origines" href="#" @click.prevent="ticket.num_origine = origine.num_origine" :class="[ticket.num_origine === origine.num_origine && 'active']">
            {{ origine.nom }}
          </a>
        </div>
    </div>
  `
})