Vue.component('in-bool', {
  props: ['value', 'readOnly', 'label', 'trueFalse', 'small', 'switchVersion'],
  watch: {
    value (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('input', this.value);
      }
    }
  },
  methods: {
    inverseValue () {
      if (!this.readOnly) {

        if (this.trueFalse) {
          this.value = !this.value
        } else {
          this.value = (!this.value ? 1 : 0)
        }
      }
    }
  },
  template: `
    <div class="in-bool" :class="[small && 'small']">
      <img :class="[readOnly && 'disabled']" :src="switchVersion ? '/assets/images/switch-on.svg' : '/assets/images/closed.svg'" v-show="value === (trueFalse ? true : 1)" @click="inverseValue">
      <img :class="[readOnly && 'disabled']" :src="switchVersion ? '/assets/images/switch-off.svg' : '/assets/images/cross.svg'" v-show="value === (trueFalse ? false : 0) || !value" @click="inverseValue">
      <a href="#" @click.prevent="inverseValue" v-if="label">{{ label }}</a>
    </div>
  `
})