Vue.component('curve-date-chart', {
  props: ['dataSource'],
  data: function () { return {
    recalcTimeout: null
  }},
  watch : {
    dataSource () {
      if (this.recalcTimeout) clearTimeout(this.recalcTimeout)
      let me = this
      this.recalcTimeout = setTimeout(() => {
        me.chart.dataSource.url = me.dataSource
        me.chart.dataSource.load()
      }, 500);
    }
  },
  mounted () {
    const format = 'dd/MM/YY';  
    let valueLabel = new am4charts.CircleBullet()
    valueLabel.circle.text = "{value}"
    valueLabel.circle.fontSize = 12
    // valueLabel.locationY = .5
    valueLabel.circle.radius = 4
    valueLabel.circle.fill = am4core.color("#fff")
    valueLabel.circle.strokeWidth = 2

    let chartConfig = {
      dateFormatter: {
        dateFormat : "dd/MM/YY"
      },
      "yAxes": [ { "type": "ValueAxis" } ],
    
      "xAxes": [
        {
          "id": "dates",
          "type": "DateAxis",
          cursorTooltipEnabled: false,
          "dataFields": { "category": "date" },
          "dateFormats": {
            "day": format,
            "week": format,
            "month": format,
            "year": format
          },
          "periodChangeDateFormats": {
            "day": format,
            "week": format,
            "month": format,
          },
          "renderer": {
            minGridDistance : 30,
            "labels": {
              "location": 0.5,
              rotation: -45,
              fontSize: 12,
              dx: -30,
              dy: -10
            }
          }
        }
      ],
      
      "scrollbarX": {},
    
      "cursor": {
        "type": "XYCursor",
        "lineY": { "disabled": false },
        "xAxis": "dates",
        "fullWidthLineX": true,
        "lineX": {
          "strokeWidth": 0,
          "fill": "#333333",
          "fillOpacity": 0.1
        }
      },
    
      "series": [
        {
          "type": "LineSeries",
          "name": "Value",
          tensionX : .9,
          strokeWidth : 3,
          fillOpacity : 0.5,
          tooltipText: "{dateX}: [bold]{valueY}[/]",
          "dataFields": {
            "valueY": "value",
            "dateX": "date"
          },
          bullets: [ valueLabel ],
          // columns: {
          //   template: {
          //     tooltipText: "{dateX}: [bold]{valueY}[/]"
          //   }
          // }
        }
      ],
      
      "dataSource": { url : this.dataSource }
    };
    
    let chart = am4core.createFromConfig(chartConfig ,this.$refs.chartdiv, am4charts.XYChart);
    
    chart.exporting.setFormatOptions("csv", {separator : ";", addColumnNames : true})
    chart.exporting.menu = new am4core.ExportMenu()
    chart.exporting.menu.align = "right"
    chart.exporting.menu.verticalAlign = "bottom"
    chart.exporting.menu.items = [{
      "label": "X",
      "type" : "csv"
    }];


    // chart.paddingRight = 20;

    // let data = [];
    // let visits = 10;
    // for (let i = 1; i < 366; i++) {
    //   visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
    //   data.push({ date: new Date(2018, 0, i), name: "name" + i, value: visits });
    // }

    // chart.data = data;

    // let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    // dateAxis.renderer.grid.template.location = 0;

    // let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.tooltip.disabled = true;
    // valueAxis.renderer.minWidth = 35;

    // let series = chart.series.push(new am4charts.LineSeries());
    // series.dataFields.dateX = "date";
    // series.dataFields.valueY = "value";

    // series.tooltipText = "{valueY.value}";
    // chart.cursor = new am4charts.XYCursor();

    // let scrollbarX = new am4charts.XYChartScrollbar();
    // scrollbarX.series.push(series);
    // chart.scrollbarX = scrollbarX;

    this.chart = chart;
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  template: `
    <div class="xy-chart" ref="chartdiv" style="width:100%;height:500px;">
    </div>
  `
})