Vue.component('in-number', {
  props: ['value', 'emptyLabel', 'readOnly', 'emptyValue', 'small', 'error', 'errorMessage', 'unite'],
  data: function () { return {
    content: this.value
  }},
  methods: {
    handleInput: function () {
      
      this.$emit('input', this.content)
    },
    setFilter () {
      if (this.$refs['input']) {
        let inputFilter = function(value) {
          return /^\d*\.?\d*$/.test(value)
        }
  
        let events =  ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"]
        for (let i = 0; i < events.length; i += 1) {
          this.$refs['input'].addEventListener(events[i], function() {
            if (inputFilter(this.value)) {
              this.oldValue = this.value;
              this.oldSelectionStart = this.selectionStart;
              this.oldSelectionEnd = this.selectionEnd;
            } else if (this.hasOwnProperty("oldValue")) {
              this.value = this.oldValue;
              this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
            } else {
              this.value = "";
            }
          });
        }
      }
    }
  },
  watch: {
    value: function () {
      this.content = this.value
    },
    readOnly () {
      this.$nextTick(this.setFilter)
    }
  },
  mounted () {
    this.setFilter()
  },
  template: `
    <div class="in-number" :class="[small && 'small', error && 'error']">
      <span v-if="readOnly" v-html="content || emptyValue"></span>
      <input v-else="" :class="[small && 'small']" type="text" v-model="content" @input="handleInput" :placeholder="emptyLabel" :ref="'input'">
      <span v-if="content && unite" v-html="unite"></span>
      <div class="indication" v-show="error">{{ errorMessage || 'Format incorrect' }}</div>
    </div>
  `
})