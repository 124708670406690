Vue.component('week-chart', {
  props: ['dataSource'],
  data: function () { return {
    recalcTimeout: null
  }},
  watch : {
    dataSource () {
      if (this.recalcTimeout) clearTimeout(this.recalcTimeout)
      let me = this
      this.recalcTimeout = setTimeout(() => {
        me.chart.dataSource.url = me.dataSource
        me.chart.dataSource.load()
      }, 500);
    }
  },
  mounted () {
    let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

    chart.dataSource.url = this.dataSource

    chart.maskBullets = false;
    
    var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    
    xAxis.dataFields.category = "jour";
    yAxis.dataFields.category = "heure";
    
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.minGridDistance = 40;
    
    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.inversed = true;
    yAxis.renderer.minGridDistance = 30;
    
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = "jour";
    series.dataFields.categoryY = "heure";
    series.dataFields.value = "signalements";
    series.sequencedInterpolation = true;
    series.defaultState.transitionDuration = 3000;
    
    var columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 2;
    columnTemplate.strokeOpacity = 1;
    columnTemplate.stroke = am4core.color("#ffffff");
    columnTemplate.tooltipText = "{jour}. {heure}h : {value.workingValue.formatNumber('#.')} signalements";
    columnTemplate.width = am4core.percent(100);
    columnTemplate.height = am4core.percent(100);
    
    series.heatRules.push({
      target: columnTemplate,
      property: "fill",
      min: am4core.color("#fafafa"),
      max: am4core.color("#67b7dc")
    });
    
    // heat legend
    var heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
    heatLegend.width = am4core.percent(100);
    heatLegend.series = series;
    heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
    heatLegend.valueAxis.renderer.minGridDistance = 30;
    
    // heat legend behavior
    series.columns.template.events.on("over", (event) => {
      handleHover(event.target);
    })
    
    series.columns.template.events.on("hit", (event) => {
      handleHover(event.target);
    })
    
    function handleHover(column) {
      if (!isNaN(column.dataItem.value)) {
        heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
      }
      else {
        heatLegend.valueAxis.hideTooltip();
      }
    }
    
    series.columns.template.events.on("out", () => {
      heatLegend.valueAxis.hideTooltip();
    })
    
    this.chart = chart;
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  template: `
    <div class="pie-chart" ref="chartdiv" style="width:400px;height:300px;margin: 0 auto;">
    </div>
  `
})