Vue.component('filter-priorite', {
  props: [],
  data() { return {
    field: 'num_priorite', label:'PRIORITÉ', showOnMobile: true, values: [
      {data: null, label: 'ND'},
      {data: 1, label: 'P1'},
      {data: 2, label: 'P2'},
      {data: 3, label: 'P3'},
      {data: 4, label: 'REFUS'},
      {data: 5, label: 'Au'},
    ]
  }},
  methods: {
  },
  template: `
    <home-filter :field="field" :label="label" :showOnMobile="showOnMobile" :values="values" ></home-filter>
  `
})