Vue.component('in-autocomplete', {
  props: ['value', 'src', 'dataField', 'labelField', 'default', 'readOnly', 'emptyLabel'],
  data () { return {
    fieldContent: "",
    editing: false,
    selectedItem: null
  }},
  watch: {
    value: function (v) {
      this.value = v ? v : '';
      if (!this.value) this.selectedItem = null
    }
  },
  methods: {
    changeKey (e) { 
      if (e.keyCode === 13) {
        this.$emit('keyup', e);
      }
    },
    launchEditing () {
      this.editing = true
      let me = this
      setTimeout(() => {
        me.$el.querySelector('input').focus()
        me.$el.querySelector('input').select()
      }, 50);
      
    }
  },
  mounted: function () {
    var me = this
    new autoComplete({
      data: {
        src: function () {
          return new Promise((resolve) => {
            const query = me.$el.querySelector('input').value
            if (query.trim() === '') {
              resolve([])
            } else {
              me.server.get( me.src + query).then((data) => {
                resolve(data)
              })
            }
          })
        },
        cache: false,
        key: [me.labelField]
      },
      selector: () => this.$el.querySelector('input'),
      resultsList: {
        render: true,
        container: source => {
          source.removeAttribute('id')
          source.classList.add('autoComplete-list')
        }
      },
      onSelection: feedback => {
        me.editing = false
        me.$el.querySelector('input').value = feedback.selection.value[me.labelField]
        me.selectedItem = feedback.selection.value
        me.value = feedback.selection.value[me.dataField]
        me.$emit('input', feedback.selection.value[me.dataField])
        me.$emit('change', feedback.selection.value[me.dataField])
      },
      debounce: 300,
      trigger: {
        event: ["input", "focusin", "focusout"]
      },
      highlight: true
    })

    this.$el.querySelector('input').addEventListener('focus', () => {
      me.$el.querySelector('.autoComplete-list').style.display = 'block'
    })
    this.$el.querySelector('input').addEventListener('blur', () => {
      me.$el.querySelector('.autoComplete-list').style.display = 'none'
    })

    if (this.default) {
      this.$el.querySelector('input').value = this.default[this.labelField]
      // this.value = this.default[this.dataField]
      this.selectedItem = this.default
    }
    
    // $(this.$el).find('input').autocomplete({ 
    //   serviceUrl: URL_SERVEUR + 'ac.php?form=' + this.$store.state.formulaire.nom +'&champ=' + this.champ,
    //   onSelect: function(suggestion) {
    //     me.value = suggestion.value
    //     me.$emit('input', me.value)
    //     me.$emit('change', me.value)
    //   },
    //   noCache: true
    // })
    
  },
  template: /*html*/`
    <div class="in-autocomplete">
      <input @keyup="changeKey" type="text" v-show="editing" @blur="editing = (selectedItem ? false : true)">
      <a href="#" v-show="!editing && !readOnly" @click.prevent="launchEditing">{{ selectedItem ? (selectedItem[labelField] || (emptyLabel || 'Non défini')) : (emptyLabel || 'Non défini') }}</a>
      <span v-show="readOnly">{{ selectedItem ? (selectedItem[labelField] || (emptyLabel || 'Non défini')) : (emptyLabel || 'Non défini') }}</span>
    </div>
  `
})
