Vue.component('modal-refus-signalement', {
  props: ['value'],
  data: function () { return {
    content: this.value
  }},
  methods: {
    handleInput: function () {
      this.$emit('input', this.content)
    }
  },
  watch: {
    value: function () {
      this.content = this.value
    }
  },
  computed: {
    modal: function () { return this.$store.state.modal },
  },
  mounted () {
    if (this.modal.num_surCategorie && this.modal.num_surCategorie === 2) {
      this.value = `Bonjour, <br><br>Votre demande : " { description } " a été refusée.<br><br>Vous remerciant de votre compréhension, <br><br>Cordialement,<br><br>La Direction Infrastructures Déplacements et Equipements<br>de la Communauté de communes du bassin de Pompey`
    } else {
      this.value = `Madame, Monsieur, <br><br>Nous avons bien reçu votre demande : " { description } ".<br><br>Nous tenons malheureusement à vous informer que ce type de demande n’a pas vocation à être traitée via l’outil « Mon Appli » dédié aux désordres survenant sur l’espace public.<br><br>En effet, votre demande nécessiterait une analyse particulière et approfondie, ainsi que des échanges pouvant impliquer plusieurs organismes.<br><br>De ce fait, ce type de demande doit être documenté et adressé <b>par courrier</b> à l’attention du <b>Président de la communes du Bassin de Pompey – BP 60008 – Rue des 4 Eléments – 54340 POMPEY</b>.<br><br>Vous remerciant de votre compréhension, <br><br>Cordialement,<br><br>La Direction Infrastructures Déplacements et Equipements<br>de la Communauté de communes du bassin de Pompey`
    }
  },
  template: /*html*/`
    <div>
      <in-rte v-model="content" :maxlength="4096" @change="handleInput"></in-rte>
    </div>
  `
})