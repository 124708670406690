// https://openlayers.org/en/latest/examples/cluster.html

Vue.component('cluster-map', {
  props: [ 'dataSource' ],
  data: function () { return {
    idComponent: "map" + this._uid,
    map: null,
    hmSource: new ol.source.Vector({features: []}),
    satellite: false,
    bingLayer: null,
    osmLayer: null,
    ticketsList: [],
    recalcTimeout: null
  }},
  watch: {
    dataSource: function() {
      let me = this
      if (this.recalcTimeout) clearTimeout(this.recalcTimeout)
      this.recalcTimeout = setTimeout(() => {
        me.server.get(me.dataSource).then((data) => {
          let allFeatures = []
          for (let i = 0; i < data.length; i += 1) {
            allFeatures.push(new ol.Feature(new ol.geom.Point(ol.proj.transform([data[i].longitude, data[i].latitude], 'EPSG:4326', 'EPSG:3857' ))))
            for(var key in data[i]) { if(data[i].hasOwnProperty(key)){
              allFeatures[allFeatures.length - 1].set(key, data[i][key])
            }}
          }
          me.hmSource.clear()
          me.hmSource.addFeatures(allFeatures)
        })
      }, 500);
      
    }
  },
  methods: {
    changeLayer () {
      if (this.satellite) {
        this.satellite = false
        this.osmLayer.setVisible(true)
        this.bingLayer.setVisible(false)
      } else {
        this.satellite = true
        this.osmLayer.setVisible(false)
        this.bingLayer.setVisible(true)
      }
    },
    // centerOnCoords () {
    //   if (this.coords) {
    //     var coordinates = ol.proj.transform(this.coords, 'EPSG:4326', 'EPSG:3857' );
    //     if (this.coords) { this.positionFeature.setGeometry(new ol.geom.Point(coordinates)) }
    //     this.map.getView().setCenter(coordinates)
    //   }
    // },
    createMap () {
      let me = this

      var view = new ol.View({
        center: ol.proj.transform([6.12, 48.762], 'EPSG:4326', 'EPSG:3857'),
        zoom: 12,
        minZoom: 11,
        maxZoom: 19
      })


      var clusterSource = new ol.source.Cluster({
        distance: 70,
        source: this.hmSource
      })

      var styleCache = {};
      let clustersLayer = new ol.layer.Vector({
        source: clusterSource,
        style: function(feature) {
          var size = feature.get('features').length;
          var style = styleCache[size];
          if (!style) {
            style = new ol.style.Style({
              image: new ol.style.Circle({
                radius: 10 * Math.ceil(Math.log10(size*5)),
                stroke: new ol.style.Stroke({
                  color: '#fff'
                }),
                fill: new ol.style.Fill({
                  color: '#3399CC'
                })
              }),
              text: new ol.style.Text({
                text: size > 1 ? size.toString() : '',
                fill: new ol.style.Fill({
                  color: '#fff'
                })
              })
            });
            styleCache[size] = style;
          }
          return style;
        }
      })

      me.osmLayer = new ol.layer.Tile({ source: new ol.source.OSM() })
      me.bingLayer = new ol.layer.Tile({
        visible: false,
        preload: Infinity,
        source: new ol.source.BingMaps({
          key: 'AqrjdP72Od3rT6Xb695qJgm_NJC3JrjJ4minFPgM-RP9gj99GuZLbOzcHwuvXzk3',
          imagerySet: 'AerialWithLabelsOnDemand'
        })
      })

      // Create map
      let layers = [ me.osmLayer, me.bingLayer, clustersLayer ]

      me.map = new ol.Map({
        target: this.idComponent,
        layers: layers,
        view: view
      });
      

      me.map.on('singleclick', function(evt) {
        // function isCluster(feature) {
        //   if (!feature || !feature.get('features')) { 
        //         return false; 
        //   }
        //   return feature.get('features').length > 1;
        // }

        var feature = me.map.forEachFeatureAtPixel(evt.pixel, function(feature) { return feature; })
        var identifiants = []
        // if (isCluster(feature) || true) {
          // is a cluster, so loop through all the underlying features
          var features = feature.get('features');
          for(var i = 0; i < features.length; i++) {
            // here you'll have access to your normal attributes:
            identifiants.push(features[i].get('identifiant'))
          }
        // } else {
        //   identifiants.push(feature.get('identifiant'))
        //   // not a cluster
        // }
        

        me.server.post('/api/stats/tickets/list', {identifiants: identifiants}).then(function (data) {
          me.ticketsList = data
        })
        me.$refs.liste.show()  

        // var map = evt.map;
        // var coordinates = ol.proj.transform(
        //     evt.coordinate,
        //     'EPSG:3857',
        //     'EPSG:4326'
        // );
        // me.hmSource.addFeature(new ol.Feature(new ol.geom.Point(evt.coordinate)))
        // hmLayer.setSource(hmSource)
        // me.formData.longitude = coordinates[0]
        // me.formData.latitude = coordinates[1]
        // me.map.getView().setCenter(evt.coordinate)
        // positionFeature.setGeometry(evt.coordinate ? new ol.geom.Point(evt.coordinate) : null)
      })

    },
    exportMap: function () {
      let me = this
      
      return new Promise((resolve) => {
        me.map.getView().setZoom(17)
        setTimeout(() => {
          let exportOptions = {
            filter: function(element) {
              return element.className ? element.className.indexOf('ol-control') === -1 : true;
            }
          }
          
          domtoimage.toPng(document.getElementById(me.idComponent), exportOptions).then((data) => {
            resolve(data);
            // this.server.post('/saveMapImage', {name, data}).then((data) => {
            //   resolve(data)
            // })
          })

        }, 1000)
      })
    },
    updateMap () {
      let me = this
      setTimeout(() => {
        me.map.updateSize()
      }, 200);
      
    }
  },
  mounted () {
    let me = this
    setTimeout(() => {
      me.createMap()
      // me.server.get(me.dataSource).then((data) => {
      //   let allFeatures = []
      //   for (let i = 0; i < data.length; i += 1) {
      //     allFeatures.push(new ol.Feature(new ol.geom.Point(ol.proj.transform([data[i].longitude, data[i].latitude], 'EPSG:4326', 'EPSG:3857' ))))
      //     for(var key in data[i]) { if(data[i].hasOwnProperty(key)){
      //       allFeatures[allFeatures.length - 1].set(key, data[i][key])
      //     }}
      //   }
      //   me.hmSource.clear()
      //   me.hmSource.addFeatures(allFeatures)
      // })
      // this.centerOnCoords()
    }, 200);
  },
  template: /*html*/`
  <div class="heat-map">
    <liste-tickets :tickets="ticketsList" ref="liste"></liste-tickets>
    
    <div :id="idComponent" class="map"></div>
    <!-- <a href="#" @click.prevent="changeLayer" class="change-layer-button">
      <img :src="satellite ? '/assets/images/map_32.png' : '/assets/images/satellite_32.png'">
      {{ satellite ? 'Route' : 'Satellite' }}
    </a>-->
  </div>
  `
})