Vue.component('radar-date-chart', {
  props: ['dataSource'],
  data () { return {
  }},
  watch : {
    dataSource () {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      this.server.get(this.dataSource).then((data) => {
        this.chart.data = data
        this.chart.update()
      })
    }
  },
  mounted () {
    var config = {
      type: 'bar',
      data: {labels: [], datasets:[]},
      options: {
        responsive: true,
        scales: { x: { stacked: true }, y: { stacked: true } }
      }
    };
    this.chart = new Chart( this.$refs.chartcanvas, config )
    this.loadData()
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  template: /*html*/`
    <canvas class="xy-chart" ref="chartcanvas" style="width:100%;height:500px;">
    </canvas>
  `
})