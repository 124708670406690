Vue.component('banner-print-suppress', {
  props: ['params'],
  methods: {
    deleteTicket () {
      let me = this
      me.$store.commit('setModal', {
        visible: true,
        message: "Voulez-vous vraiment supprimer ce ticket ?",
        title: 'Suppression du ticket',
        type: 'validation',
        okCallback () {
          me.server.get('/api/ticket/delete/' + me.ticket.num_ticket).then(() => {
            me.$store.commit('setDataUpdated')
            router.push('/accueil')
          })
        }
      })
    }
  },
  computed: {
    ticket () { return this.$parent.ticket },
  },
  template: /*html*/`
    <div class="">
      <div class="buttons txt-center">
        <a :href="'/api/ticket/pdf/' + ticket.num_ticket" target="_blank" class="button blue">IMPRIMER UNE FICHE</a>
        <a href="#"  v-if="params.suppression" class="button red" @click.prevent="deleteTicket">SUPPRIMER</a>
      </div>
    </div>
  `
})