Vue.component('in-text', {
  props: ['value', 'emptyLabel', 'readOnly', 'emptyValue', 'small', 'error', 'errorMessage'],
  data: function () { return {
    content: this.value
  }},
  methods: {
    handleInput: function () {
      
      this.$emit('input', this.content)
    }
  },
  watch: {
    value: function () {
      this.content = this.value
    }
  },
  template: /*html*/`
    <div class="in-text" :class="[small && 'small', error && 'error', readOnly && 'read-only']">
      <span v-if="readOnly" class="value" v-html="content || ('<em>' + emptyValue + '</em>')"></span>
      <input v-else="" :class="[small && 'small']" type="text" v-model="content" @input="handleInput" :placeholder="emptyLabel">
      <div class="indication" v-show="error">{{ errorMessage || 'Format incorrect' }}</div>
    </div>
  `
})