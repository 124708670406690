Vue.component('cell-priorite', {
  props: ['header', 'ticket'],
  data() { return {
    pp: this.$parent.$parent
  }},
  template: `
    <component :is="header ? 'th' : 'td'" :class="[!header && ('priorite-' + ticket.num_priorite)]" class="priorite-ticket">
      <a v-if="header" href="#" @click.prevent="pp.sortBy('num_priorite')">
        <span v-show="pp.queryParams.orderBy === 'num_priorite' && pp.queryParams.orderDesc">&#9650;</span>
        <span v-show="pp.queryParams.orderBy === 'num_priorite' && !pp.queryParams.orderDesc">&#9660;</span>
        Priorité
      </a>
      <span v-else="">
        {{ ticket.priorite || 'ND' }}
      </span>
    </component>
  `
})