PAGES['formTicket'] = Vue.component('page-form-ticket', {
  data: function () { return {
    // ticket:       { },
    demandeur:    null,
    fichiers:     [],
    datesLimites: [],
    // readOnly:     true,
    commentaires: [],
    echanges:     [],
    valideur:     null,
    intituleCat:  '',
    
    mapUpdatedFromBatiment: false,
    ticketUpdated: false,
    justLoaded: false,
  }},
  methods: {
    markRead () {
      this.server.get('/api/ticket/echange/readTicket/' + this.ticket.num_ticket)
    },
        
    showMail () {
      this.$store.commit('setModal', {
        visible: true,
        message: this.ticket.descriptionHTML,
        title: this.ticket.sujetMail,
        type: 'info',
        isImage: false,
      })
    },
    deleteTicket () {
      let me = this
      this.$store.commit('setModal', {
        visible: true,
        message: `Êtes-vous sûr de vouloir supprimer ce ticket ?`,
        title: 'Confirmation de suppression',
        type: 'validation',
        okCallback: function () {
          me.server.get('/api/ticket/delete/' + me.ticket.num_ticket).then(() => { me.$router.push('/') })
          me.$store.commit('setDataUpdated')
        }
      })
      
    },
    print () {
      let me = this
      this.$store.commit('incRunnnigQueries');
      this.$refs.vuecarte.exportMap().then((data) => { 
        this.server.post('/api/ticket/pdf/gen', {num_ticket : me.ticket.num_ticket, map_data: data}).then(() => {
          window.open('/api/ticket/pdf/' + me.ticket.num_ticket, '_blank')
          me.$store.commit('decRunnnigQueries');
        })
      })
    },

    
    
  },
  computed: {
    resume () { return this.$route.name === 'pageResumeTicket';  },
    ticket () { return this.$refs.formticket ? this.$refs.formticket.ticket : {} }
  },
  mounted: function () { },
  template: /*html*/`
    <div class="page-form-ticket">
      <!-- <h1>{{ resume ? 'Votre demande' :'Ticket n° ' + ($route.params.uniqueId || $route.params.identifiant) }}</h1> -->
      <form-ticket v-if="resume" :ticketUniqueId="$route.params.uniqueId"></form-ticket>
      <form-ticket v-else="" :ticketId="$route.params.identifiant"></form-ticket>
    </div>
  `
})