Vue.component('cell-demandeur', {
  props: ['header', 'ticket', 'hideOrigine', 'hideType'],
  data() { return {
    pp: this.$parent.$parent
  }},
  template: /*html*/ `
    <component :is="header ? 'th' : 'td'" :class="[!hideType && 'demandeur']">
      <a v-if="header" href="#" @click.prevent="pp.sortBy('prenomNom')">
        <span v-show="pp.queryParams.orderBy === 'prenomNom' && pp.queryParams.orderDesc">&#9650;</span>
        <span v-show="pp.queryParams.orderBy === 'prenomNom' && !pp.queryParams.orderDesc">&#9660;</span>
        Demandeur
      </a>
      <span v-else="">
        <span v-if="!hideOrigine && false">
          <span v-if="ticket.num_origine === 4"><img src="/assets/images/mail.svg"></span>
          <span v-else-if="ticket.num_origine === 2"><img src="/assets/images/phone.svg"></span>
          <span v-else-if="ticket.num_origine === 3"><img src="/assets/images/letter.svg"></span>
          <span v-else=""><img src="/assets/images/app.svg"></span>
        </span>

        <span v-if="!hideType">
          <span v-if="ticket.typeDemandeur === 'AGENT'"><img src="/assets/images/ccbp.svg"></span>
          <span v-else-if="ticket.typeDemandeur === 'BIPM'"><img src="/assets/images/police.svg"></span>
          <span v-else-if="ticket.typeDemandeur === 'COMMUNE'"><img src="/assets/images/city.svg"></span>
          <span v-else=""><img src="/assets/images/citizen.svg"></span>
        </span>

        {{ ticket.prenomNom || ticket.prenomNomC }}
      </span>
    </component>
  `
})