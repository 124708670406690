Vue.component('in-file-direct', {
  props: ['value', 'readOnly', 'label', 'templateFile', 'hideTb', 'linkClass'],
  data: function () { return {
    numFichier: this.value
  }},
  methods: {
    setFile (infosPhoto) {
      if (infosPhoto) this.numFichier = infosPhoto.split(':')[0]
      else this.numFichier = null
      this.$emit('input', this.numFichier)
    },
    genDoc () {
      let me = this
      this.server.post('/api/image/genDoc/' + this.templateFile, this.$parent.ticket).then(function (data) {
        me.numFichier = data.num_fichier
        me.$emit('input', me.numFichier)
      })
    }
  },
  watch: {
    value: function () {
      this.numFichier = this.value
    }
  },
  computed: {
    hasSlotData() {
      return this.$slots.default;
    }
  },
  template: /*html*/`
    <div class="in-file-direct">
      <div class="file-label" v-show="label">{{ label }}</div>
      <a class="button blue rond" href="#" v-show="templateFile && !readOnly && !numFichier" @click.prevent="genDoc"><img src="/assets/images/roue_w.svg"></a>
      <in-file v-show="!numFichier && !readOnly" @add="setFile" :allFiles="true" :linkClass="linkClass">
        <a v-if="!hasSlotData" class="button green" href="#"><img src="/assets/images/plus_w.svg"></a>
        <slot></slot>
      </in-file>
      <!--<a class="button red"  v-show="!numFichier" href="#"><img src="/assets/images/cross_w.svg"></a>
      <a class="button blue"  v-show="!numFichier" href="#"><img src="/assets/images/fleche_bas_w.svg"></a>-->
      <file-tb v-show="numFichier && !hideTb" :numFichier="numFichier" :suppressOption="!readOnly" :alwaysDownload="true" @suppress="setFile(null)"></file-tb>
    </div>
  `
})