Vue.component('filter-status', {
  props: [],
  data() { return {
    field: 'status',
    label:'STATUT', 
    showOnMobile: true, 
    values: [
      {data: -1, label: 'ATTENTE DE VALIDATION'},
      {data: 0, label: 'OUVERT'},
      {data: 1, label: 'EN COURS'},
      {data: 2, label: 'CLÔTURÉ'}
    ] 
  }},
  methods: {
  },
  template: `
    <home-filter :field="field" :label="label" :showOnMobile="showOnMobile" :values="values" ></home-filter>
  `
})