Vue.component('mod-traitement-1', {
  props: ['params'],
  data () { return {
    readOnly: true,
    editingClot: false
  }},
  methods: {
    save () { this.$parent.updateTicket(['chefEquipe', 'dateRealisation', 'tempsRealisation', 'num_priorite'])  },
    reload () {
      this.$parent.loadTicket()
      this.readOnly = true
      this.editingClot = false
    },
    saveClot () {
      let me = this
      me.$store.commit('setModal', {
        visible: true,
        message: `Ce ticket sera clôturé, et un message sera envoyé au demandeur pour l'avertir.<br><br>
                Confirmez-vous ce changement ?`,
        title: '<span class="txt-red">&#9888;</span> Clôture du ticket',
        type: 'validation',
        okCallback () {
          me.server.post('/api/ticket/updateClot', me.ticket).then(() => {
            me.$emit('reload')
            me.$store.commit('setDataUpdated')
          })
        }
      })
    }
  },
  computed: {
    ticket () { return this.$parent.ticket }
  },
  template: /*html*/`
    <div class="module">
      <h2>
        Traitement
        <a href="#" v-if="params.readWrite" class="button green" v-show="readOnly" @click.prevent="readOnly = false">CORRIGER</a>
        <a href="#" v-if="params.readWrite" class="button blue" v-show="!readOnly" @click.prevent="save">ENREGISTRER</a>
        <a href="#" v-if="params.readWrite" class="button red" v-show="!readOnly" @click.prevent="reload">ANNULER</a>
      </h2>
      <table>
          <tr>
            <td class="label">Agent :</td>
            <td class="field">
              <in-select 
                :data="$store.state.constants.intervenants[ticket.num_surCategorie]"
                :labelField="'nom'" 
                v-model="ticket.chefEquipe" 
                emptyLabel="Aucun agent affecté" 
                dataField="login"
                :readOnly="readOnly || num_profil < 40"
              ></in-select>
            </td>
          </tr>
          <tr v-if="ticket.chefEquipe">
            <td class="label">Priorité :</td>
            <td class="field">
              <!--<div v-show="readOnly">
                <a v-if="params.binaire" href="#" :class="'priorite-' + ticket.num_priorite" style="pointer-events:none">
                  <span v-if="!ticket.num_priorite">ND</span>
                  <span v-else-if="ticket.num_priorite === 1">URGENT</span>
                  <span v-else-if="ticket.num_priorite === 2">STANDARD</span>
                </a>
              </div>-->
              <div v-if="params.binaire">
                <in-select-buttons :data="[{data: 1, label: 'URGENT', color: '#e55951'}, {data: 2, label: 'STANDARD', color: '#45b046'}]" v-model="ticket.num_priorite" :readOnly="readOnly"></in-select-buttons>
                <!--{{ ticket.num_priorite }}-->

                <!--<a href="#" class="button green" :style="{ backgroundColor: '#eee !important', color: '#888 !important', boxShadow: 'none' }">Standard</a>
                <a href="#" class="button red">Urgent</a>
                <a href="#" class="button violet">Refus</a>-->
              </div>
              <a v-else="" href="#" :class="'priorite-' + ticket.num_priorite" style="pointer-events:none">
                {{ ticket.num_priorite ? $store.state.indexedConstants.priorites[ticket.num_priorite].nom : 'ND' }}
              </a>
            </td>
          </tr>
          <tr v-if="ticket.chefEquipe && !params.binaire">
            <td class="label">Date limite :</td>
            <td class="field">
              <div class="read-only">
                <span v-html="formatDateComplete(ticket.dateLimite) || '<em>(Pas de priorité définie)</em>'" class="value"></span>
              </div>
            </td>
          </tr>
          <tr v-if="(!ticket.dateRealisation && (params.cloture || ticket.chefEquipe === login)) || editingClot">
            <td colspan="2" class="txt-center">
              <a href="#" class="button red" v-show="!editingClot && ticket.chefEquipe && ticket.num_priorite" @click.prevent="editingClot = true">CLÔTURER CE TICKET</a>
              <a href="#" class="button blue" v-show="editingClot" @click.prevent="saveClot" :class="(!ticket.dateRealisation || !ticket.tempsRealisation) && 'disabled'">ENREGISTRER</a>
              <a href="#" class="button red" v-show="editingClot" @click.prevent="reload">ANNULER</a>
            </td>
          </tr>
          <tr v-show="ticket.dateRealisation || editingClot">
            <td class="label">Date de réalisation :</td>
            <td class="field">
              <in-date v-model="ticket.dateRealisation" :readOnly="readOnly && !editingClot" emptyValue="(non renseigné)" :time="false"></in-date>
            </td>
          </tr>
          <tr v-show="ticket.dateRealisation || editingClot">
            <td class="label">Temps de réalisation :</td>
            <td class="field">
              <in-duration v-model="ticket.tempsRealisation" :emptyValue="'(non renseigné)'" :readOnly="readOnly && !editingClot"></in-duration>
            </td>
          </tr>
        </table>
    </div>
  `
})