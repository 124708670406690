Vue.component('mod-cloture-1', {
  props: ['params'],
  data () { return {
    readOnly: true,
    editingClot: false
  }},
  methods: {
    save () { this.$parent.updateTicket(['dateRealisation', 'tempsRealisation'])  },
    reload () {
      this.$parent.loadTicket()
      this.readOnly = true
      this.editingClot = false
    },
    saveClot () {
      let me = this
      me.$store.commit('setModal', {
        visible: true,
        message: `Ce ticket sera clôturé, et un message sera envoyé au demandeur pour l'avertir.<br><br>
                Confirmez-vous ce changement ?`,
        title: '<span class="txt-red">&#9888;</span> Clôture du ticket',
        type: 'validation',
        okCallback () {
          me.$set(me.ticket, 'chefEquipe', me.login)
          me.server.post('/api/ticket/updateClot', me.ticket).then(() => {
            me.$emit('reload')
            me.$store.commit('setDataUpdated')
          })
        }
      })
    }
  },
  computed: {
    ticket () { return this.$parent.ticket }
  },
  template: /*html*/`
    <div class="module">
      <h2>Clôture</h2>
      <table v-show="!ticket.dateCloture">
        <tr>
          <td colspan="2" class="txt-center">
            <a href="#" class="button red" @click="saveClot" :class="[!ticket.dateRealisation && 'disabled']">CLÔTURER CE TICKET</a>
          </td>
        </tr>
        <tr>
          <td class="label">Date de réalisation :</td>
          <td class="field">
            <in-date v-model="ticket.dateRealisation" emptyValue="(non renseigné)" :time="false"></in-date>
          </td>
        </tr>
      </table>
      <div v-show="ticket.dateCloture" class="txt-center fs-12">
        Ce ticket a été réalisé le <b>{{ formatDateComplete(ticket.dateRealisation) }}</b> par <b>{{ ticket.chefEquipe }}</b>.
      </div>
    </div>
  `
})