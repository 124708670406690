Vue.component('in-duration', {
  props: ['value', 'readOnly', 'emptyValue'],
  data: function () { return {
    content: this.value,
    choices: [30, 60, 90, 120, 180]
  }},
  methods: {
    handleInput: function () {
      this.content = parseInt(this.content.toString().replace(/[^0-9]/g, ''))
      if (isNaN(this.content)) { this.content = null }
      this.$emit('input', this.content)
    },
    formatValue: function (value) {
      return value ? (Math.floor(value / 60) + 'h' + ('0' + (value % 60)).slice(-2)) : '0h00'
    }
  },
  watch: {
    value: function () {
      this.content = this.value
    }
  },
  computed: {
    formattedContent: function () {
      return this.formatValue(this.content)
    }
  },
  template: /*html*/`
    <div class="in-duration" :class="[readOnly && 'read-only']">
      <span v-if="readOnly" v-html="content ? formattedContent : emptyValue" class="value"></span>
      <div v-else="">
        <div>
          <a 
            v-for="(choice, index) in choices" 
            href="#"
            @click.prevent="content = choice; handleInput()"
          >
            {{ formatValue(choice) }}
          </a>
        </div>
        <div>
          <a href="#" @click.prevent="content -= 60; handleInput()">-1h</a>
          <a href="#" class="last" @click.prevent="content -= 15; handleInput()">-15min</a>
          <span>{{formattedContent}}</span>
          <a href="#" class="first" @click.prevent="content += 15; handleInput()">+15min</a>
          <a href="#" @click.prevent="content += 60; handleInput()">+1h</a>
        </div>
      </div>
    </div>
  `
})