Vue.component('data-table', {
  data : function () { return {
    donnees: [],
    entetes: [],
    recalcTimeout: null
  }},
  props: ['dataSource'],
  watch : {
    dataSource () {
      if (this.recalcTimeout) clearTimeout(this.recalcTimeout)
      let me = this
      this.recalcTimeout = setTimeout(() => {
        me.loadData()
      }, 500);
      
    }
  },
  methods: {
    loadData: function () {
      let me = this
      this.server.get(this.dataSource).then((data) => {
        me.entetes.splice(0, me.entetes.length)
        if (data.length > 0) {
          for (const [key, ] of Object.entries(data[0])) {
            me.entetes.push(key)
          }
          me.donnees = data;
        }
      })
    }
  },
  mounted () {
    this.loadData()
  },
  template: /*html*/`
    <table class="data-table">
      <thead>
        <tr>
          <th v-for="entete in entetes">{{ entete }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="donnee in donnees">
          <td v-for="entete in entetes">
            {{ donnee[entete] }}
          </td>
        </tr>
      </tbody>
    </table>
  `
})