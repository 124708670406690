Vue.component('in-fichier', {
  data: function() {
    return {
      id: "inFichier" + this._uid,
      uploadRunning: false,
      pct: 0,
      fichiers: []
    }
  },
  watch: {
    value: function () {
      this.fichiers = this.value
    }
  },
  props: [ 'value', 'readOnly', 'uploadProgressCb', 'linkClass', 'allFiles', 'maxFiles', 'hideTb', 'disableSuppress', 'label', 'templateFile', 'autosave' ],
  computed: {
    hasSlotData() {
      return this.$slots.default;
    }
  },
  methods: {
    emitInput : function () {
      if (this.autosave && this.autosave.num_ticket) {
        this.server.post('/api/ticket/saveFiles', {num_ticket: this.autosave.num_ticket, champ: this.autosave.champ, fichiers: this.fichiers}).then((data) => {
        })
      }
      this.$emit('input', this.fichiers)
    },
    removeFile: function(num_fichier) {
      this.fichiers.splice(this.fichiers.indexOf(num_fichier), 1)
      this.emitInput()
    },
    genDoc () {
      let me = this
      this.server.post('/api/image/genDoc/' + this.templateFile, this.$parent.ticket).then(function (data) {
        if (!me.fichiers) {
          me.fichiers = []
        }
        me.fichiers.push(data.num_fichier)
        me.emitInput()
      })
    }
  },
  mounted: function () {
    var me = this
    this.fichiers = this.value
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      new ss.SimpleUpload({
        button: me.id,
        url: '/api/image/upload', // server side handler
        responseType: 'string',
        name: 'file',
        dropzone: me.id,
        multiple: false,
        allowedExtensions: me.allFiles ? ["jpg", "jpeg", "jpe", "png", "gif", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf"] : ["jpg", "jpeg", "jpe", "png", "gif"],
        autoSubmit: true,

        onSubmit: function ( ) {
          me.uploadRunning = true
          me.$emit("uploadstart")

        },

        onComplete:   function(filename, response) {
          me.uploadRunning = false
          if (response) {
            if (!me.fichiers) {
              me.fichiers = []
            }
            me.fichiers.push(parseInt(response.split(':')[0]))
          }          
          me.emitInput()
        },
        onProgress: function (pct) {
          me.pct = pct
          if (me.uploadProgressCb) {
            me.uploadProgressCb(pct)
          }
        }
      })
    }, 200);
    
  },
  template: /*html*/`
    <div class="in-fichier">
      <div v-show="label" class="label">{{ label }}</div>
      <file-tb v-if="!hideTb" v-for="fichier in fichiers" :numFichier="fichier" :suppressOption="!readOnly && !disableSuppress" @suppress="removeFile(fichier)"></file-tb>
      <div v-show="(!fichiers || !maxFiles || fichiers.length < maxFiles) && !readOnly">
        <span v-show="uploadRunning && pct != 100">{{ pct }} %</span>
        <span v-show="uploadRunning && pct == 100">Traitement...</span>
        <a class="button blue rond" href="#" v-show="templateFile && !readOnly && (!fichiers || fichiers.length === 0)" @click.prevent="genDoc"><img src="/assets/images/roue_w.svg"></a>
        <a v-show="!uploadRunning" href="#" :id="id" :class="linkClass" style="z-index:5">
          <a v-if="!hasSlotData" class="button green rond" href="#"><img src="/assets/images/plus_w.svg"></a>
          <slot></slot>
        </a>
      </div>
      <div v-show="(!fichiers || fichiers.length === 0) && readOnly" class="fs-12"><em>Aucun fichier</em></div>
    </div>
  `
})

