Vue.component('in-select', {
  props: ['value', 'labelField', 'data', 'emptyLabel', 'dataField', 'readOnly'],
  data: function () { return {
    content: this.getContent()
  }},
  methods: {
    handleInput: function () {
      this.$emit('input', this.data[this.content] ? (this.dataField ? this.data[this.content][this.dataField] : this.data[this.content])  : null)
    },
    getContent: function () {
      if (this.value !== null) {
        if (this.dataField) {
          for (let i = 0; i < this.data.length; i += 1) {
            if (this.data[i][this.dataField] == this.value) {
              return i
            }
          }
        } else {
          return this.data.indexOf(this.value)
        }
      }

      return -1
    }
  },
  watch: {
    data: function (newData, oldData) {
      if (this.content !== this.getContent() && oldData.length !== 0) {
        this.content = -1
        this.$emit('input', null)
      }
    },
    value: function () {
      this.content = this.getContent()
    }
  },
  template: /*html*/`
    <div class="in-select" :class="[readOnly && 'read-only']">
      <span v-if="readOnly" class="value" v-html="data[getContent()] ? data[getContent()][labelField] : ('<em>' + emptyLabel + '</em>' || '')"></span>
      <select v-else="" v-model="content" @change="handleInput">
        <option v-if="emptyLabel" value="-1">{{ emptyLabel }}</option>
        <option v-for="(element, index) in data" v-if="element" :value="index">{{ element[labelField] }}</option>
      </select>
    </div>
  `
})