Vue.component('banner-validation', {
  methods: {
    validateTicket (state) {
      let me = this
      this.server.get('/api/ticket/validate/' + this.ticket.num_ticket + '/' + (state ? '1' : '0')).then(me.$parent.loadTicket())
      this.$store.commit('setDataUpdated')
    }
  },
  computed: {
    ticket () { return this.$parent.ticket }
  },
  template: /*html*/`
    <div v-if="login">
      <div class="header-message" v-if="ticket.valideur === login && !ticket.dateValidation">
        Ce signalement vous a été soumis pour validation.<br>
        <a href="#" class="button green" @click.prevent="validateTicket(true)">Valider la demande</a>
        <a href="#" class="button red" @click.prevent="validateTicket(false)">Refuser la demande</a>
      </div>
      <div class="header-message text-message type-error" v-if="ticket.login === login && ticket.valideur && !ticket.valide && ticket.dateValidation">
        <table>
          <tr>
            <td><img src="/assets/images/cross.svg"></td>
            <td>Votre demande a été refusée par <b> {{ ticket.valideurFullName }}</b> le <b>{{ formatDateComplete(ticket.dateValidation, true) }}</td>
          </tr>
        </table>
      </div>
      <div class="header-message text-message type-warning" v-if="ticket.login === login && ticket.valideur && !ticket.dateValidation">
        <table>
          <tr>
            <td><img src="/assets/images/warning.svg"></td>
            <td>Votre demande est en attente de validation par <b> {{ ticket.valideurFullName }}</b></td>
          </tr>
        </table>
      </div>
      <div class="header-message text-message" v-if="ticket.valideur === login && ticket.valide && ticket.dateValidation">
        <table>
          <tr>
            <td><img src="/assets/images/information.svg"></td>
            <td>Vous avez validé ce ticket le <b> {{ formatDateComplete(ticket.dateValidation, true) }}</b></td>
          </tr>
        </table>
      </div>
      <div class="header-message text-message type-error" v-if="ticket.valideur === login && !ticket.valide && ticket.dateValidation">
        <table>
          <tr>
            <td><img src="/assets/images/cross.svg"></td>
            <td>Vous avez refusé ce ticket le <b> {{ formatDateComplete(ticket.dateValidation, true) }}</b></td>
          </tr>
        </table>
      </div>
    </div>
  `
})