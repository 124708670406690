Vue.component('cell-bat', {
  props: ['header', 'ticket', 'titre'],
  data() { return {
    pp: this.$parent.$parent
  }},
  methods: {
    htmlLieu (ticket) {
      if (ticket.batiment) return ticket.batiment
      return 'Non renseigné'
    }
  },
  template: /* html */`
    <component :is="header ? 'th' : 'td'">
      <a v-if="header" href="#" @click.prevent="pp.sortBy('lieu')">
        <span v-show="pp.queryParams.orderBy === 'lieu' && pp.queryParams.orderDesc">&#9650;</span>
        <span v-show="pp.queryParams.orderBy === 'lieu' && !pp.queryParams.orderDesc">&#9660;</span>
        {{  titre || 'Bâtiment' }}
      </a>
      <span v-else="">
        <span v-html="htmlLieu(ticket)"></span>
      </span>
    </component>
  `
})