Vue.component('filter-pec-commune', {
  props: [],
  data() { return {
    field: 'pecCommune',
    label:'PRISE EN CHARGE', 
    showOnMobile: true, 
    values: [
      {data: 0, label: 'CCBP'},
      {data: 1, label: 'COMMUNE'}
    ] 
  }},
  methods: {
  },
  template: `
    <home-filter :field="field" :label="label" :showOnMobile="showOnMobile" :values="values" ></home-filter>
  `
})