Vue.component('in-select-buttons', {
  props: {
    value: null,
    labelField: { default: 'label' },
    data: Array,
    dataField: { default: 'data' }, 
    readOnly: Boolean
  },
  data: function () { return {
    selectedElement: null
  }},
  methods: {
    handleInput: function (element) {
      this.updateValue()
      this.$emit('input', element ? element[this.dataField] : null)
    },
    updateValue: function () {
      if (this.data) {
        for (let i = 0; i < this.data.length; i += 1) {
          if (this.data[i][this.dataField] === this.value) {
            this.selectedElement = this.data[i]
          }
        }
      }
    }
  },
  watch: {
    value: function () {
      this.updateValue()
    },
    data: function() {
      this.updateValue()
    }
  },
  mounted: function () {

    this.updateValue()
  },
  template: /*html*/`
    <div class="in-select-buttons" :class="[readOnly && 'read-only']">
      <a v-for="element in data" 
        v-show="!readOnly"
        href="#" 
        @click.prevent="handleInput(element)"
        :style="{backgroundColor: element.color}"
        :class="[selectedElement && selectedElement[dataField] === element[dataField] && 'selected']"
      >
        {{ element[labelField] }} 
      </a>
      <span v-for="element in data" v-show="selectedElement && readOnly && selectedElement[dataField] === element[dataField]" :style="{color: element.color}">
        {{ element[labelField] }} 
      </span>
      <span v-show="readOnly && !selectedElement">N/D</span>
    </div>
  `
})