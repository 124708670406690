

Vue.component('mod-details-1', {
  props: ['params'],
  data () { return {
    readOnly: true
  }},
  methods: {
    save () { this.$parent.updateTicket(['num_categorie', 'num_sousCategorie', 'latitude', 'longitude', 'num_batiment', 'description']) },
    reload () {
      this.$parent.loadTicket()
      this.readOnly = true
    },
    updateAdress () {
      this.ticket.latitude = this.selectedBatiment.latitude
      this.ticket.longitude = this.selectedBatiment.longitude
    }
  },
  computed: {
    ticket () { return this.$parent.ticket },
    runningCheck () {return this.$parent.runningCheck },
    newTicket () { return this.$parent.newTicket },
    selectedBatiment: function () { return this.ticket.num_batiment ? this.$store.state.indexedConstants.batiments[this.ticket.num_batiment] : null },
    batiments () {
      if (this.params.toutBatiment) return this.$store.state.constants.batiments
      return this.$store.state.constants.batiments.filter((batiment) => {
        return batiment.demandeurs.indexOf(this.$store.state.userInfos.login) !== -1 || batiment.valideurs.indexOf(this.$store.state.userInfos.login) !== -1
      }).sort((a,b) => { return a.nom < b.nom ? -1 : (a.nom > b.nom ? 1 : 0) })
    }
  },
  template: /*html*/`
    <div class="module">
    <h2>
        Sujet de la demande
        <a href="#" v-if="params.readWrite && !newTicket" class="button green" v-show="readOnly" @click.prevent="readOnly = false">CORRIGER</a>
        <a href="#" v-if="params.readWrite && !newTicket" class="button blue" v-show="!readOnly" @click.prevent="save">ENREGISTRER</a>
        <a href="#" v-if="params.readWrite && !newTicket" class="button red" v-show="!readOnly" @click.prevent="reload">ANNULER</a>
      </h2>
      <table>
        <tr v-if="!newTicket">
          <td class="label">ID unique :</td>
          <td class="field">
            <in-text v-model="ticket.uniqueId" :readOnly="true"></in-text>
          </td>
        </tr>
        <tr v-if="!newTicket">
          <td class="label">Domaine :</td>
          <td class="field">
            <in-select 
              :data="$store.state.constants.surCategories"
              :labelField="'nom'" 
              v-model="ticket.num_surCategorie" 
              dataField="num_surCategorie"
              :readOnly="true"
            ></in-select>
          </td>
        </tr>
        <tr v-if="params.batiment">
          <td class="label">Bâtiment :</td>
          <td class="field">
            <in-select 
              :data="batiments" 
              :labelField="'nom'" 
              v-model="ticket.num_batiment" 
              emptyLabel="Choisir un bâtiment"
              @input="updateAdress"
              dataField="num_batiment"
              :readOnly="readOnly && !newTicket"
            ></in-select>
          </td>
        </tr>
        <tr>
          <td class="label"><span v-if="newTicket" class="txt-red txt-bold">*</span> Catégorie :</td>
          <td class="field">
          <in-select 
            v-show="catParSurCat(ticket.num_surCategorie).length > 0" 
            :data="catParSurCat(ticket.num_surCategorie)"
            :labelField="'nom'" 
            v-model="ticket.num_categorie" 
            emptyLabel="Choisir une catégorie" 
            dataField="num_categorie"
            :class="{ 'error' : runningCheck && !ticket.num_categorie }"
            :readOnly="readOnly && !newTicket"
          ></in-select>
          </td>
        </tr>
        <tr v-show="ticket.num_categorie && sousCatParCat(ticket.num_categorie).length > 0">
          <td class="label"><span v-if="newTicket" class="txt-red txt-bold">*</span> Sous-catégorie :</td>
          <td class="field">
            <in-select 
              :data="sousCatParCat(ticket.num_categorie)" 
              :labelField="'nom'" 
              v-model="ticket.num_sousCategorie" 
              emptyLabel="Choisir une sous-catégorie" 
              dataField="num_sousCategorie"
              :class="{ 'error' : runningCheck && (sousCatParCat(ticket.num_categorie).length > 0 && !ticket.num_sousCategorie) }"
              :readOnly="readOnly && !newTicket"
            ></in-select>
          </td>
        </tr>
        <tr>
          <td class="label"><span v-if="newTicket" class="txt-red txt-bold">*</span> Description :</td>
          <td class="field">
          <in-long-text 
              v-model="ticket.description" 
              :emptyLabel="params.emptyLabel || '-'" 
              :class="{ 'error' : runningCheck && (!ticket.description || ticket.description.length < 5) }"
              :readOnly="!newTicket"
            ></in-long-text>
          </td>
        </tr>
        <tr v-show="!newTicket && ticket.files.photo && ticket.files.photo.length > 0">
          <td class="label">Photos :</td>
          <td class="field photos">
            <in-fichier v-model="ticket.files.photo" :readOnly="true"></in-fichier>
          </td>
        </tr>
      </table>
    </div>
  `
})