Vue.component('mod-dom-pdl-bailleur', {
  props: ['params', 'newTicket'],
  data () { return {
    readOnly: true
  }},
  methods: {
    save () {  this.$parent.updateTicket(['prenomNom', 'email', 'telephone']) },
    reload () {
      this.$parent.loadTicket()
      this.readOnly = true
    }
  },
  computed: {
    ticket () { return this.$parent.ticket },
    runningCheck () {return this.$parent.runningCheck },
    newTicket () { return this.$parent.newTicket },
  },
  template: /*html*/`
    <div class="module">
      <h2>
        Bailleur
        <a href="#" v-if="params.readWrite && !newTicket" class="button green" v-show="readOnly" @click.prevent="readOnly = false">CORRIGER</a>
        <a href="#" v-if="params.readWrite && !newTicket" class="button blue" v-show="!readOnly" @click.prevent="save">ENREGISTRER</a>
        <a href="#" v-if="params.readWrite && !newTicket" class="button red" v-show="!readOnly" @click.prevent="reload">ANNULER</a>
      </h2>
      <table>
        <tr>
          <td class="label">Prénom :</td>
          <td class="field">
            <in-text v-model="ticket.pdl_prenom_bailleur" small="1" emptyValue="(non renseigné)" :readOnly="(!newTicket && readOnly)"></in-text>
          </td>
        </tr>
        <tr>
          <td class="label">Nom :</td>
          <td class="field">
            <in-text v-model="ticket.pdl_nom_bailleur" small="1" emptyValue="(non renseigné)" :readOnly="(!newTicket && readOnly)"></in-text>
          </td>
        <tr>
          <td class="label">Adresse :</td>
          <td class="field">
            <in-text v-model="ticket.pdl_adresse_bailleur" small="1" emptyValue="(non renseigné)" :readOnly="(!newTicket && readOnly)"></in-text>
          </td>
        </tr>
      </table>
    </div>
  `
})