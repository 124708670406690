/* MODULE ECHANGES
 - params.texteBouton : texte afficher sur le bouton ('Envoyer par défaut')
 - params.isService : à true s'il sagit du service
 */
Vue.component('mod-echanges-1', {
  props: ['params'],
  data () { return {
    newEchange:   ""
  }},
  methods: {
    save () { this.$emit('save') },
    reload () {
      this.$emit('reload')
      this.readOnly = true
    },
    readEchange (echange) {
      let me = this
      
      this.server.get('/api/ticket/echange/read/' + echange.num_echange).then(
        () => {
          me.$store.commit('setDataUpdated')
        }
      )
    },
    addEchange () {
      let me = this
      this.server.post('/api/ticket/echange/save', {
        num_ticket: this.ticket.num_ticket,
        login:      this.login,
        texte:      this.newEchange
      }).then((data) => {
        me.echanges.unshift(data)
        me.newEchange = ""
      })
    },
    showMail (echange) {
      this.$store.commit('setModal', {
        visible: true,
        message: '<div style="font-size:13px">à <b>' + echange.nomComplet + '</b> le <b>' + this.formatDateComplete(echange.date, true) + '</b><br><br>' + this.addBr(echange.texte).replace(/<br>/g, '<br><br>') + '</div>',
        title: '<span style="font-size:15px">' + echange.login + '</span>',
        type: 'info'
      })
    },
    formatMailSubject (subject) {
      return subject.replace(/\[[^\]]+\]/g, '')
    }
  },
  computed: {
    ticket () { return this.$parent.ticket },
    echanges () { return this.$parent.echanges }
  },
  template: /*html*/`
    <div class="module">
    <!-- {{ params }} -->
      <h2>{{ params.isService ? 'Échanges avec le demandeur' : 'Échanges' }}</h2>
      <table>
        <tr v-if="params.fichier">
          <td class="label" v-show="params.readWrite">Fichiers joints :</td>
          <td class="field">
            <in-fichier v-model="ticket.files.echange" :readOnly="!params.readWrite" :autosave="{num_ticket: ticket.num_ticket, champ: 'echange'}" class="small" :disableSuppress="true"></in-fichier>
          </td>
        </tr>
      </table>
      <div v-show="params.readWrite">
        <in-long-text v-model="newEchange"  :readOnly="false"></in-long-text>
        <a href="#" class="button blue" :class="[ newEchange.length < 3 && 'disabled' ]" @click.prevent="addEchange">
          {{ params.texteBouton || 'Envoyer' }}
        </a>
      </div>
      <div>
        <div v-for="echange in echanges">
          <div class="commentaire" :class="[!echange.service && 'blue', echange.service && 'orange']" v-if="echange.num_echange">
            <span>{{ formatDate(echange.date, true) }}
              <span v-if="echange.service && !params.anonyme"> - {{ echange.nomComplet }}</span>
              <span v-else-if="echange.service"> - {{ ticket.pecCommune ? ('COMMUNE DE ' + $store.state.indexedConstants.communes[ticket.num_commune].nom) : 'BASSIN DE POMPEY' }}</span>
              <span v-else=""> - {{ echange.nomComplet || ticket.prenomNom }}</span>
            </span>
            <div>{{ echange.texte }}</div>
            <div class="read-infos">
              <!-- S'il s'agit d'un message usager, qu'il n'est pas lu et que l'utilisateur est d'un service on affiche le bouton pour marquer lu -->
              <a href="#" v-if="!echange.service && !echange.lu && params.isService" @click.prevent="readEchange(echange)">MARQUER LU</a>
              <!-- Sinon si le message est non lu on affiche "NON LU" -->
              <span v-else-if="!echange.lu && ((!params.isService && !echange.service) || (params.isService && echange.service))" class="txt-red">NON LU</span>
              <span v-if="echange.lu && (!echange.service || params.isService)">
                Lu {{ !params.anonyme && echange.nomCompletLecteur ?  ('par ' + echange.nomCompletLecteur + ' ') : '' }} le {{ formatDate(echange.lu, true) }}
              </span>
            </div>
          </div>
          <div class="echange-mail" v-else="">
            <a href="#" @click.prevent="showMail(echange)">
              <img src="/assets/images/mail.svg">
              {{ formatDate(echange.date, true) }} - {{ formatMailSubject(echange.login) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
