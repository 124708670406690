Vue.component('in-date', {
  props: ['value', 'emptyLabel', 'readOnly', 'emptyValue', 'time', 'noEraseButton'],
  data: function () { 
    let now         = new Date()
    let yearList    = []
    let currentYear = now.getFullYear()

    for (let i = 0; i < 12; i += 1) {
      yearList.push(currentYear + i)
    }

    return {
      content:      this.value,
      panelVisible: false,
      panelType:    'date',
      coordinates:  {},
      year:         now.getUTCFullYear(),
      month:        now.getUTCMonth(),
      date:         now.getUTCDate(),
      hour:         now.getHours(),
      minute:       now.getMinutes(),
      tmpYear:      now.getUTCFullYear(),
      tmpMonth:     now.getUTCMonth(),
      yearList:     yearList,
      monthList:    [1, 2, 3 ,4 ,5, 6, 7 ,8, 9, 10, 11, 12],
      weekList:     [0, 1, 2, 3, 4, 5, 6]
    }
  },
  methods: {
    handleInput: function () {
      this.$emit('input', this.content)
    },
    isSelected (type, item) {
      switch (type){
      case 'year':
        return item === this.tmpYear
      case 'month':
        return item === this.tmpMonth && this.year === this.tmpYear
      case 'date':
        return this.date === item.value && this.month === this.tmpMonth && item.currentMonth
      }
    },
    selectYear (year) {
      this.tmpYear = year
      this.panelType = 'month'
    },
    selectMonth (month) {
      this.tmpMonth = month
      this.panelType = 'date'
    },
    commitValue () {
      let value = `${this.year}-${('0' + (this.month + 1)).slice(-2)}-${('0' + this.date).slice(-2)}` + (this.time ? (' ' + ('0' + this.hour).slice(-2) + ':' + ('0' + this.minute).slice(-2) + ':00') : '')
      this.$emit('input', value)
    },
    selectDate (date) {
      let me = this
      setTimeout(() => {
        if(date.previousMonth){
          if(me.tmpMonth === 0){
            me.year -= 1
            me.tmpYear -= 1
            me.month = me.tmpMonth = 11
          }else{
            me.month = me.tmpMonth - 1
            me.tmpMonth -= 1
          }
        }else if(date.nextMonth){
          if(me.tmpMonth === 11){
            me.year += 1
            me.tmpYear += 1
            me.month = me.tmpMonth = 0
          }else{
            me.month = me.tmpMonth + 1
            me.tmpMonth += 1
          }
        }

        me.year   = me.tmpYear
        me.month  = me.tmpMonth
        me.date   = date.value

        me.commitValue()
        me.panelVisible = false
        
    }, 0)
    },
    prevMonthPreview () {
      if (this.tmpMonth === 0) { this.tmpYear -= 1 }
      this.tmpMonth = this.tmpMonth === 0 ? 11 : this.tmpMonth - 1
    },
    nextMonthPreview () {
      if (this.tmpMonth === 11) { this.tmpYear += 1 }
      this.tmpMonth = this.tmpMonth === 11 ? 0 : this.tmpMonth + 1
    },
    chYearRange (next) {
      if(next){
        this.yearList = this.yearList.map((i) => i + 12)
      }else{
        this.yearList = this.yearList.map((i) => i - 12)
      }
    },
    chHour (inc) {
      this.hour = inc ? (this.hour + 1) % 24 :(this.hour + 23) % 24
    },
    chMinute (inc) {
      this.minute = Math.round((inc ? (this.minute + 15) % 60 :(this.minute + 45) % 60) / 15) * 15
    },
    chkHour () {
      this.hour = this.hour.replace(/[^0-9]/g, '')
      this.hour = Math.min(this.hour, 23)
      this.hour = Math.max(this.hour, 0)
    },
    erase () {
      let value = null
      this.$emit('input', value)
      this.panelVisible = false
    }
  },
  computed: {
    dateList () {
      let currentMonthLength  = new Date(this.tmpYear, this.tmpMonth + 1, 0).getDate()
      let dateList            = []
      for (let i = 0; i < currentMonthLength; i += 1) {
        dateList.push({
          currentMonth: true,
          value: i + 1
        })
      }
      let startDay = new Date(this.tmpYear, this.tmpMonth, 1).getDay()
      let previousMonthLength = new Date(this.tmpYear, this.tmpMonth, 0).getDate()
      for(let i = 0, len = startDay; i < len; i++){
        dateList = [{previousMonth: true, value: previousMonthLength - i}].concat(dateList)
      }
      for(let i = dateList.length, item = 1; i < 42; i++, item++){
        dateList[dateList.length] = {nextMonth: true, value: item}
      }
      return dateList
    },
    formattedValue () {
      if (!this.content) { return null }
      // return this.year + '-' + ('0' + (parseInt(this.month) + 1)).slice(-2) + '-' + ('0' + this.date).slice(-2) + ' ' + (this.time ? (' ' + ('0' + this.hour).slice(-2) + ':' + ('0' + this.minute).slice(-2)) + ':00' : '')
      return this.formatDateComplete(this.year + '-' + ('0' + (parseInt(this.month) + 1)).slice(-2) + '-' + ('0' + this.date).slice(-2) + (this.time ? (' ' + ('0' + this.hour).slice(-2) + ':' + ('0' + this.minute).slice(-2)): ''), this.time)
      // return ('0' + this.date).slice(-2) + '/' + ('0' + (parseInt(this.month) + 1)).slice(-2) + '/' + this.year + 
      //         (this.time ? (' ' + ('0' + this.hour).slice(-2) + ':' + ('0' + this.minute).slice(-2)) : '')
    }
  },
  watch: {
    value: function () {
      if (this.value) {
        let datas       = this.value.split(' ')
        let dateInfos   = datas[0].split('-')

        this.year       = parseInt(dateInfos[0])
        this.month      = parseInt(dateInfos[1]) - 1
        this.date       = parseInt(dateInfos[2])

        let hourInfos   = []

        if (this.time) {
          hourInfos   = datas[1].split(':')

          this.hour   = parseInt(hourInfos[0])
          this.minute = parseInt(hourInfos[1])
        }
      }
      this.content = this.value
      
    }
  },
  mounted: function () {
    let me = this
    me.$nextTick(function () {
      // if(me.$el.parentNode.offsetWidth + me.$el.parentNode.offsetLeft - me.$el.offsetLeft <= 300){
      //   me.coordinates = {right: '0', top: '23px'}
      // } else {
        me.coordinates = {left: '0', top: '23px'}
      // }
    })
  },
  filters: {
    week: (item) => ({0: 'Di', 1:'Lu', 2: 'Ma', 3: 'Me', 4: 'Je', 5: 'Ve', 6: 'Sa'}[item]),
    month: (item) => ({1: 'Jan', 2: 'Fév', 3: 'Mar', 4: 'Avr', 5: 'Mai', 6: 'Juin', 7: 'Juil', 8: 'Août', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Déc'}[item])
  },
  template: /*html*/`
    <div class="in-date" :class="[readOnly && 'read-only']">
      <span class="value" v-html="formatDateComplete(value) || ('<em>' + emptyValue + '</em>')" @click="panelVisible = !panelVisible; commitValue()"></span>
      <a class="erase" v-show="content && !readOnly && !noEraseButton" href="#" @click.prevent="erase">X</a>
      <!-- <input v-else="" class="main-input" type="text" v-model="content" @input="handleInput" :placeholder="emptyLabel" onClick="this.setSelectionRange(0, this.value.length)" @click="panelVisible = true"> -->

      <div class="date-panel" v-show="panelVisible && !readOnly" :style="coordinates">

        <div class="panel-header" v-show="panelType !== 'year'">
          <div class="arrow-left" @click="prevMonthPreview()">&lt;</div>
          <div class="year-month-box">
            <div class="year-box" @click="panelType = 'year'" v-text="tmpYear"></div>
            <div class="month-box" @click="panelType = 'month'">{{tmpMonth + 1 | month()}}</div>
          </div>
          <div class="arrow-right" @click="nextMonthPreview()">&gt;</div>
        </div>

        <div class="panel-header" v-show="panelType === 'year'">
          <div class="arrow-left" @click="chYearRange(0)">&lt;</div>
          <div class="year-range">
            <span v-text="yearList[0]"></span> - <span v-text="yearList[yearList.length - 1]"></span>
          </div>
          <div class="arrow-right" @click="chYearRange(1)">&gt;</div>
        </div>

        <div class="type-year" v-show="panelType === 'year'">
          <ul class="year-list">
            <li v-for="item in yearList"
              v-text="item"
              :key="item"
              :class="{selected: isSelected('year', item)}"
              @click="selectYear(item)"
              >
            </li>
          </ul>
        </div>
        <div class="type-month" v-show="panelType === 'month'">
          <ul class="month-list">
            <li v-for="(item, index) in monthList"
              :key="item"
              :class="{selected: isSelected('month', index)}"
              @click="selectMonth(index)"
            >
              {{item | month()}}
            </li>
          </ul>
        </div>
        <div class="type-date" v-show="panelType === 'date'">
          <ul class="weeks">
            <li v-for="item in weekList" :key="item">{{item | week()}}</li>
          </ul>
          <ul class="date-list">
            <li v-for="(item, index) in dateList"
              :key="index"
              :class="{preMonth: item.previousMonth, nextMonth: item.nextMonth, firstItem: (index % 7) === 0}"
              @click="selectDate(item)">
            <div class="message" :class="{selected: isSelected('date', item)}">
            <div class="bg"></div><span v-text="item.value"></span>
            </div>
          </li>
          </ul>
        </div>
        <div class="type-time" v-if="time">
          <span class="title">Heures</span>
          <span class="title">Minutes</span>
          <a href="#" @click.prevent="chHour()"><span>-</span></a>
          <input type="text" v-model="hour" maxlength="2" @input="chkHour">
          <a href="#" @click.prevent="chHour(true)"><span>+</span></a>
          <span>:</span>
          <a href="#" @click.prevent="chMinute()"><span>-</span></a>
          <input type="text" v-model="minute" maxlength="2">
          <a href="#" @click.prevent="chMinute(true)"><span>+</span></a>
        </div>
      </div>
    </div>
  `
})