Vue.component('modal-message', {
  data () { return {
    modalDatas: {}
  }},
  methods: {
    hideModal: function () { this.$store.commit('hideModal') },
    execCallback: function () { 
      this.$store.state.modal.okCallback(this.modalDatas)
      this.$store.commit('hideModal')
    },
    closeIfNeeded () {
      if (this.modal.type === 'info') {
        this.hideModal()
      }
    }
  },
  computed: {
    modal: function () { return this.$store.state.modal },
  },
  template: /*html*/`
    <div :class="['modal', modal.type === 'image' && 'image']" v-show="modal.visible" @click="closeIfNeeded">
      <!--<img src="images/loading.gif"><br>-->
      <div class="text" v-show="modal.type === 'info' || modal.type === 'validation' || modal.type === 'component'">
        <div class="title" v-html="modal.title"></div>
        <div class="message" v-html="modal.message"></div>
        <div class="component">
          <component v-show="modal.type === 'component'" v-bind:is="modal.component" v-model="modalDatas.text"></component>
        </div>
        <div class="buttons">
          <a v-show="modal.type === 'info'" href="#" class="button w-100 blue" @click.prevent="hideModal"><i aria-hidden="true" class="fa fa-check"></i> OK</a>
          <a v-show="modal.type === 'validation' || modal.type === 'component'" href="#" class="button w-100 green" @click.prevent="execCallback"><i aria-hidden="true" class="fa fa-check"></i> Valider</a>
          <a v-show="modal.type === 'validation' || modal.type === 'component'" href="#" class="button w-100 red" @click.prevent="hideModal"><i aria-hidden="true" class="fa fa-times"></i> Annuler</a>
        </div>
      </div>
      <div class="image" v-show="modal.type === 'image'">
        <img :src="modal.imageSrc">
      </div>
        
    </div>
  `
})