Vue.component('bandeau-haut', {
  data: function () { return {
    burgerOpen : false,
    delegationChoisie: null
  }},
  methods: {
    loginas: function () {
      this.server.get('/api/user/loginas/' + this.delegationChoisie).then((userInfos) => {
        if (userInfos.connected) { window.location.replace('/') }
        else { this.error = true }
      })
    },
    reloginas: function () {
      this.server.get('/api/user/loginas/' + this.$store.state.userInfos.loginfrom.login).then((userInfos) => {
        if (userInfos.connected) { window.location.replace('/') }
        else { this.error = true }
      })
    }
  },
  computed: {
    ticketPrec: function () {
      let currentIndex = this.$store.state.listeTickets.indexOf(this.$route.params.identifiant)
      if (currentIndex !== -1 && currentIndex !== 0) {
        return this.$store.state.listeTickets[currentIndex - 1]
      }
      return null
    },
    ticketSuiv: function () {
      let currentIndex = this.$store.state.listeTickets.indexOf(this.$route.params.identifiant)
      if (currentIndex !== -1 && currentIndex !== this.$store.state.listeTickets.length - 1) {
        return this.$store.state.listeTickets[currentIndex + 1]
      }
      return null
    }
  },
  template: /*html*/`
    <div class="bandeau-haut">
      <a href="#" class="burger-menu" @click.prevent="burgerOpen = !burgerOpen" :class="[burgerOpen && 'open']">
        <i class="icon-burger-menu"></i>
        <ul>
          <router-link to="/accueil" class="router-link" tag="li" @click="burgerOpen = false"><a>Liste des tickets</a></router-link>
          <router-link to="/usager" class="router-link" tag="li" @click="burgerOpen = false" v-if="$store.state.userInfos.num_surCategories.length > 0"><a>Nouvelle demande</a></router-link>
          <li><a href="/logout">Déconnexion</a></li>
        </ul>
      </a>
      
      <img :src="$store.state.constants.env !== 'test' ? '/assets/images/logoCCBP.svg' : '/assets/images/logoCCBP_rouge.svg'">
      <router-link to="/accueil" class="router-link">Liste des tickets</router-link>
      <router-link to="/usager" class="router-link" v-if="$store.state.userInfos.num_surCategories.length > 0">Nouvelle demande</router-link>
      <router-link to="/delegation" class="router-link" v-if="$store.state.userInfos.valideur || $store.state.userInfos.valideurBat" :class="[store.state.userInfos.delegue && 'rouge']">Délégation</router-link>
      <router-link v-show="$route.name == 'pageFormTicket' && ticketPrec" :to="'/ticket/' + ticketPrec" class="router-link arrow">&lt;</router-link>
      <router-link v-show="$route.name == 'pageFormTicket'" :to="'/ticket/' + $route.params.identifiant" class="router-link arrow">{{ $route.params.identifiant }}</router-link>
      <router-link v-show="$route.name == 'pageFormTicket' && ticketSuiv" :to="'/ticket/' + ticketSuiv" class="router-link arrow">&gt;</router-link>
      <router-link to="/stats" class="router-link" v-if="$store.state.userInfos.statsAccess">Statistiques</router-link>
      <router-link to="/config" class="router-link" v-if="$store.state.userInfos.configAccess"><a>Configuration</a></router-link>
      <router-link to="/configdisplays" class="router-link" v-if="$store.state.userInfos.configAccess"><a>Configuration modules</a></router-link>
      <span class="user-infos" v-if="!isMobile">
        {{ $store.state.userInfos.prenomNom || '' }}
        <in-select
          v-if="store.state.userInfos.delegations" 
          :data="$store.state.userInfos.delegations"
          :labelField="'nomComplet'" 
          v-model="delegationChoisie" 
          emptyLabel="Se connecter en tant que" 
          dataField="login"
          @input="loginas"
        ></in-select>
        <a href="#" @click.prevent="reloginas" v-if="$store.state.userInfos.loginfrom">Se reconnecter en tant que {{ $store.state.userInfos.loginfrom.nomComplet }}</a>
        <a href="/logout">Déconnexion</a>
      </span>
    </div>
  `
})