Vue.component('photos-gallery', {
  props: ['fichiers'],
  data () { return {
    viewer: null
  }},
  mounted () {
    let me = this
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      me.viewer = new Viewer( me.$el, {
          url (image) {
            return image.src.replace('/tb/', '/')
          },
          title: 0,
          navbar: 0,
          transition: true,
          toolbar: { zoomIn: {show: 1, size: 'large' }, 
            zoomOut: {show: 1, size: 'large' }, oneToOne: 0, reset: 0, prev: {show: 1, size: 'large' }, play: 0, next: {show: 1, size: 'large' }, rotateLeft: {show: 1, size: 'large' }, rotateRight: {show: 1, size: 'large' }, flipHorizontal: 0, flipVertical: 0 }
        }
      )
    }, 200);
  },
  beforeDestroy () {
    this.viewer.destroy()
  },
  template: `
    <ul class="photos-gallery">
      <li v-for="fichier in fichiers" >
        <img :src="'/api/image/tb/' + fichier">
      </a>
    </ul>
  `
})