PAGES['configDisplays'] = Vue.component('page-config-displays', {
  data () { return {
    newDisplayName: '',
    displays: [],
    profil_surCategorie_display: [],
    mods: [],
    movingMod: null,
    dragCopy: false,
    palette: [],
    newMod: {
      num_display: null,
      num_mod: null,
      new: null,
      ticketFilter: null,
      params: null,
      column: null,
      row: null,
      paramsValues: []
    }
  }},
  mounted () {
    this.loadDisplays()
    var newMod
    for (let i = 0; i < this.$store.state.constants.mods.length; i += 1) {
      newMod = JSON.parse(JSON.stringify(this.newMod))
      newMod.num_mod = this.$store.state.constants.mods[i].num_mod
      this.palette.push(newMod)
    }
  },
  beforeMount () {
    for (let i = 0; i < this.$store.state.constants.profils.length; i += 1) {
      this.$set(this.profil_surCategorie_display, this.$store.state.constants.profils[i].num_profil, [])
      for (let j = 0; j < this.$store.state.constants.surCategories.length; j += 1) {
        this.profil_surCategorie_display[this.$store.state.constants.profils[i].num_profil][this.$store.state.constants.surCategories[j].num_surCategorie] = null
      }
    }
    
  },
  methods: {
    modDisplay (mod) { return this.displays[mod.num_display] },
    modColumn (mod) { return this.modDisplay(mod).mods[mod.new][mod.column] },
    modPlace (mod) { return this.modColumn(mod)[mod.row] },
    updatePSD (num_profil, num_surCategorie) {
      this.server.post('/api/config/psd/update', {num_profil, num_surCategorie, num_display: this.profil_surCategorie_display[num_profil][num_surCategorie]})
    },
    changeDisplayVisibility (display) {
      if (display.visible) this.$set(display, 'visible', false)
      else this.$set(display, 'visible', true)
    },
    createDisplay () {
      this.server.post('/api/config/display/create', {nom: this.newDisplayName}).then(() => { document.location.reload() })
    },
    loadDisplays () {
      let me = this
      this.server.get('/api/config/display/list').then((data) => {

        for (let i = 0; i < data.profil_surCategorie_display.length; i += 1) {
          me.$set(me.profil_surCategorie_display[data.profil_surCategorie_display[i].num_profil], data.profil_surCategorie_display[i].num_surCategorie, data.profil_surCategorie_display[i].num_display )
        }

        for (let i = 0; i < data.displays.length; i += 1) {
          me.$set(me.displays, data.displays[i].num_display, data.displays[i])
          me.displays[data.displays[i].num_display].mods =  [[],[]]
        }
        me.mods = data.mods

        for (let i = 0; i < me.mods.length; i += 1) {
          // if (!me.displays[me.mods[i].num_display].mods) me.displays[me.mods[i].num_display].mods = [[],[]]
          if (!me.displays[me.mods[i].num_display].mods[me.mods[i].new][me.mods[i].column]) me.displays[me.mods[i].num_display].mods[me.mods[i].new][me.mods[i].column] = []

          me.modColumn(me.mods[i])[me.mods[i].row] = me.mods[i]
          me.mods[i].paramsValues = this.parseParams(me.mods[i].params)
        }
      })
    },
    saveDisplays () {
      this.server.post('/api/config/display/save', this.mods)
    },
    delMod (mod) {
      this.modColumn(mod).splice(mod.row, 1)
      for (let i = 0; i <  this.modColumn(mod).length; i += 1) {
        if (this.modColumn(mod)[i]) this.modColumn(mod)[i].row = i
      }
      mod.num_display = -1
      this.$forceUpdate()
    },
    updateParamValue (mod) {
      let paramsStrings = []
      for (let key in  mod.paramsValues) if (mod.paramsValues.hasOwnProperty(key)) {
        if (mod.paramsValues[key]) paramsStrings.push(key + '=' + mod.paramsValues[key])
      }
      this.$set(mod, 'params', paramsStrings.join('|'))
    },
    startDrag (e, mod) {
      e.dataTransfer.effectAllowed = 'all'
      this.movingMod = mod
    },
    endDrag () {
      this.movingMod = null
    },
    dragEnter (e, col) {
      let isBanner = (col === 10)
      let movingModIsBanner = this.$store.state.indexedConstants.mods[this.movingMod.num_mod].banner === 1
      if (movingModIsBanner === isBanner) e.target.classList.add('over')
      else e.target.classList.add('over-error')
    },
    dragLeave (e) {
      e.target.classList.remove('over')
      e.target.classList.remove('over-error')
    },
    onDrag (e) {
      this.dragCopy = e.ctrlKey
    },
    // Au drop sur une zone
    onDrop (e, onMod, mod, num_display, isNew, column) {
      let isBanner = ((onMod ? mod.column : column) === 10)
      let movingModIsBanner = this.$store.state.indexedConstants.mods[this.movingMod.num_mod].banner === 1

      e.target.classList.remove('over-error')

      if (movingModIsBanner === isBanner) {
        e.target.classList.remove('over')
        var i, newMod
        // S'il s'agit d'une copie ou que le module vient de la palette on fait une copie du module
        if (this.dragCopy || this.movingMod.num_display === null) {
          newMod = JSON.parse(JSON.stringify(this.movingMod))
          this.mods.push(newMod)
        } else {
        // Sinon on supprime le module de sa liste initiale
          this.displays[this.movingMod.num_display].mods[this.movingMod.new][this.movingMod.column].splice(this.movingMod.row, 1)

          // Correction des numéros de ligne des modules suivants dans la liste d'origine
          for (i = this.movingMod.row; i < this.displays[this.movingMod.num_display].mods[this.movingMod.new][this.movingMod.column].length; i += 1) {
            this.displays[this.movingMod.num_display].mods[this.movingMod.new][this.movingMod.column][i].row -= 1
          }
          newMod = this.movingMod
        }

        // Si on drop le module au dessus d'un module existant
        if (onMod) {
          // Configuration du nouveau module
          newMod.row = mod.row
          newMod.column = mod.column
          newMod.num_display = mod.num_display
          newMod.new = mod.new
          
          // Correction des numéros de ligne des modules suivants
          for (i = mod.row; i < this.displays[mod.num_display].mods[mod.new][mod.column].length; i += 1) {
            this.displays[mod.num_display].mods[mod.new][mod.column][i].row += 1
          }
          
          // Insertion du nouveau module
          this.displays[mod.num_display].mods[mod.new][mod.column].splice(mod.row - 1, 0, newMod)
          
        } else {
          // Configuration du nouveau module
          if (!this.displays[num_display].mods[isNew][column]) this.displays[num_display].mods[isNew][column] = []
          newMod.row = this.displays[num_display].mods[isNew][column].length
          newMod.column = column
          newMod.num_display = num_display
          newMod.new = isNew

          // Insertion du nouveau module
          this.displays[num_display].mods[isNew][column].push(newMod)
        }
        this.movingMod = null
      }
    }
  },
  template: /*html*/`
    <div class="page-config-profils">
      <div class="info-drag" v-show="movingMod">{{ dragCopy ? 'COPIE' : 'DÉPLACEMENT' }}</div>
      <div class="mod-palette">
        <h2>MODULES</h2>
        <div v-for="mod in palette" v-if="!$store.state.indexedConstants.mods[mod.num_mod].banner" draggable="true" @dragstart="startDrag($event, mod)" @dragend="endDrag()" @drag="onDrag">
          {{ mod.num_mod }} - {{ $store.state.indexedConstants.mods[mod.num_mod].name }}
        </div>
        <h2>BANNIÈRES</h2>
        <div v-for="mod in palette" v-if="$store.state.indexedConstants.mods[mod.num_mod].banner"  draggable="true" @dragstart="startDrag($event, mod)" @dragend="endDrag()" @drag="onDrag">
          {{ mod.num_mod }} - {{ $store.state.indexedConstants.mods[mod.num_mod].name }}
        </div>
      </div>
      <div class="display-palette">
        <h2>DISPLAYS</h2>
        <a v-for="display in displays" v-if="display" href="#" :class="[!display.visible && 'inactive']" @click.prevent="changeDisplayVisibility(display)">
          {{display.num_display}} - {{ display.nom }}
        </a>
      </div>
      <a href="#" @click.prevent="saveDisplays" class="button blue">ENREGISTRER</a><br><br>
      <table>
        <tr class="new-display-form">
          <td><in-text v-model="newDisplayName" emptyLabel="Nom du nouveau display"></in-text></td>
          <td><a href="#" class="button w-100 blue" @click.prevent="createDisplay">Créer</a></td>
        </tr>
        <tbody v-for="display in displays" v-if="display" v-show="display.visible">
          <tr>
            <th :colspan="2" class="categorie">{{ display.nom }}</th>
          </tr>
          <tr>
            <th class="titre-colonne">NOUVEAU TICKET</th>
            <th class="titre-colonne">TICKET EXISTANT</th>
          </tr>
          <tr>
            <td v-for="isNew in [1,0]">
              <table class="layout">
                <tr>
                  <td class="contenant-layout">
                    <div v-for="i in [10, 0, 1, 2, 3]">
                      <div v-for="mod in display.mods[isNew][i]">
                        <div class="drop-area" :class="[i === 10 && 'full-width']"  @dragenter.prevent="dragEnter($event, i)" @dragleave.prevent="dragLeave" @dragover.prevent @drop="onDrop($event, true, mod)"><hr></div>
                        <div v-if="mod" class="mod" :class="[i === 10 && 'full-width']" draggable="true" @dragstart="startDrag($event, mod)" @dragend="endDrag()" @drag="onDrag">
                          <div class="entete">
                            <a href="#" @click.prevent="$event.target.parentNode.parentNode.classList.toggle('etendu')" draggable="false">{{ $store.state.indexedConstants.mods[mod.num_mod].name }}</a>
                            <span v-if="mod.ticketFilter" class="tag">F</span>
                            <span v-if="mod.params" class="tag">P</span>
                          </div>
                          <table class="contenu">
                            <tr><th class="txt-left">Filtre : </th></tr>
                            <tr><td colspan="2"> <in-long-text v-model="mod.ticketFilter" small="1"></in-long-text> </td></tr>
                            <tbody v-for="param in parseModParams($store.state.indexedConstants.mods[mod.num_mod].params)">
                              <tr v-if="param[1] === 's'">
                                <th>{{ param[0] }} :</th>
                              </tr>
                              <tr v-if="param[1] === 's'">
                                <td colspan="2">
                                  <in-text small="1" v-model="mod.paramsValues[param[0]]" @input="updateParamValue(mod, param[0], $event)"></in-text>
                                </td>
                              </tr>
                              <tr v-if="param[1] !== 's'">
                                <th>{{ param[0] }} :</th>
                                <td>
                                  <in-bool small="1" v-model="mod.paramsValues[param[0]]" @input="updateParamValue(mod, param[0], $event)"></in-bool>
                                </td>
                              </tr>
                            </tbody>
                            <tr><td colspan="2" class="txt-center"><a href="#" @click.prevent="delMod(mod)" class="small button red">SUPPRIMER</a></td></tr>
                          </table>
                        </div>
                        <div v-else="">[vide]</div>
                      </div>
                      <div 
                        class="drop-area" :class="[i === 10 && 'full-width']"
                        @dragenter.prevent="dragEnter($event, i)" 
                        @dragleave.prevent="dragLeave" 
                        @dragover.prevent 
                        @drop="onDrop($event, false, null, display.num_display, isNew, i)">
                        <hr>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <tr>
          <th>Profil</th>
          <th v-for="surCategorie in $store.state.constants.surCategories">{{ surCategorie.nom }}</th>
        </tr>
        <tr v-for="profil in $store.state.constants.profils" v-if="!profil.meta">
          <td>{{ profil.nom }}</td>
          <td v-for="surCategorie in $store.state.constants.surCategories">
            <in-select 
              :data="$store.state.constants.displays"
              :labelField="'nom'" 
              v-model="profil_surCategorie_display[profil.num_profil][surCategorie.num_surCategorie]" 
              emptyLabel="Aucun display" 
              dataField="num_display"
              :readOnly="false"
              @input="updatePSD(profil.num_profil, surCategorie.num_surCategorie)"
            ></in-select>
          </td>
        </tr>
      </table>
    </div>
  `
})