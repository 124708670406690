Vue.component('in-multi-check', {
  props: ['value', 'labelField', 'data', 'dataField', 'readOnly'],
  data () { return {
    selectedValues: []
  }},
  methods: {
    updateSelectedValues () {
      this.selectedValues = []
      let allValues = this.value.toString().split(',')
      for (let j = 0; j < this.data.length; j += 1) {
        this.selectedValues[j] = false
      }

      for (let i = 0; i < allValues.length; i += 1) {
        for (let j = 0; j < this.data.length; j += 1) {
          if (this.data[j][this.dataField].toString() === allValues[i]) {
            this.selectedValues[j] = true
          }
        }
      }
    },
    setValue () {
      let selectedDatas = []
      for (let i = 0; i < this.selectedValues.length; i += 1) {
        if (this.selectedValues[i]) {
          selectedDatas.push(this.data[i][this.dataField])
        }
      }
      this.value = selectedDatas.join(',')
    },
    selectAll () {
      if (this.value.split(',').length > this.data.length / 2) {
        for (let j = 0; j < this.data.length; j += 1) { this.selectedValues[j] = false }
      } else {
        for (let j = 0; j < this.data.length; j += 1) { this.selectedValues[j] = true }
      }
      this.setValue()
    },
    switchValue (index) {
      this.selectedValues[index] = !this.selectedValues[index]
      this.setValue()
    }
  },
  watch: {
    value () {
      this.updateSelectedValues()
      this.$emit('input', this.value)
    },
    data () {
      this.updateSelectedValues()
    }
  },
  mounted () {
    this.updateSelectedValues()
  },
  template: `
    <div class="in-multi-check">
      <!-- {{ value }} -->
      <div v-show="!readOnly">
        <a href="#" v-show="!readOnly" @click.prevent="selectAll"><b>Tous</b>/<b>Aucun</b></a>
        <div class="in-bool" v-for="(element, index) in data">
          <img src="/assets/images/closed.svg" v-show="selectedValues[index]" @click.prevent="switchValue(index)">
          <img src="/assets/images/cross.svg" v-show="!selectedValues[index]" @click.prevent="switchValue(index)">
          <a href="#" @click.prevent="switchValue(index)">{{ element[labelField] }}</a>
        </div>
        <!-- <in-bool v-for="(element, index) in data" v-model="selectedValues[index]" @input="setValue()" :label="element[labelField]"></in-bool> -->
        <!-- <in-bool v-for="(element, index) in data" v-model="selectedValues[index]" :label="element[labelField]"></in-bool> -->
      </div>
      <div v-show="readOnly">
        <span v-for="(element, index) in selectedValues" v-show="element">
          {{ data[index][labelField] }}<span>, </span>
        </span>
      </div>
    </div>
  `
})