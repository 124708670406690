Vue.component('el-pagination', {
  data: function () { return { }},
  props: ['start', 'total', 'nPerPage'],
  methods: {
    goToPage (n) {
      this.$emit('startChange', n * this.nPerPage)
    }
  },
  computed: {
    nPages () { return Math.ceil(this.total / this.nPerPage) },
    pageCourante () { return Math.floor(this.start / this.nPerPage) },
    indexPages () {
      let index = []
      if (this.nPages < 15) {
        for (let i = 0; i < this.nPages; i += 1) {
          index.push(i);
        }
      } else if (this.pageCourante < 10) {
        for (let i = 0; i < Math.min(this.nPages, this.pageCourante + 5); i += 1) {
          index.push(i)
        }
        index.push(-1)
        for (let i = this.nPages - 5; i < this.nPages; i += 1) {
          index.push(i)
        }
      } else if (this.pageCourante > this.nPages - 9) {
        for (let i = 0; i < 5; i += 1) {
          index.push(i)
        }
        index.push(-1)
        for (let i = this.pageCourante - 5; i < this.nPages; i += 1) {
          index.push(i)
        }
      } else {
        for (let i = 0; i < 5; i += 1) {
          index.push(i)
        }
        index.push(-1)
        for (let i = this.pageCourante - 2; i < this.pageCourante + 3; i += 1) {
          index.push(i)
        }
        index.push(-1)
        for (let i = this.nPages - 5; i < this.nPages; i += 1) {
          index.push(i)
        }
      }
      return index;
    },
    lastElementIndex () {
      return Math.min(this.start + this.nPerPage, this.total)
    }
    // page () { return  }
  },
  template: `
  <div class="el-pagination">
    <div class="header">
      Tickets <b>{{ start + 1 }}</b> à <b>{{ lastElementIndex }}</b> sur <b>{{ total }}</b>
    </div>
    <div>
      <a href="#" @click.prevent="goToPage(0)" :class="[pageCourante === 0 && 'disabled']">&lt;&lt;</a><a
        v-for="page in indexPages"
        href="#"
        @click.prevent="goToPage(page)"
        :class="[page === -1 && 'disabled', page === pageCourante && 'active']"
      >{{ page === -1 ? '...' : (page + 1) }}</a><a href="#" @click.prevent="goToPage(nPages -1)" :class="[pageCourante === nPages - 1 && 'disabled']">&gt;&gt;</a>
    </div>
    
    <!-- {{ nPages }} {{ start }} {{ total }} {{ nPerPage }} {{ pageCourante }} -->
  </div>
  `
})