Vue.component('cell-date-limite', {
  props: ['header', 'ticket'],
  data() { return {
    pp: this.$parent.$parent
  }},
  methods: {
    
    classJoursRestant (joursRestants, status) {
      if (joursRestants !== null && status !== 2) {
        if (joursRestants <= 0) return 'blink-red';
        if (joursRestants < 2) return 'red';
        if (joursRestants < 5) return 'yellow';
        return 'green';
      }
      return '';
    }
  },
  computed: {
    joursRestants () {
      if (!this.ticket.dateLimite) {
        return null
      }
      return Math.floor(((new Date(this.ticket.dateLimite)).getTime() - (new Date()).getTime() + 1000 * 3600 * 24) / (1000 * 3600 * 24))
    }
  },
  template: `
    <component :is="header ? 'th' : 'td'" class="date-limite"  :class="ticket ? classJoursRestant(joursRestants, ticket.status) : ''">
      <a v-if="header" href="#" @click.prevent="pp.sortBy('dateLimite')">
        <span v-show="pp.queryParams.orderBy === 'dateLimite' && pp.queryParams.orderDesc">&#9650;</span>
        <span v-show="pp.queryParams.orderBy === 'dateLimite' && !pp.queryParams.orderDesc">&#9660;</span>
        Date limite
      </a>
      <span v-else="">
        <span>{{formatDate(ticket.dateLimite)  }}</span>
        <span>{{ joursRestants !== null && ticket.status !== 2 ? joursRestants + 'j' : '' }}</span>
      </span>
    </component>
  `
})