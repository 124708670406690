/* MODULE DEMANDEUR
 - params.hideType : à true pour masquer le type de demandeur
 - params.readWrite : à true s'il est possible de modifier les infos relatives au demandeur
 - parame.titre : titre affiché
 */
Vue.component('mod-demandeur-1', {
  props: ['params', 'newTicket'],
  data () { return {
    readOnly: true
  }},
  methods: {
    save () {  this.$parent.updateTicket(['prenomNom', 'email', 'telephone']) },
    reload () {
      this.$parent.loadTicket()
      this.readOnly = true
    }
  },
  computed: {
    ticket () { return this.$parent.ticket },
    runningCheck () {return this.$parent.runningCheck },
    newTicket () { return this.$parent.newTicket },
  },
  template: /*html*/`
    <div class="module">
      <h2>
        {{ params.titre || 'Coordonnées' }}
        <a href="#" v-if="params.readWrite && !newTicket" class="button green" v-show="readOnly" @click.prevent="readOnly = false">CORRIGER</a>
        <a href="#" v-if="params.readWrite && !newTicket" class="button blue" v-show="!readOnly" @click.prevent="save">ENREGISTRER</a>
        <a href="#" v-if="params.readWrite && !newTicket" class="button red" v-show="!readOnly" @click.prevent="reload">ANNULER</a>
      </h2>
      <table>
        <tr v-if="!params.hideType && !newTicket">
          <td class="label">Type demandeur :</td>
          <td class="field"><div class="read-only">
            <span class="value">{{ ($parent.demandeur && $parent.demandeur.num_profil) ? $store.state.indexedConstants.profils[$parent.demandeur.num_profil].typeDemandeur : 'Usager' }}</span>
          </td>
        </tr>
        <tr>
          <td class="label">Prénom et nom :</td>
          <td class="field">
            <in-text v-model="ticket.prenomNom" emptyLabel="Prénom et nom" :emptyValue="'<em>(non renseigné)</em>'" :readOnly="(!newTicket && readOnly) || (ticket.login && !params.readWrite)" small="1"></in-text>
          </td>
        </tr>
        <tr>
          <td class="label">Adresse mail :</td>
          <td class="field">
            <in-text v-model="ticket.email" emptyLabel="Adresse de messagerie" :emptyValue="'<em>(non renseigné)</em>'" :readOnly="(!newTicket && readOnly) || (ticket.login && !params.readWrite)" small="1"></in-text>
          </td>
        </tr>
        <tr>
          <td class="label">N° de téléphone  :</td>
          <td class="field">
            <in-text v-model="ticket.telephone" emptyLabel="Numéro de téléphone" :emptyValue="'<em>(non renseigné)</em>'" :readOnly="(!newTicket && readOnly) || (ticket.login && !params.readWrite)" small="1"></in-text>
          </td>
        </tr>
      </table>
    </div>
  `
})