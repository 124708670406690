Vue.component('file-tb', {
  data () { return {
    infosFichier: {}
  }},
  props: ['numFichier', 'suppressOption', 'alwaysDownload'],
  watch: {
    numFichier: function() {
      if (this.viewer) { this.viewer.destroy() }
      this.infosFichier.tbReady = false
      this.getFileInfo()
    }
  },
  methods: {
    showPhoto () {
      if (!this.viewer) {
        this.viewer = new Viewer(this.$refs['photo'], {
          title: 0,
          navbar: 0,
          transition: true,
          toolbar: { zoomIn: {show: 1, size: 'large' }, 
            zoomOut: {show: 1, size: 'large' }, oneToOne: 0, reset: 0, prev: {show: 0, size: 'large' }, play: 0, next: {show: 0, size: 'large' }, rotateLeft: {show: 1, size: 'large' }, rotateRight: {show: 1, size: 'large' }, flipHorizontal: 0, flipVertical: 0 }
        })
      }
      this.viewer.show()
    },
    getFileInfo () {
      let me = this
      if (this.numFichier) {
        this.server.get('/api/image/infos/' + this.numFichier).then((data) => {
          me.infosFichier = data
          if (!data.tbReady) {
            this.server.get('/api/image/genTb/' + this.numFichier, false).then(() => {
              me.infosFichier.tbReady = true
            })
          }
        })
      }
    },
    suppress () {
      let me = this
      me.$store.commit('setModal', {
        visible: true,
        message: "Voulez-vous vraiment supprimer ce fichier ?",
        title: 'Suppression du fichier',
        type: 'validation',
        okCallback () {
          me.numFichier = null
          me.infosFichier.tbReady = false
          me.$emit("suppress")
        }
      })
      
    }
  },
  computed: {
  },
  mounted () {
    this.getFileInfo()
  },
  beforeDestroy () {
    if (this.viewer) { this.viewer.destroy() }
  },
  template: /*html*/`
    <div class="file-tb">
      <!-- {{ numFichier }} -->
      <a href="#" @click.prevent="showPhoto" v-if="infosFichier.tbReady">
        <img :src="'/api/image/tb/' + numFichier" class="thumbnail">
        <div style="display:none">
          <img :src="'/api/image/' + numFichier" ref="photo">
        </div>
      </a>
      <img v-else="" class="blinking-alert loading" src="/assets/images/loading.svg"><br>
      <a class="download" :href="'/api/image/doc/' + numFichier" v-show="alwaysDownload || !infosFichier.isImage"><img src="/assets/images/fleche_bas_b.svg"></a>
      <a class="suppress"  v-show="!numFichier" href="#" v-show="suppressOption" @click.prevent="suppress"><img src="/assets/images/cross_r.svg"></a>
    </div>
  `
})