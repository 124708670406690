Vue.component('ticket-card', {
  data: function () { return { }},
  props: {
    ticket: {type: Object, required: true}
  },
  methods: { },
  template:/*html*/`
    <div class="ticket-card">
      <div class="gauche">
        <div class="ticket-status">
          <img v-if="ticket.status == 0" src="/assets/images/new.svg">
          <img v-else-if="ticket.status == 1" src="/assets/images/open.svg">
          <img v-else="" src="/assets/images/closed.svg">
          <img v-if="ticket.echangesNonLus" src="/assets/images/echanges.svg" class="blinking-alert">
        </div>
        <div class="date-ticket" v-html="formatDate(ticket.dateCreation)"></div>
        <div :class="['priorite-ticket', 'priorite-' + ticket.num_priorite]">{{ ticket.priorite || 'ND' }}</div>
        <div class="identifiant">#{{ ticket.identifiant }}</div>
        
        <div class="categorie">{{ticket.categorie + (ticket.sousCategorie ? (' - ' + ticket.sousCategorie) : '')}}</div>
      </div>
      <div class="centre">
        <router-link class="description" :to="'/ticket/' + ticket.identifiant" v-html="ticket.sujetMail || addBr(ticket.description)"></router-link>
        <photo-bouton v-if="ticket.fichiers" :fichiers="ticket.fichiers"></photo-bouton>
        <!-- <a class="description" v-html="ticket.sujetMail || addBr(ticket.description)" href=""></a> -->
      </div>

      <table class="table">
        <tr>
          <td class="info-case info-demandeur">
            <div class="info-titre">demandeur</div>
            <div class="info">{{ ticket.prenomNom }}</div>
            
          </td>
          <td class="info-case info-demandeur">
            <div class="info-titre">Adresse / bâtiment</div>
            <div class="info">
              {{ ticket.lieu || 'Non renseigné' }}
            </div>
          </td>
        </tr>
      </table>
    </div>
  `
})