Vue.component('banner-priorite', {
  props: ['params'],
  methods: {
    updatePriorite (num_priorite) {
      let me = this
      let message = ''

      if (!this.datesLimites[num_priorite] || !this.datesLimites[num_priorite].dateLimite) {
        message = `Ce ticket va être enregistré, et un message sera envoyé au demandeur pour l'avertir.<br><br>
        Confirmez-vous ce changement ?`
      } else {
        message = `Ce ticket va être enregistré, sa date limite de clôture va être fixée de manière irréversible au <b> 
        ` + this.formatDateComplete(this.datesLimites[num_priorite].dateLimite) + 
        `</b> et un message sera envoyé au demandeur pour l'avertir.<br><br>
        Confirmez-vous ce changement ?`
      }

      if (num_priorite === 4) {
        me.$store.commit('setModal', {
          visible: true,
          title: '<span class="txt-red">&#9888;</span> Refus du ticket',
          type: 'component',
          component: 'modal-refus-signalement',
          message: 'Un message de refus du signalement va être envoyé au demandeur, vous pouvez le personnaliser ci-dessous :',
          okCallback (message) {
            if (me.datesLimites[num_priorite] && me.datesLimites[num_priorite].dateLimite) {
              me.ticket.dateLimite    = me.datesLimites[num_priorite].dateLimite
            } else {
              me.ticket.dateLimite = null
            }
            me.ticket.num_priorite  = num_priorite
            me.server.post(
              '/api/priorite/priorite/set',
              {
                num_ticket: me.ticket.num_ticket,
                num_priorite: num_priorite,
                dateLimite: (me.datesLimites[num_priorite] && me.datesLimites[num_priorite].dateLimite) ? me.datesLimites[num_priorite].dateLimite : null,
                message: message.text
              }
            ).then(function () {
              me.$parent.loadTicket()
              me.$store.commit('setDataUpdated')
            })
          }
        })
      } else {

        me.$store.commit('setModal', {
          visible: true,
          message: message,
          title: '<span class="txt-red">&#9888;</span> Modification de la priorité',
          type: 'validation',
          okCallback () {
            if (me.datesLimites[num_priorite] && me.datesLimites[num_priorite].dateLimite) {
              me.ticket.dateLimite    = me.datesLimites[num_priorite].dateLimite
            } else {
              me.ticket.dateLimite = null
            }
            me.ticket.num_priorite  = num_priorite
            me.server.post(
              '/api/priorite/priorite/set',
              {
                num_ticket: me.ticket.num_ticket,
                num_priorite: num_priorite,
                dateLimite: (me.datesLimites[num_priorite] && me.datesLimites[num_priorite].dateLimite) ? me.datesLimites[num_priorite].dateLimite : null
              }
            ).then(function () {
              me.$parent.loadTicket()
              me.$store.commit('setDataUpdated')
            })
          }
        })
      }
    }
  },
  computed: {
    ticket () { return this.$parent.ticket },
    datesLimites () { return this.$parent.datesLimites || []}
  },
  template: /*html*/`
    <div class="header-message">
      Une priorité doit être attribuée ce ticket.<br>
      <a 
        v-for="priorite in $store.state.constants.priorites"
        v-if="priorite.num_priorite !== 4 || params.afficheRefus"
        href="#"
        class="button"
        :class="['priorite-' + priorite.num_priorite]"
        @click.prevent="updatePriorite(priorite.num_priorite)"
        :title="priorite.num_priorite < 4 && datesLimites[priorite.num_priorite] ? ('Date limite : ' + formatDate(datesLimites[priorite.num_priorite].dateLimite)) : 'Pas de date limite'"
      >
        {{ priorite.nom }}{{ priorite.num_priorite < 4 && datesLimites[priorite.num_priorite] ? (' - ' + formatDate(datesLimites[priorite.num_priorite].dateLimite)) : '' }}
      </a>
    </div>
  `
})