Vue.component('banner-message', {
  props: {
    params: Object,
    type: {
      type: String,
      default: ''
    }
  },
  methods: { },
  computed: {
    ticket () { return this.$parent.ticket }
  },
  template: /*html*/`
    <div class="header-message text-message" :class="'type-' + params.type">
      <table>
        <tr>
          <td>
            <img v-if="params.type === 'info'" src="/assets/images/information.svg">
            <img v-else-if="params.type === 'warning'" src="/assets/images/warning.svg">
            <img v-else-if="params.type === 'error'" src="/assets/images/cross.svg">
          </td>
          <td v-html="params.texte"></td>
        </tr>
      </table>
    </div>
  `
})