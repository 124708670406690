Vue.component('in-file', {
  data: function() {
    return {
      id: "inFile" + this._uid,
      uploadRunning: false,
      pct: 0
    }
  },
  watch: { },
  props: [ 'uploadProgressCb', 'signalement', 'linkClass', 'allFiles' ],
  computed: { },
  methods: {  },
  mounted: function () {
    var me = this
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      new ss.SimpleUpload({
        button: me.id,
        url: '/api/image/upload', // server side handler
        responseType: 'string',
        name: 'file',
        dropzone: me.id,
        multiple: false,
        allowedExtensions: me.allFiles ? ["jpg", "jpeg", "jpe", "png", "gif", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf"] : ["jpg", "jpeg", "jpe", "png", "gif"],
        autoSubmit: true,

        onSubmit: function ( ) {
          me.uploadRunning = true
          me.$emit("uploadstart")

        },

        onComplete:   function(filename, response) {
          me.uploadRunning = false
          me.$emit("add", response)
        },
        onProgress: function (pct) {
          me.pct = pct
          if (me.uploadProgressCb) {
            me.uploadProgressCb(pct)
          }
        }
      })
    }, 200);
    
  },
  template: /*html*/`
    <div class="in-file" :class="[signalement && 'signalement']">
      <span v-show="uploadRunning && pct != 100">{{ pct }} %</span>
      <span v-show="uploadRunning && pct == 100">Traitement...</span>
      <a v-show="!uploadRunning" href="#" :id="id" :class="linkClass" style="z-index:5">
        <slot></slot>
      </a>
    </div>
  `
})

