Vue.component('in-multi-choices', {
  props: ['value', 'labelField', 'data', 'dataField', 'readOnly'],
  data () { return {
    selectedValues: [],
    choiceVisible: false
  }},
  methods: {
    updateSelectedValues () {
      this.selectedValues = []
      let allValues = this.value.toString().split(',')
      for (let j = 0; j < this.data.length; j += 1) {
        this.selectedValues[j] = false
      }

      for (let i = 0; i < allValues.length; i += 1) {
        for (let j = 0; j < this.data.length; j += 1) {
          if (this.data[j][this.dataField].toString() === allValues[i]) {
            this.selectedValues[j] = true
          }
        }
      }
    },
    setValue () {
      let selectedDatas = []
      for (let i = 0; i < this.selectedValues.length; i += 1) {
        if (this.selectedValues[i]) {
          selectedDatas.push(this.data[i][this.dataField])
        }
      }
      this.value = selectedDatas.join(',')
    },
    selectAll () {
      if (this.value.split(',').length > this.data.length / 2) {
        for (let j = 0; j < this.data.length; j += 1) { this.selectedValues[j] = false }
      } else {
        for (let j = 0; j < this.data.length; j += 1) { this.selectedValues[j] = true }
      }
      this.setValue()
    },
    switchValue (index) {
      this.selectedValues[index] = !this.selectedValues[index]
      this.setValue()
    },
    emit () {
      this.$emit('input', this.value)
      this.choiceVisible = false
    }
  },
  watch: {
    value () {
      this.updateSelectedValues()
      // this.$emit('input', this.value)
    },
    data () {
      this.updateSelectedValues()
    }
  },
  computed: {
    selectedList () {
      let selected = []
      for (let i = 0; i < this.data.length; i += 1) {
        if (this.selectedValues[i]) {
          selected.push(this.data[i][this.labelField])
        }
      }
      if (selected.length > 3) {
        return selected.slice(0,3).join(', ') + " [..." + (selected.length - 1) + " de plus]"
      }
      return selected.join(', ')
    }
  },
  mounted () {
    this.updateSelectedValues()
  },
  template: /*html*/`
    <div class="in-multi-choices">
      <a href="#" class="list" v-show="!choiceVisible" @click.prevent="choiceVisible = !choiceVisible">
        <span v-if="value.split(',').length === data.length">Tout</span>
        <span v-else-if="value.length === 0">Aucun</span>
        <span v-else="">{{ selectedList }}</span>
        <!--<span v-show="value.split(',').length > 3 && value.split(',').length !== data.length">({{value.split(',').length - 3}}+)</span>-->
      </a>

      <!-- {{ value }} -->
      <div class="choice" v-show="!readOnly && choiceVisible">
        <div class="tous-aucun"><a href="#" @click.prevent="selectAll">Tous / Aucun</a></div>
        <div class="choices-list">
          <div v-for="(element, index) in data"><a href="#" @click.prevent="switchValue(index)" :class="[selectedValues[index] && 'selected']">{{ element[labelField] }}</a></div>
        </div>
        <div class="valider"><a class="button blue" href="#" @click.prevent="emit()">Valider</a></div>
      </div>
    </div>
  `
})