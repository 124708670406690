Vue.component('map-test', {
  props: [],
  mounted () {
    let chart = am4core.create(this.$refs.chartdiv, am4maps.MapChart);

    chart.geodataSource.url = "/api/stats/geojson"


    chart.geodataSource.events.on("parseended", function(ev) {
      var data = [];
      for(var i = 0; i < ev.target.data.features.length; i++) {
        data.push({
          id: ev.target.data.features[i].id,
          value: ev.target.data.features[i].properties.surf_ha,
          name: ev.target.data.features[i].properties.geometry_name
        })
      }
      polygonSeries.data = data;
    })

    chart.projection = new am4maps.projections.Mercator();

    var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    
    polygonSeries.heatRules.push({
      property: "fill",
      target: polygonSeries.mapPolygons.template,
      min: chart.colors.getIndex(1).brighten(1),
      max: chart.colors.getIndex(1).brighten(-0.3)
    });

    polygonSeries.useGeodata = true;

    var polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}: {value}";
    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeWidth = 0.5;

    this.chart = chart;
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  template: `
    <div class="map-chart" ref="chartdiv" style="width:100%;height:300px;">
    </div>
  `
})
