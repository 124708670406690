Vue.component('cell-adresse-bat', {
  props: ['header', 'ticket', 'titre'],
  data() { return {
    pp: this.$parent.$parent
  }},
  methods: {
    htmlLieu (ticket) {
      if (ticket.batiment) return ticket.batiment
      else if (ticket.commune) return ('<span class="commune">' + ticket.commune + '</span><br><span class="adresse">' + ticket.adresse + '</span>')
      return 'Non renseigné'
    }
  },
  template: `
    <component :is="header ? 'th' : 'td'" class="lieu">
      <a v-if="header" href="#" @click.prevent="pp.sortBy('lieu')">
        <span v-show="pp.queryParams.orderBy === 'lieu' && pp.queryParams.orderDesc">&#9650;</span>
        <span v-show="pp.queryParams.orderBy === 'lieu' && !pp.queryParams.orderDesc">&#9660;</span>
        {{  titre || 'Adresse / bâtiment' }}
      </a>
      <span v-else="">
        <span v-if="ticket.batiment"><img src="/assets/images/building.svg"></span>
        <span v-else-if="ticket.commune"><img src="/assets/images/location.svg"></span>
        <span v-html="htmlLieu(ticket)"></span>
      </span>
    </component>
  `
})