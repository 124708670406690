PAGES['connexion'] = Vue.component('page-connexion', {
  data () { return {
    username: '',
    password: '',
    error: false
  }},
  methods: {
    connect () {
      this.server.post('/api/user/login', { username: this.username, password: this.password }).then((userInfos) => {
        if (userInfos.connected) { window.location.replace((this.$route.params.backpath ? this.$route.params.backpath.replace(/\|/g ,'/') : '/')) }
        else { this.error = true }
      })
    }
  },
  mounted () {
    if (this.$store.state.userInfos.connected) {
      this.$router.push('/')
    }
  },
  template: `
    <div class="page-connexion">
      <div class="bg"></div>
      <div class="form">
        <div class="title">
          <img src="/assets/images/logoAppli.svg">
        </div>
        <div class="error" v-show="error">
          Nom d'utilisateur ou mot de passe incorrect !
        </div>
        <div class="label">Nom d'utilisateur</div>
        <input type="text" v-model="username" @keyup.enter="connect">
        <div class="label">Mot de passe</div>
        <input type="password" v-model="password" @keyup.enter="connect">
        <!-- {{ login }} {{ password }} -->
        <a href="#" @click.prevent="connect">CONNEXION</a>
      </div>
    </div>
  `
})