PAGES['accueil'] = Vue.component('page-accueil', {
  data: () => {
    return {
      tickets: [],
      total: 0,
      queryParams: {
        start: 0,
        limit: 50,
        orderBy: null,
        orderDesc: false,
        search: null,
        where: []
      },
      searchNow: false,
      searchTimeout: null,
      currentPage: 1,
      keepPage: false,
      activeFilters: { },
      stats: {},
      selectedStatFilter: 0,
      updateSelectedStatFilter: true,
      excelFields: [],
      currentTable: ''
    }
  },
  watch: {
    queryParams: {
      handler: function () {
        let me = this
        if (this.updateSelectedStatFilter) { this.selectedStatFilter = null }
        this.updateSelectedStatFilter = true
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout)
          this.searchTimeout = null
        }
        this.searchTimeout = setTimeout(
          () => {
            me.searchNow  = false
            if (!me.keepPage) {
              me.currentPage = 1
              me.queryParams.start = 0
            }
            me.keepPage   = false
            me.loadTickets()
            me.countTickets()
          }, me.searchNow ? 0 : 200
        )
      },
      deep: true
    }
  },
  methods: {
    startChange (v) { 
      this.keepPage = true
      this.queryParams.start = v
    },
    loadTickets () {
      this.server.post('/api/ticket/list', this.queryParams).then((data) => { 
        this.tickets = data
        this.$store.commit('setListeTickets', data.map(ticket => ticket.identifiant))
      })
    },
    loadStats () {
      let me = this
      this.server.get('/api/ticket/homeStats').then((data) => {
        me.stats = data
      })
    },
    countTickets () {
      this.server.post('/api/ticket/count', this.queryParams).then((data) => { this.total = data })
    },
    sortBy (field) {
      this.searchNow = true
      if (this.queryParams.orderBy === field) {
        this.queryParams.orderDesc = !this.queryParams.orderDesc
      } else {
        this.queryParams.orderBy = field
        this.queryParams.orderDesc = false
      }
    },
    updateWhere () {
      this.queryParams.where.splice(0, this.queryParams.where.length)

      this.searchNow = true

      for(let field in this.activeFilters) { if(this.activeFilters.hasOwnProperty(field) && this.activeFilters[field].length > 0) {
        this.queryParams.where.push(this.activeFilters[field].map(value => (
          value !== null ? ("(`" + field + "` = '" + value.toString().replace("'", "\\'") + "')") : ("(`" + field + "` IS NULL)")
        )).join(' OR '))
      }}
    },
    addFilter (filterField, value) {
      this.searchNow = true
      this.activeFilters[filterField] = this.activeFilters[filterField] || []

      if (this.activeFilters[filterField].indexOf(value) === -1) {
        this.activeFilters[filterField].push(value)
      } else {
        this.activeFilters[filterField].splice(this.activeFilters[filterField].indexOf(value), 1)

      }
      // this.activeFilters[filterField] = value
      this.updateWhere()
    },
    excel () {
      this.server.post('/api/ticket/excel/gen', {queryParams: this.queryParams, fields: this.excelFields}).then((data) => { 
        window.open('/api/ticket/excel/' + data.file)
      })
    }
  },
  filters: {
    
  },
  computed: {
    unreadCommentsFilter () {
      return "num_ticket IN (SELECT num_ticket FROM commentaire_utilisateur WHERE login = '" + this.login + "')"
    }
  },
  mounted: function () {
    this.currentTable = this.$store.state.userInfos.homeTable
    this.loadTickets()
    this.countTickets()
    this.loadStats()
  },
  template: /*html*/`
    <div class="page-accueil">
      <div class="stats" v-if="!isMobile">
        <home-stat image="/assets/images/ticket.svg" title="TOUS LES TICKETS" :value="stats.tousTickets" :statIndex="0" ></home-stat>
        <home-stat image="/assets/images/hourglass.svg" title="NON-VALIDÉS" :value="stats.ticketsNonValides" :filter="['status = -1']" :statIndex = "1"></home-stat>
        <home-stat image="/assets/images/new.svg" title="NOUVEAUX TICKETS" :value="stats.ticketsNonPec" :filter="['status = 0']" :statIndex = "2"></home-stat>
        <home-stat image="/assets/images/clock.svg" title="TICKETS EN RETARD" :value="stats.ticketRetard" :filter="['dateLimite <= NOW()', 'status = 1']" :statIndex = "3"></home-stat>
        <home-stat image="/assets/images/open.svg" title="TICKETS OUVERTS" :value="stats.ticketsOuverts" :filter="['status = 1']" :statIndex = "4"></home-stat>
        <home-stat image="/assets/images/month.svg" title="30 DERNIERS JOURS" :value="stats.tickets30jours" :filter="['dateCreation > DATE_SUB(NOW(),INTERVAL 1 MONTH)']" :statIndex = "5"></home-stat>
        <home-stat image="/assets/images/echanges.svg" title="MESSAGES NON-LUS" :value="stats.echangesNonLus" :filter="['echangesNonLus > 0']" :statIndex = "6"></home-stat>
        <home-stat image="/assets/images/echanges.svg" title="MESSAGES NON-LUS" :value="stats.echangesSNonLus" :filter="['echangesServiceNonLus > 0']" :statIndex = "6"></home-stat>
        <home-stat image="/assets/images/message.svg" title="COMMENTAIRES" :value="stats.commentairesNonLus" :filter="[unreadCommentsFilter]" :statIndex = "7"></home-stat>
      </div>
      <div class="filters" :class="[isMobile && 'mobile']">
        <filter-status v-if="!isMobile"></filter-status>
        <filter-priorite v-if="!isMobile"></filter-priorite>
        <filter-domaine v-if="!isMobile"></filter-domaine>
        <filter-type-demandeur v-if="!isMobile"></filter-type-demandeur> 
        <filter-pec-commune v-if="!isMobile"></filter-pec-commune> 
        <div>
          <div class="title">RECHERCHER :</div>
          <input type="text" v-model="queryParams.search">
        </div>
      </div>
      <el-pagination v-if="total > queryParams.limit && !isMobile" :start="queryParams.start" :total="total" :nPerPage="queryParams.limit" @startChange="startChange"></el-pagination>
      <div class="export-excel" v-if="excelFields.length > 0">
        <a href="#" @click.prevent="excel">
          <img src="/assets/images/excel.svg">
          EXPORTER EN EXCEL
        </a>
      </div>
      <component v-bind:is="currentTable" :tickets="tickets" v-bind:excelFields.sync="excelFields" v-if="!isMobile"></component>
      <!--<component v-bind:is="$store.state.userInfos.homeTable" :tickets="tickets" v-bind:excelFields.sync="excelFields" v-if="!isMobile"></component>-->
      <div class="cards-container" v-if="isMobile">
        <ticket-card v-for="ticket in tickets" :ticket="ticket"></ticket-card>
      </div>
      <el-pagination v-if="total > queryParams.limit" :start="queryParams.start" :total="total" :nPerPage="queryParams.limit" @startChange="startChange"></el-pagination>
    </div>
  `
})