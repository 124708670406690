Vue.component('form-field', {
  props: ['label'],
  data () { return {
  }},
  mounted () { },
  template: `
    <tr>
      <td class="label">{{ label }}&nbsp;:</td>
      <td class="field">
        <slot></slot>
      </td>
    </tr>
  `
})