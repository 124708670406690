Vue.component('mod-commentaires-1', {
  props: ['params'],
  data () { return {
    newComment:   ""
  }},
  methods: {
    addComment () {
      let me = this
      this.server.post('/api/ticket/comment/save', {
        num_ticket: this.ticket.num_ticket,
        login:      this.login,
        texte:      this.newComment
      }).then((data) => {
        me.commentaires.unshift(data)
        me.newComment = ""
      })
    }
  },
  computed: {
    commentaires () { return this.$parent.commentaires },
    ticket () { return this.$parent.ticket }
  },
  template: /*html*/`
    <div class="module">
      <h2>Commentaires internes</h2>
      <table>
        <tr v-if="params.fichier">
          <td class="label">Fichiers joints :</td>
          <td class="field">
            <in-fichier v-model="ticket.files.commentaire" :readOnly="!params.readWrite" :autosave="{num_ticket: ticket.num_ticket, champ: 'commentaire'}" class="small"></in-fichier>
          </td>
        </tr>
      </table>
      <div v-if="params.readWrite">
        <in-long-text v-model="newComment"></in-long-text>
        <a href="#" class="button blue" :class="[ newComment.length < 3 && 'disabled' ]" @click.prevent="addComment" :readOnly="false">Ajouter</a>
      </div>
      <div>
        <div class="commentaire" v-for="commentaire in commentaires">
          <span>{{ formatDate(commentaire.date, true) }} - {{ commentaire.nomComplet }}</span>
          <div>{{ commentaire.texte }}</div>
        </div>
      </div>
    </div>
  `
})