Vue.component('photo-bouton', {
  props: ['fichiers', 'thumbnail'],
  data () { return {
    viewer: null,
    loadPhoto: false
  }},
  methods: {
    showPhoto () {
      this.loadPhoto = true
      if (!this.viewer) {
        this.viewer = new Viewer(this.$refs.photo, {
          title: 0,
          navbar: 0,
          transition: true,
          toolbar: { zoomIn: {show: 1, size: 'large' }, 
            zoomOut: {show: 1, size: 'large' }, oneToOne: 0, reset: 0, prev: {show: 1, size: 'large' }, play: 0, next: {show: 1, size: 'large' }, rotateLeft: {show: 1, size: 'large' }, rotateRight: {show: 1, size: 'large' }, flipHorizontal: 0, flipVertical: 0 }
        })
      }
      this.viewer.show()
    }
  },
  computed: {
    fichier () { return this.fichiers.split(',')[0] }
  },
  beforeDestroy () {
    if (this.viewer) {
      this.viewer.destroy()
    }
  },
  template: `
    <a href="#" @click.prevent="showPhoto" class="photo-bouton">
      <img src="/assets/images/picture.svg" class="icon">
      <div>
        <img :src="loadPhoto ? ('/api/image/' + fichier) : '/assets/images/picture.svg'" ref="photo">
      </div>
    </a>
  `
})