Vue.component('table-agent', {
  data: function () { return {
    distinctUsers: []
  }},
  computed: {
    tickets () { return this.$parent.tickets }
  },
  mounted () {
    let me = this
    this.server.get('/api/ticket/distincts/prenomNom').then(function (data) {
      me.distinctUsers = data
    })
  },
  template: /*html*/`
    <table class="table-accueil table-agent liste-tickets">
      <thead>
        <tr>
          <cell-id :header="true"></cell-id>
          <cell-demandeur :header="true"></cell-demandeur>
          <cell-date-creation :header="true"></cell-date-creation>
          <cell-status-texte :header="true"></cell-status-texte>
          <cell-demandeur v-if="distinctUsers.length > 1" :header="true"></cell-demandeur>
          <cell-domaine :header="true"></cell-domaine>
          <cell-description :header="true"></cell-description>
        </tr>
      </thead>
      <tbody>
        <tr v-for="ticket in tickets" :class="[ticket.status === 2 && 'cloture', ticket.status === -2 && 'dismissed']">
          <cell-id :ticket="ticket"></cell-id>
          <cell-demandeur :ticket="ticket"></cell-demandeur>
          <cell-date-creation :ticket="ticket"></cell-date-creation>
          <cell-status-texte :ticket="ticket" :hideUnread="true"></cell-status-texte>
          <cell-demandeur v-if="distinctUsers.length > 1" :ticket="ticket"></cell-demandeur>
          <cell-domaine :ticket="ticket"></cell-domaine>
          <cell-description :ticket="ticket"></cell-description>
        </tr>
      </tbody>
    </table>
  `
})