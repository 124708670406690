Vue.component('cell-id', {
  props: ['header', 'ticket'],
  computed : {
    identifiant () {
      return this.ticket.identifiant.replace(/-/g, '&#8209;')
    }
  },
  template: /*html*/`
    <component :is="header ? 'th' : 'td'" class="cell-id">
      <span v-if="!header" v-html="identifiant + '<br><span>' + ticket.uniqueId + '</span>'"></span>
    </component>
  `
})