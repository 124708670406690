PAGES['signalement'] = Vue.component('page-signalement', {
  data: function () { return {
    currentStep: 2,
    uniqueId: 'az87tui',
    selectedUniqueId: 'sdf',
    selectedTicketDate: '',
    ticketsId: [],
    myTickets: [],
    ticket: {
      numFichiers: [],
      login: this.$store.state.userInfos.login || null,
      email: this.$store.state.userInfos.mail || this.$store.state.userInfos.cookie.email || '',
      prenomNom: this.$store.state.userInfos.prenomNom || this.$store.state.userInfos.cookie.prenomNom || '',
      telephone: this.$store.state.userInfos.connected ? '' : (this.$store.state.userInfos.cookie.telephone || ''),
      num_origine: 1,
      num_categorie: this.$route.params.num_categorie || null,
      num_sousCategorie: this.$route.params.num_sousCategorie || null,
      description: '',
      num_surCategorie: 1,
      files: {}
    },
    runningUpload: false
  } },
  watch: {
    currentStep (v) {
      if (v === 8) {
        let me = this
        this.server.post('/api/ticket/save', this.ticket).then((data) => {
          // window.scrollTo(0,0)
          // me.$store.commit('setDataUpdated')
          me.uniqueId = data.uniqueId
          me.$el.querySelector('.main-content').style.marginLeft = "-" + this.currentStep + "00%"
          me.$set(me.ticket, 'numFichiers', [])
          me.$set(me.ticket, 'description', '')
        })
      } else {
        this.$el.querySelector('.main-content').style.marginLeft = "-" + this.currentStep + "00%"
      }
      
    }
  },
  methods: {
    goToStep (step) {
      this.currentStep = step
      
    },
    updateLocation (data) {
      this.ticket.adresse     = data.name || ''
      this.ticket.codePostal  = data.postcode || ''
      this.ticket.nomCommune  = data.city || ''
      this.ticket.latitude    = data.coords[1] || this.ticket.latitude
      this.ticket.longitude   = data.coords[0] || this.ticket.longitude

    },
    setPhoto (infosPhoto) {
      this.ticket.numFichiers.push(infosPhoto.split(':')[0])
      // this.currentStep += 1
    },
    removePhoto () {
      this.ticket.files.photo.splice(0, this.ticket.files.photo.length)
    },
    uploadProgress (pct) {
      this.runningUpload = pct > 0 && pct < 100

      let domUploadProgress = this.$el.querySelectorAll(".uploadProgress")
      for (let i = 0; i < domUploadProgress.length; i += 1) {
        domUploadProgress[i].querySelector("div").style.width = pct + 'px'
      }
    }
  },
  filters: {
    stepButtonLabel (step) {
      switch (step) {
        case 4:
          return 'VALIDER LA LOCALISATION'
        case 5:
          return 'VALIDER LA DESCRIPTION'
        case 6:
          return 'VALIDER LES COORDONNÉES'
        case 7:
          return 'ENVOYER'
        default:
          return 'VALIDER'
      }
    }
  },
  mounted () {
    this.server.get('/api/ticket/cookieTickets').then((data) => {
      this.myTickets = data;
    })
    if (this.$store.state.userInfos.cookie.ticketsId) {
      this.ticketsId = this.$store.state.userInfos.cookie.ticketsId.split(',');
      this.ticketsId.pop();
    }
    if (this.$route.params.num_categorie) {
      this.goToStep(3)
    }
  },
  template: /*html*/`
    <div class="page-signalement">
      <div class="fond-haut"></div>
      <div class="main-content">
        <div>
          <div class="entete">
            <h1>Signalement du {{ formatDateComplete(selectedTicketDate, true) }}</h1>
            <a href="#" @click.prevent="currentStep += 1" class="right">Liste de vos signalements &gt;</a>
          </div>
          <div class="details-ticket">
            <form-ticket :ticketUniqueId="selectedUniqueId"></form-ticket>
          </div>
        </div>
        <div>
          <div class="entete">
            <h1>Vos Signalements</h1>
            <a href="#" @click.prevent="currentStep += 1" class="right">Accueil &gt;</a>
          </div>
          <div class="vos-signalements">
            <a href="#" v-for="ticket in myTickets" @click.prevent="selectedUniqueId=ticket.uniqueId; selectedTicketDate = ticket.dateCreation ;currentStep -= 1">
              <img v-if="ticket.status == -2" src="/assets/images/cross.svg">
              <img v-else-if="ticket.status == -1" src="/assets/images/hourglass.svg">
              <img v-else-if="ticket.status == 0" src="/assets/images/new.svg">
              <img v-else-if="ticket.status == 1" src="/assets/images/open.svg">
              <img v-else="" src="/assets/images/closed.svg">
              <span class="date">
                Signalement du <b>{{ formatDateComplete(ticket.dateCreation, true) }}</b> : <br><br>
                <b>{{ ticket.categorie }}</b> au <b>{{ ticket.adresse }}</b> à <b>{{ ticket.commune }}</b>
              </span>
            </a>
          </div>
        </div>
        <div>
          <div class="entete">
            <img src="/assets/images/logoAppli.svg">
          </div>
          <a href="#" class="lien-vos-signalements" v-if="$store.state.userInfos.cookie.ticketsId" @click.prevent="currentStep -= 1">VOS SIGNALEMENTS</a>
          <!-- <div class="alerte">Les signalements présentant un caractère d'urgence n'ont pas vocation à être saisis dans Mon Appli, merci dans ce cas de contacter l'astreinte idoine.</div> -->
          <div class="choix-categorie">
            <div class="icon" v-for="icon in categoriesIcons" v-if="icon">
              <a href="#" @click.prevent="ticket.num_categorie=icon.num_categorie;currentStep += 1">
                <img :src="'/assets/images/boutons/' + icon.image">
                <span>{{ icon.label }}</span>
              </a>
            </div>
          </div>
        </div>
        <div>
          <div class="entete">
            <h1>Nouveau Signalement</h1>
            {{ ticket.num_categorie ? $store.state.indexedConstants.categories[ticket.num_categorie].nom : '' }}
            <a href="#" @click.prevent="currentStep -= 1">< Retour</a>
            <div class="uploadProgress" v-show="runningUpload"><div></div></div>
          </div>
          <div class="choix-photo">
            <!--<in-file v-show="ticket.numFichiers.length === 0" @add="setPhoto" @uploadstart="currentStep += 1" :uploadProgressCb="uploadProgress" :signalement="true" :linkClass="'rond'">
              <img src="/assets/images/camera.svg">
            </in-file>-->
            <in-fichier v-model="ticket.files.photo" :maxFiles="1" :linkClass="'gros-rond'" :disableSuppress="true" @uploadstart="currentStep += 1" :uploadProgressCb="uploadProgress">
              <img src="/assets/images/camera.svg">
            </in-fichier>
            <div class="mt-10" v-show="!ticket.files.photo || ticket.files.photo.length === 0">
              Souhaitez-vous joindre une photo ?
            </div>
            <!-- <in-file v-show="ticket.numFichiers.length === 0" @add="setPhoto" @uploadstart="currentStep += 1" :uploadProgressCb="uploadProgress" :signalement="true" :linkClass="'button green fs-16 w-100'">
              <span>OUI</span>
            </in-file>-->
            <in-fichier v-model="ticket.files.photo" :maxFiles="1" :linkClass="'button green fs-16 w-100'" :hideTb="true" @uploadstart="currentStep += 1" :uploadProgressCb="uploadProgress">
              <span>OUI</span>
            </in-fichier>
            <a v-show="!ticket.files.photo || ticket.files.photo.length === 0" href="#" class="button red fs-16 w-100 mt-10" @click.prevent="currentStep += 1">NON</a>
            <img v-show="ticket.files.photo && ticket.files.photo.length !== 0" v-for="numFichier in ticket.numFichiers" :src="'/api/image/tb/' + numFichier"><br>
            <a v-show="ticket.files.photo && ticket.files.photo.length !== 0" href="#" class="button blue" @click.prevent="currentStep += 1">VALIDER</a>
            <a v-show="ticket.files.photo && ticket.files.photo.length !== 0" href="#" class="button red" @click.prevent="removePhoto">SUPPRIMER / MODIFIER</a>
          </div>
        </div>
        <div>
          <div class="entete">
            <h1>Nouveau Signalement</h1>
            {{ ticket.num_categorie ? $store.state.indexedConstants.categories[ticket.num_categorie].nom : '' }}
            <a href="#" @click.prevent="currentStep -= 1">< Retour</a>
            <div class="uploadProgress" v-show="runningUpload"><div></div></div>
          </div>
          <div class="choix-localisation">
            <in-geo @change="updateLocation" mobile="1" ></in-geo>
            <!-- <a href="#" class="button blue valide-choix" @click.prevent="currentStep += 1">VALIDER LA LOCALISATION</a> -->
          </div>
        </div>
        <div>
          <div class="entete">
            <h1>Nouveau Signalement</h1>
            {{ ticket.num_categorie ? $store.state.indexedConstants.categories[ticket.num_categorie].nom : '' }}
            <a href="#" @click.prevent="currentStep -= 1">< Retour</a>
            <div class="uploadProgress" v-show="runningUpload"><div></div></div>
          </div>
          <div class="choix-description">
            <div class="label-form">Description</div>
            <in-long-text 
              v-model="ticket.description" 
              emptyLabel="Description de la demande, ne saisissez pas d'informations personnelles dans ce champs."
              maxLength="400" 
              :readOnly="false"
            ></in-long-text>
            <!-- <a href="#" class="button blue valide-choix" @click.prevent="currentStep += 1" :class="[ticket.description.length < 0 && 'disabled']">VALIDER LA DESCRIPTION</a> -->
          </div>
        </div>
        <div>
          <div class="entete">
            <h1>Nouveau Signalement</h1>
            {{ ticket.num_categorie ? $store.state.indexedConstants.categories[ticket.num_categorie].nom : '' }}
            <a href="#" @click.prevent="currentStep -= 1">< Retour</a>
            <div class="uploadProgress" v-show="runningUpload"><div></div></div>
          </div>
          <div class="choix-coordonnees">
            <!-- <div class="label-form">Vos prénom et nom :</div> -->
            <in-text v-model="ticket.prenomNom" emptyLabel="Vos prénom et nom"></in-text>

            <!-- <div class="label-form" id="inMail">Votre adresse de messagerie :</div> -->
            <in-text v-model="ticket.email" emptyLabel="Votre adresse de messagerie" :error="!mailValide(ticket.email)" errorMessage=" "></in-text>

            <!-- <div class="label-form">Votre numéro de téléphone :</div> -->
            <in-text v-model="ticket.telephone" emptyLabel="Votre numéro de téléphone" :error="!telephoneValide(ticket.telephone)" errorMessage=" "></in-text>
            
            <div class="error-message" v-show="!(mailValide(ticket.email) || telephoneValide(ticket.telephone))">
              Merci de renseigner un numéro de téléphone ou une adresse mail valide.
            </div>

            <div class="rgpd">
              La Communauté de Communes du Bassin de Pompey traite les données recueillies pour assurer le suivi des signalements des usagers.
              La commune correspondante à la localisation de votre demande sera également destinatrice des informations saisies.
              Pour en savoir plus sur la gestion de vos données personnelles et pour exercer vos droits, reportez-vous à la page dédiée de notre site internet.<br>
              <a href="https://www.bassinpompey.fr/informations-pratiques/politique-de-gestion-des-donnees-personnelles" target="_blank">Cliquer ici pour y accéder</a>
              <!-- <a href="#" class="button blue valide-choix" @click.prevent="currentStep += 1">VALIDER LES COORDONNÉES</a> -->
            </div>

          </div>
        </div>
        <div>
          <div class="entete">
            <h1>Nouveau Signalement</h1>
            Récapitulatif
            <a href="#" @click.prevent="currentStep -= 1">< Retour</a>
            <div class="uploadProgress" v-show="runningUpload"><div></div></div>
          </div>
          <div class="choix-recap">
            <table>
              <tr v-show="ticket.files.photo && ticket.files.photo.length > 0"><td colspan="2" class="photo"><img v-for="numFichier in ticket.files.photo" :src="'/api/image/tb/' + numFichier"></td></tr>
              <tr><td colspan="2" class="categorie">{{ ticket.num_categorie ? $store.state.indexedConstants.categories[ticket.num_categorie].nom : '' }}</td></tr>
              <tr><td colspan="2" class="adresse">{{ ticket.adresse + ' ' + ticket.codePostal + ' ' + ticket.nomCommune }}</td></tr>
              <tr><td colspan="2" class="description">{{ ticket.description }}</td></tr>
              <tr>
                <td class="label">Prénom - Nom :</td>
                <td v-html="ticket.prenomNom.trim() === '' ? '<em>Non renseigné</em>' : ticket.prenomNom"></td>
              </tr>
              <tr>
                <td class="label">Email :</td>
                <td v-html="ticket.email.trim() === '' ? '<em>Non renseigné</em>' : ticket.email"></td>
              </tr>
              <tr>
                <td class="label">Téléphone :</td>
                <td v-html="ticket.telephone.trim() === '' ? '<em>Non renseigné</em>' : ticket.telephone"></td>
              </tr>
              
            </table>
          </div>
        </div>
        <div class="choix-confirmation">
          <div class="entete">
            <h1>Nouveau Signalement</h1>
            Confirmation d'envoi
          </div>
          <div class="confirm-message">
            Votre signalement a bien été enregistré.<br>
            Si vous avez renseigné votre email, un message de confirmation et une URL de suivi vous seront envoyés.<br><br>
            Vous pouvez également consulter le suivi de votre demande depuis la <a href="#" @click.prevent="location.reload()">page d'accueil</a> et le bouton <b>"VOS SIGNALEMENTS"</b>
            <a href="#" @click.prevent="location.reload()" class="retour">Retour à l'accueil</a>
          </div>
        </div>
      </div>
      
      <div class="step-indicator">
        <a href="#" class="button blue valide-choix" @click.prevent="currentStep += 1" v-show="[4,5,6,7].includes(currentStep) && !(runningUpload && currentStep === 7 ) && ((mailValide(ticket.email) || telephoneValide(ticket.telephone)) || currentStep !== 6)">{{ currentStep | stepButtonLabel }}</a>
        <a href="#" class="button blue valide-choix disabled" v-show="[7].includes(currentStep) && runningUpload">Chargement en cours...</a>
        <a href="#" class="button blue valide-choix disabled" v-show="[6].includes(currentStep) && !(mailValide(ticket.email) || telephoneValide(ticket.telephone))">VALIDER LES COORDONNÉES</a><br>
        <span v-for="index in 6" v-show="currentStep < 8 && currentStep > 1"><img :src="currentStep > index ? '/assets/images/rond-plein.svg' : '/assets/images/rond-vide.svg'"><img v-if="index !== 6" src="/assets/images/tiret.svg"></span>
      </div>
    </div>
  `
})