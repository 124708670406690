Vue.component('mod-dom-pdl-pj', {
  props: ['params', 'newTicket'],
  data () { return {
    readOnly: true
  }},
  methods: {
    save () {  this.$parent.updateTicket(['prenomNom', 'email', 'telephone']) },
    reload () {
      this.$parent.loadTicket()
      this.readOnly = true
    }
  },
  computed: {
    ticket () { return this.$parent.ticket },
    runningCheck () {return this.$parent.runningCheck },
    newTicket () { return this.$parent.newTicket },
  },
  template: /*html*/`
    <div class="module">
      <h2>
        Logement concerné
        <a href="#" v-if="params.readWrite && !newTicket" class="button green" v-show="readOnly" @click.prevent="readOnly = false">CORRIGER</a>
        <a href="#" v-if="params.readWrite && !newTicket" class="button blue" v-show="!readOnly" @click.prevent="save">ENREGISTRER</a>
        <a href="#" v-if="params.readWrite && !newTicket" class="button red" v-show="!readOnly" @click.prevent="reload">ANNULER</a>
      </h2>
      <table>
        <tr>
          <td class="label">Adresse :</td>
          <td class="field" colspan="2">
            <in-text v-model="ticket.pdl_adresse_logement" small="1"  :readOnly="(!newTicket && readOnly)"  emptyValue="(non renseigné)"></in-text>
          </td>
        </tr>
        <tr>
          <td class="field">
            <in-fichier v-model="ticket.files.pdl_dpe" label="DPE" :readOnly="(!newTicket && readOnly)" :maxFiles="1"></in-fichier>
          </td>
          <td class="field">
            <in-fichier v-model="ticket.files.pdl_crep" label="CREP" :readOnly="(!newTicket && readOnly)" :maxFiles="1"></in-fichier>
          </td>
          <td class="field">
            <in-fichier v-model="ticket.files.pdl_amiante" label="État amiante" :readOnly="(!newTicket && readOnly)" :maxFiles="1"></in-fichier>
          </td>
        </tr>
        <tr>
          <td class="field">
            <in-fichier v-model="ticket.files.pdl_elec" label="État éléctricité" :readOnly="(!newTicket && readOnly)" :maxFiles="1"></in-fichier>
          </td>
          <td class="field">
            <in-fichier v-model="ticket.files.pdl_gaz" label="État gaz" :readOnly="(!newTicket && readOnly)" :maxFiles="1"></in-fichier>
          </td>
          <td class="field">
            <in-fichier v-model="ticket.files.pdl_risques" label="État risques / pollution" :readOnly="(!newTicket && readOnly)" :maxFiles="1"></in-file-direct>
          </td>
        </tr>
      </table>
    </div>
  `
})