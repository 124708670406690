Vue.component('cell-status', {
  props: ['header', 'ticket', 'hideUnread'],
  template: /*html*/`
    <component :is="header ? 'th' : 'td'" :class="[!header && ('status-' + ticket.status), !header && 'meta']" class="txt-center">
      <a v-if="header" href="#" @click.prevent="$parent.$parent.sortBy('status')">
        <span v-show="$parent.$parent.queryParams.orderBy === 'status' && $parent.$parent.queryParams.orderDesc">&#9650;</span>
        <span v-show="$parent.$parent.queryParams.orderBy === 'status' && !$parent.$parent.queryParams.orderDesc">&#9660;</span>
        Statut
      </a>
      <span v-else="">
        <img v-if="ticket.status == -2" src="/assets/images/cross.svg">
        <img v-else-if="ticket.status == -1" src="/assets/images/hourglass.svg">
        <img v-else-if="ticket.status == 0" src="/assets/images/new.svg">
        <img v-else-if="ticket.status == 1" src="/assets/images/open.svg">
        <img v-else="" src="/assets/images/closed.svg">
        <img v-if="(ticket.echangesNonLus || ticket.commentairesNonLus) && !hideUnread" src="/assets/images/echanges.svg" class="blinking-alert">
        <!--<img v-if="ticket.commentairesNonLus && !hideUnread" src="/assets/images/message.svg" class="blinking-alert">-->
      </span>
    </component>
  `
})