/* TODO : Gestion des erreurs d'enregistrement */

Vue.component('form-ticket', {
  props: ['ticketId', 'ticketUniqueId'],
  data: function () { return {
    adresse:"Choisissez une localisation",
    locationValid: false,
    scrollBottom: 500,
    ticket: {
      numFichiers: [],
      login: this.$store.state.userInfos.login || null,
      email: this.$store.state.userInfos.mail || this.$store.state.userInfos.cookie.email || null,
      prenomNom: this.$store.state.userInfos.prenomNom || this.$store.state.userInfos.cookie.prenomNom || null,
      telephone: this.$store.state.userInfos.connected ? this.$store.state.userInfos.telephone : (this.$store.state.userInfos.cookie.telephone || null),
      num_origine: 1,
      num_categorie: this.$route.params.num_categorie || null,
      num_sousCategorie: this.$route.params.num_sousCategorie || null,
      num_surCategorie: this.$store.state.userInfos.num_surCategories[0],
      num_batiment: this.$store.state.userInfos.num_batiment || null,
      files: {}
    },
    invalid: {description: false},
    runningCheck : false,
    layouts: [],
    datesLimites: [],
    col0Empty : false,
    cols: [[], [], [], [], []],
    intitule: ''
  }},
  watch: {
    ticketUniqueId () {
      this.loadTicket()
    }
  },
  methods: {
    markRead () {
      this.server.get('/api/ticket/echange/readTicket/' + this.ticket.num_ticket)
    },
    loadTicket () {
      let me = this
      let setTicket = (data) => {
        me.ticket           = data.ticket
        // me.fichiers         = data.fichiers
        me.echanges         = data.echanges
        me.valideur         = data.valideur
        me.intitule         = me.$store.state.indexedConstants.surCategories[me.ticket.num_surCategorie].intitule
        me.ticket.files     = data.files

        me.demandeur      = data.demandeur || null
        me.datesLimites   = data.datesLimites || null
        me.commentaires   = data.commentaires || null

        // me.$refs.modloc.updateMap()

        me.server.get('/api/layouts/configuration/' + me.ticket.num_surCategorie + '/' + me.ticket.num_ticket).then((data) => {
          if (data.length === 0) {
            window.location.replace("/connexion/%7Cticket%7C" + me.ticket.identifiant)
          }
          me.setLayouts(data)
          me.drawLayout()
        })

      }

      if (me.ticketUniqueId) {
        me.server.get('/api/ticket/byUniqueId/' + me.ticketUniqueId).then((data) => { 
          if (data) {
            if (!data.ticket) { me.$router.push('/usager') }
            else {
              setTicket(data)
              if (me.ticket.login === me.login || !me.connected) { me.markRead() }
            }
          }
        })
      } else if (me.ticketId) {
        if (me.login) {
          me.server.get('/api/ticket/markCommentRead/' + me.ticketId).then((data) => {
            if (data.affectedRows > 0) {
              me.$store.commit('setDataUpdated')
            }
          })
        }
        me.server.get('/api/ticket/byIdentifiant/' + me.ticketId).then((data) => { 
          if (!data) { me.$router.push('/') }
          else {
            setTicket(data)
            if (me.ticket.num_batiment) { me.mapUpdatedFromBatiment = true }
            if (me.ticket.login === me.login) { me.markRead() }
          }
        })
      }
    },
    // Affichage des éléments, rappelé à chaque changement de catégorie
    drawLayout () {
      this.cols = [[], [], [], [], []]
      if (this.layouts[this.ticket.num_surCategorie]) {
        for (let i = 0; i < this.layouts[this.ticket.num_surCategorie].length; i += 1){
          if (this.layouts[this.ticket.num_surCategorie][i]) this.cols[i] = this.layouts[this.ticket.num_surCategorie][i]
        }
      }
    },    
    handleScroll () {
      this.scrollBottom = Math.max(document.body.offsetHeight - (window.pageYOffset + window.innerHeight), 0)
    },
    save () {
      let me = this
      this.server.post('/api/ticket/save', this.ticket).then((data) => {
        window.scrollTo(0,0)
        me.$store.commit('setDataUpdated')
        if (data.identifiant) {
          router.push('/ticket/' + data.identifiant)
        } else {
          router.push('/ticket/resume/' + data.uniqueId + '/1')
        }
      })
    },
    updateTicket (fieldsToInclude = null) {
      let me = this
      this.server.post('/api/ticket/update', {ticket: this.ticket, fieldsToInclude: fieldsToInclude}).then(() => {
        me.loadTicket()
        me.$store.commit('setDataUpdated')
      })
    },
    checkBeforeSave () {
      let me = this
      this.runningCheck = true
      setTimeout(
        () => {
          if (document.getElementsByClassName('error').length > 0) {
            me.$store.commit('setModal', {
              visible: true,
              message: `Vous n'avez pas renseigné tous les champs obligatoires`,
              title: 'Champs obligatoire',
              type: 'info'
            })
            document.getElementsByClassName('error')[0].scrollIntoView()
          } else {
            if ((!me.ticket.email || me.ticket.email.length < 5) && (!me.ticket.prenomNom || me.ticket.prenomNom.length < 3)) {
              me.$store.commit('setModal', {
                visible: true,
                message: "Vous n'avez pas renseigné d'addresse mail ni de numéro de téléphone," +
                  "nous ne pourrons donc pas vous contacter pour vous informer de l'avancement " +
                  "du ticket ou vous demander d'avantage d'informations, validez-vous quand même cette demande ?",
                title: 'Absence de coordonnées',
                type: 'validation',
                okCallback () {
                  me.save()
                }
              })
            } else {
              me.save()
            }
          }
          
        },
      100)
    },
    setLayouts (data) {
      this.layouts = []
      let currentColumn = 0
      for (let i = 0; i < data.length; i += 1) {
        let num_surCategorie  = data[i].num_surCategorie
        let column            = data[i].column

        if (i > 0) {
          if (num_surCategorie !== data[i - 1].num_surCategorie) {
            currentColumn = 0
          } else if (data[i - 1].column !== column) {
            currentColumn += 1
          }
        }

        if (column === 10) currentColumn = 10

        if (!this.layouts[num_surCategorie]) {this.layouts[num_surCategorie] = [] }
        if (!this.layouts[num_surCategorie][currentColumn]) { this.layouts[num_surCategorie][currentColumn] = [] }

        this.layouts[num_surCategorie][currentColumn].push( {name: data[i].component, params: this.parseParams(data[i].params) })
      }
 
    },
    messageConfirmation () {
      return 'Votre demande a bien été enregistrée.'
    },
    printTicket () {
      let me = this
      this.$store.commit('incRunnnigQueries');
      this.$refs['mod-localisation-1'][0].exportMap().then((data) => { 
        this.server.post('/api/ticket/pdf/gen', {num_ticket : me.ticket.num_ticket, map_data: data}).then(() => {
          window.open('/api/ticket/pdf/' + me.ticket.num_ticket, '_blank')
          me.$store.commit('decRunnnigQueries');
        })
      })
    }
  },
  mounted () {
    let me = this
    this.scrollBottom = Math.max(document.body.offsetHeight - (window.pageYOffset + window.innerHeight), 0)

    if (!me.newTicket) {
      me.loadTicket()
    } else {
      me.server.get('/api/layouts/new/configuration').then((data) => {
        me.setLayouts(data)
        me.drawLayout()
      })
    }

  },
  computed: {
    newTicket () { return (this.ticketId || this.ticketUniqueId) ? false : true }
  },
  activated () {
    window.addEventListener('scroll', this.handleScroll);
  },
  deactivated () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  template: /*html*/`
    <div class="form-ticket">
      <h1 v-if="ticketUniqueId">Ticket n°{{ ticketUniqueId }}</h1>
      <h1 v-else-if="ticketId">
        Ticket n°{{ ticketId }}<br/>
        <span v-if="$store.state.indexedConstants.surCategories[ticket.num_surCategorie].decoupageCommunal">suivi par <b>{{ ticket.pecCommune ? ('la commune de ' + $store.state.indexedConstants.communes[ticket.num_commune].nom) : 'le Bassin de Pompey' }}</b></span>
      </h1>
      <h1 v-else-if="!num_profil">Mon Appli</h1>
      <h1 v-else="">{{ $store.state.indexedConstants.surCategories[ticket.num_surCategorie].titre || "Demande d'intervention" }}</h1>
      <div>
        <div class="header-message" v-if="$route.params.confirm" v-html="messageConfirmation()"></div>
        <component v-for="element in cols[10]" v-bind:is="element.name" :params="element.params" :newTicket="newTicket" :ref="element.name"  :key="ticket.num_surCategorie"></component>
      </div>
      <div class="main-form-container">
        <div v-show="!col0Empty || ($store.state.userInfos.num_surCategories.length > 1 && newTicket)">
          <div v-if="$store.state.userInfos.num_surCategories.length > 1 && newTicket">
            <h2>Domaine</h2>
            <div class="choix-sur-categorie">
              <a 
                href="#"
                v-for="num_surCategorie in $store.state.userInfos.num_surCategories"
                :class="[ticket.num_surCategorie === num_surCategorie && 'active']"
                @click.prevent="ticket.num_surCategorie = num_surCategorie;drawLayout()"
              >
                <img v-if="$store.state.indexedConstants.surCategories[num_surCategorie].icone" :src="'/assets/images/' + $store.state.indexedConstants.surCategories[num_surCategorie].icone"><br>
                {{ $store.state.indexedConstants.surCategories[num_surCategorie].nom }}
              </a>
            </div>
          </div>
          <div ref="col0">
            <component v-for="element in cols[0]" v-bind:is="element.name" :params="element.params" :newTicket="newTicket" :key="ticket.num_surCategorie"></component>
          </div>
        </div>
        <div v-for="i in 4" v-show="cols[i].length > 0">
          <component v-for="element in cols[i]" v-bind:is="element.name" :params="element.params" :newTicket="newTicket" :ref="element.name"  :key="ticket.num_surCategorie"></component>
        </div>
      </div>
      <div class="bandeau-bas" v-show="scrollBottom < 100 && newTicket" >
        <a href="#" @click.prevent="checkBeforeSave">
          <i class="icon-paper-plane"></i>
          {{ [null].includes(num_profil) ? 'Envoyer mon signalement' : 'Enregistrer' }}
        </a>
      </div>

    </div>
  `
})