Vue.component('mod-dom-info-sujet', {
  props: ['params'],
  data () { return {
    readOnly: true
  }},
  methods: {
    save () { this.$parent.updateTicket(['info_item_sujet', 'info_item_appli', 'num_categorie', 'description']) },
    reload () {
      this.$parent.loadTicket()
      this.readOnly = true
    }
  },
  mounted: function () {
    this.$set(this.ticket, 'num_categorie', 33)
  },
  computed: {
    ticket () { return this.$parent.ticket },
    runningCheck () {return this.$parent.runningCheck },
    newTicket () { return this.$parent.newTicket }
  },
  template: /*html*/`
    <div class="module mod-dom-info-sujet">
      <h2>
        <span v-if="newTicket" class="txt-red txt-bold">*</span> Sujet de la demande
        <a href="#" v-if="params.readWrite && !newTicket" class="button green" v-show="readOnly" @click.prevent="readOnly = false">CORRIGER</a>
        <a href="#" v-if="params.readWrite && !newTicket" class="button blue" v-show="!readOnly" @click.prevent="save">ENREGISTRER</a>
        <a href="#" v-if="params.readWrite && !newTicket" class="button red" v-show="!readOnly" @click.prevent="reload">ANNULER</a>
      </h2>
      <div class="liste-sujets" v-show="newTicket">
        <a v-for="sujet in $store.state.indexedConstants.lists[1].items" href="#" @click.prevent="$set(ticket, 'info_item_sujet', sujet.num_item)" :class="[ticket.info_item_sujet === sujet.num_item && 'active']">
          <img :src="'/assets/images/dom_info/sujet_' + sujet.num_item + '.png'"><br>
          {{ sujet.item }}
        </a>
      </div>
      <div v-show="ticket.info_item_sujet === 1 && newTicket">
        <span class="label"><span class="txt-red txt-bold">*</span> Choisir l'application concernée :</span>
        <div class="liste-applis">
          <a v-for="sujet in $store.state.indexedConstants.lists[2].items" href="#" @click.prevent="$set(ticket, 'info_item_appli', sujet.num_item)" :class="[ticket.info_item_appli === sujet.num_item && 'active']">
            {{ sujet.item }}
          </a>
        </div>
      </div>
      <table>
        <tr v-show="!newTicket">
          <td class="label">Sujet de la demande :</td>
          <td class="field"><div class="read-only"><span class="value">{{ $store.state.indexedConstants.listsItems[ticket.info_item_sujet] }}</span></div></td>
        </tr>
        <tr>
          <td class="label"><span v-if="newTicket" class="txt-red txt-bold">*</span> Détails de la demande :</td>
          <td class="field">
            <in-long-text 
              v-model="ticket.description" 
              emptyLabel="Description de la demande" 
              :class="{ 'error' : runningCheck && (!ticket.description || ticket.description.length < 5) }"
              :readOnly="!newTicket"
            ></in-long-text>
          </td>
        </tr>
      </table>
    </div>
  `
})