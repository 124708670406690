Vue.component('mod-localisation-1', {
  props: ['params'],
  data () { return {
    readOnly: true
  }},
  methods: {
    save () { this.$parent.updateTicket(['num_commune', 'adresse', 'codePostal', 'num_batiment', 'longitude', 'latitude']) },
    reload () {
      this.$emit('reload')
      this.readOnly = true
    },
    numCommuneFromName (commune) {
      for (let i = 0; i < this.$store.state.constants.communes.length; i += 1) {
        if (this.$store.state.constants.communes[i].nom === commune) {
          return this.$store.state.constants.communes[i].num_commune
        }
      }
      return null
    },
    updateLocation (data) {
      this.ticket.adresse     = data.name || ''
      this.ticket.codePostal  = data.postcode || ''
      this.ticket.num_commune = this.numCommuneFromName(data.city)
      this.ticket.latitude    = data.coords[1] || this.ticket.latitude
      this.ticket.longitude   = data.coords[0] || this.ticket.longitude

      if (!this.mapUpdatedFromBatiment) {
        this.ticket.num_batiment = null
      }

      this.mapUpdatedFromBatiment = false
    },
    updateMap () {
      this.$refs.vuecarte ? this.$refs.vuecarte.updateMap() : null
      this.$refs.ingeo ? this.$refs.ingeo.updateMap() : null
    },
    exportMap () {
      let me = this
      this.readOnly = true
      return new Promise((resolve) => {
        setTimeout(() => {
          me.$refs.vuecarte.exportMap().then(resolve)
        }, 500);
      })
    }
  },
  computed: {
    ticket () { return this.$parent.ticket },
    localisationParDefaut () { return this.$parent.ticket.latitude === 48.77000000000001000000 && this.$parent.ticket.longitude === 6.12000000000000100000 },
    newTicket () { return this.$parent.newTicket }
  },
  template: `
    <div class="module">
      <h2>
        Localisation de la demande
        <a href="#" v-if="params.readWrite" class="button green" v-show="readOnly && !newTicket" @click.prevent="readOnly = false">CORRIGER</a>
        <a href="#" v-if="params.readWrite" class="button blue" v-show="!readOnly && !newTicket" @click.prevent="save">ENREGISTRER</a>
        <a href="#" v-if="params.readWrite" class="button red" v-show="!readOnly && !newTicket" @click.prevent="reload">ANNULER</a>
      </h2>
      <table v-if="params.saisieManuelle">
        <tr>
          <td class="label">Adresse :</td>
          <td class="field">
            <in-text v-model="ticket.adresse" :emptyValue="'<em>(non renseigné)</em>'" :readOnly="readOnly" small="1"></in-text>
          </td>
          <!-- <td class="field" v-html="ticket.prenomNom || '<em>(non renseigné)</em>'"></td> -->
        </tr>
        <tr>
          <td class="label">Commune :</td>
          <td class="field">
            <in-select 
              :data="$store.state.constants.communes"
              :labelField="'nom'" 
              v-model="ticket.num_commune" 
              dataField="num_commune"
              :readOnly="readOnly"
              emptyLabel="non renseigné"
            ></in-select>
          </td>
        </tr>
      </table>
      <vue-carte v-if="readOnly && !(params.hideMapOnMobile && isMobile) && !localisationParDefaut && !newTicket" :coords="[ticket.longitude, ticket.latitude]" ref="vuecarte"></vue-carte>
      <in-geo v-if="(!readOnly || newTicket) && !(params.hideMapOnMobile && isMobile)" :noGeoloc="!newTicket" :overCoords="newTicket ? null : [ticket.longitude, ticket.latitude]" @change="updateLocation" ref="ingeo"></in-geo>
    </div>
  `
})